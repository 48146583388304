import {Grid, Typography} from "@material-ui/core";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import {ReactComponent as HomeIcon} from "../../assets/icons/24px/home.svg";
import {ReactComponent as PlanIcon} from "../../assets/icons/24px/plan.svg";
import {ReactComponent as HelpIcon} from "../../assets/icons/24px/help.svg";
import {ReactComponent as LogoutIcon} from "../../assets/icons/24px/log-out.svg";
import {ReactComponent as CoupleIcon} from "../../assets/icons/24px/couple.svg";
import {ReactComponent as SingleIcon} from "../../assets/icons/24px/single.svg";
import {useHistory} from "react-router-dom"
import React from "react";
import {Responsive} from "../../utils/Responsive";
import {colors} from "../../styles/colors/colors";
import {useDispatch} from "react-redux";
import {isotypeSrc} from "../../utils/logotype";
import {logout} from "../../services/logout/logout";

function SidebarPlan(props){
    const {user}=props
    const userName=user.family_situation?.name?user.family_situation?.name:''
    const hasPartner=user.family_situation?.has_partner?user.family_situation?.has_partner:false
    const history=useHistory()
    const dispatch=useDispatch()
    const isoSrc=isotypeSrc()
    return(
        <>
            <Responsive displayIn={"Laptop"}>
                <Grid
                    container
                    item
                    direction={"column"}
                    alignItems={"center"}
                    style={{
                        maxWidth:"60px",
                        height:"100vh",
                        background:"white",
                    }}
                >
                    <Grid
                        item
                        style={{marginTop:"30px"}}
                    >
                        <img alt={""} src={isoSrc} style={{color:"red", width:process.env.REACT_APP_ENVIRONMENT==='planifica'?32:24}}/>
                    </Grid>
                    <MenuList style={{marginTop:"32px"}}>

                        <MenuItem onClick={()=>history.push('/posicion-global')} style={{margin:"0 0 18px"}}><HomeIcon fill={history.location.pathname.match("/posicion-global") ?colors['--brand-main-100']:colors['--black']} /></MenuItem>
                        <MenuItem onClick={()=>history.push("/plan")} style={{margin:"0 0 18px"}} className={'plan_menu_item'}><PlanIcon fill={(history.location.pathname.match("/plan") || history.location.pathname==="/recomendacion") ?colors['--brand-main-100']:colors['--black']} /></MenuItem>
                        <MenuItem style={{margin:"0 0 18px",opacity:1}} >
                            {
                                hasPartner===true?
                                    <CoupleIcon fill={history.location.pathname==="/datos-personales" ?colors['--brand-main-100']:colors['--black']} onClick={()=>history.push('/datos-personales')}/>
                                    :
                                    <SingleIcon fill={history.location.pathname==="/datos-personales" ?colors['--brand-main-100']:colors['--black']} onClick={()=>history.push('/datos-personales')}/>
                            }
                        </MenuItem>
                    </MenuList>

                    <MenuList style={{marginTop:"220px"}}>
                        <MenuItem style={{margin:"0 0 18px",opacity:1}}>
                            <Grid container justify={"center"} alignItems={"center"} style={{width:'24px',height:'24px', borderRadius:'50%', background:colors['--brand-main-100']}}>
                                <Grid
                                    item
                                >
                                    <Typography variant={"h4"} style={{color:colors['--white'],textTransform:"capitalize",position:"relative"}}>
                                        {userName.slice(0,1)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>
                        <MenuItem style={{margin:"0 0 18px"}} className={'tour_menu_item'} onClick={()=>props.setBeginTour(true)}><HelpIcon fill={"black"} /></MenuItem>
                        <MenuItem onClick={()=>{
                            logout().then()
                            dispatch({type:'reset'})
                            history.push('/login')
                        }}>
                            <LogoutIcon fill={"black"} />
                        </MenuItem>
                    </MenuList>
                </Grid>
            </Responsive>
            <Responsive displayIn={["Mobile","Tablet"]}>
                <Grid
                    container
                    item
                    alignItems={"center"}
                    justify={"center"}
                    style={{
                        background:"white",
                        width:"100vw",
                        height:"56px",
                    }}
                >
                        <MenuItem onClick={()=>history.push("/posicion-global")} ><HomeIcon fill={history.location.pathname.match("/posicion-global") ?colors['--brand-main-100']:colors['--black']} /></MenuItem>
                        <MenuItem onClick={()=>history.push("/plan")} className={'plan_menu_item'}><PlanIcon fill={(history.location.pathname.match("/plan") || history.location.pathname==="/recomendacion") ?colors['--brand-main-100']:colors['--black']} /></MenuItem>
                        <MenuItem style={{opacity:1}} >
                            {
                                hasPartner===true?
                                    <CoupleIcon fill={history.location.pathname==="/datos-personales" ?colors['--brand-main-100']:colors['--black']} onClick={()=>history.push('/datos-personales')}/>
                                    :
                                    <SingleIcon fill={history.location.pathname==="/datos-personales" ?colors['--brand-main-100']:colors['--black']} onClick={()=>history.push('/datos-personales')}/>
                            }
                        </MenuItem>

                        <MenuItem disabled style={{opacity:1}}>
                            <Grid container justify={"center"} alignItems={"center"} style={{width:'24px',height:'24px', borderRadius:'50%', background:colors['--brand-main-100']}}>
                                <Grid
                                    item
                                >
                                    <Typography variant={"h4"} style={{color:colors['--white'],textTransform:"capitalize",position:"relative",bottom:-1,right:-1}}>
                                        {userName.slice(0,1)}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </MenuItem>
                        <MenuItem className={'tour_menu_item'} onClick={()=>props.setBeginTour(true)}><HelpIcon fill={"black"} /></MenuItem>
                        <MenuItem onClick={()=>{
                            logout().then()
                            dispatch({type:'reset'})
                            history.push('/login')
                        }}>
                            <LogoutIcon fill={"black"} />
                        </MenuItem>
                </Grid>
            </Responsive>
        </>
    )
}
export default SidebarPlan