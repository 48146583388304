import React, {useEffect} from "react";
import {colors} from "../../styles/colors/colors";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import SidebarPlan from "../../components/sidebar/SidebarPlan";
import {planStyles} from "../plan/planStyles";
import {useDispatch, useSelector} from "react-redux";
import {getTracingData, selectPlan} from "../../redux/plan/planSlice";
import {selectRecommendation} from "../../redux/recommendation/recommendationSlice";
import {selectUser} from "../../redux/user/userSlice";
import {Button, Divider, Link, Tab, Tabs, Typography, withStyles} from "@material-ui/core";
import {differenceInCalendarYears, format, getYear, parse,compareDesc} from "date-fns";
import {es} from "date-fns/locale";
import {ReactComponent as RightArowIcon} from "../../assets/onboardingGoals/arrow-right.svg";
import GoalSvg from "../../components/goalSvg/GoalSvg";
import NumberFormat from "react-number-format";
import ChartRadialBarGoalsVsInitialInvestment from "../../components/chart/ChartRadialBarGoalsVsInitialInvestment";
import ChartBarSavingGoal from "../../components/chart/ChartBarSavingsGoal";
import {ReactComponent as PromoImage} from '../../assets/globalPosition/promoImg.svg'
import LinearProgress from "@material-ui/core/LinearProgress";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {globalPositionsStyles} from "./globalPositionStyles";
import ChartAreaPatrimony from "../../components/chart/ChartAreaPatrimony";
import {generalStyles} from "../../styles/general/generalStyles";
import {Responsive} from "../../utils/Responsive";
import {useHistory} from "react-router-dom";
import {selectConfig} from "../../redux/config/configSlice";
import {ReactComponent as CheckedFilledIcon} from "../../assets/icons/16px/check-filled.svg";
import {ReactComponent as CloseFilledIcon} from "../../assets/icons/16px/close-filled.svg";


const objectiveColor=[colors["--goal-primary-100"],colors["--goal-secondary-100"],colors["--goal-tertiary-100"],colors["--goal-quaternary-100"]]

const ScrollableGrid = withStyles(theme=>({
    root:{
        '&::-webkit-scrollbar': {
            width: '4px'
        },
        '&::-webkit-scrollbar-track': {
            background:colors["--gray-soft"],
            borderRadius:"24px",
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: colors["--gray"],
            borderRadius:"24px",
            height:"36px"
        },

    }
}))(Grid);

const useLinearProgressBarStyles= makeStyles(theme=>({
    root:{
        height:2,
        borderRadius:"24px",
        [theme.breakpoints.down("sm")]:{
            margin:"10px 0"
        }
    },
    colorPrimary:{
        backgroundColor:colors["--gray-soft"]
    },
    barColorPrimary:props => ({
        backgroundColor:props.objectiveColor,
        borderRadius:"24px"
    })



}));

export default function GlobalPosition(){
    const dispatch=useDispatch()
    const history=useHistory()
    const config=useSelector(selectConfig)
    const generalClasses=generalStyles()
    const progressBarClasses = [
        useLinearProgressBarStyles({objectiveColor: objectiveColor[0]}),
        useLinearProgressBarStyles({objectiveColor: objectiveColor[1]}),
        useLinearProgressBarStyles({objectiveColor: objectiveColor[2]}),
        useLinearProgressBarStyles({objectiveColor: objectiveColor[3]})
    ]
    const classes=globalPositionsStyles()
    const planClasses = planStyles()
    const plan = useSelector(selectPlan)
    const recommendation = useSelector(selectRecommendation)
    const user = useSelector(selectUser)
    const [charValue, setChartValue] = React.useState(0);
    const longerTimeHorizonPlusDuration=plan?.data?.plan?.reduce((longerTimeHorizonPlusDuration,objectivePlan)=>{
        let newTimeHorizonPlusDuration=(objectivePlan.objective.duration?objectivePlan.objective.time_horizon+Math.round(objectivePlan.objective.duration/12)+1:objectivePlan.objective.time_horizon+1)
        if(longerTimeHorizonPlusDuration<newTimeHorizonPlusDuration){
            longerTimeHorizonPlusDuration=newTimeHorizonPlusDuration
        }
        return longerTimeHorizonPlusDuration
    },0)
    const longerPlanDataArrayNumber=plan.data?.plan.reduce((longerIndexObject,planData,index)=>{
        if(longerIndexObject.dataLength<planData.data?.length){
            longerIndexObject={index:index,dataLength:planData.data?.length}
        }
        return longerIndexObject
    }, {index:0,dataLength:0})
    const handleChange = (event, newValue) => {
        setChartValue(newValue);
    };
    useEffect(()=>{
        if(plan.data?.is_active===true){
            dispatch(getTracingData())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    let monthsPaid
    if(plan.data?.is_active && plan.tracing!==null){
        monthsPaid=Object.keys(plan?.tracing).map(key=> {
            return {...plan.tracing[key],date:parse(key, 'L-yyyy', new Date())}
        }).reverse()
    }
    return(
        <Grid
            container
            style={{height: "100%", background: colors["--background"]}}
            direction={"row"}
        >
            <Backdrop style={{zIndex: "100"}} open={plan.loading || recommendation.loading}>
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Box style={{flexGrow: "1", display: "flex", overflow: "hidden"}}>
                <Box className={planClasses.menu_bar_box}>
                    <SidebarPlan user={user}/>
                </Box>
                <Box className={classes.content_container_box}>
                    <Grid
                        container
                        className={classes.content_container}
                    >
                        <Grid
                            item
                            container
                        >
                            <Typography variant={"h3"}>
                                Posición Global
                            </Typography>
                        </Grid>
                        {/*
                        FIRST ROW
                        */}
                        <Grid
                            item
                            container
                            className={classes.goal_cards_container}
                            style={{marginTop:16}}
                        >
                            <Grid
                                item
                                container
                                className={
                                    plan.data?.plan.length===1?
                                        classes.goal_cards_1_objective_inner_container
                                        :
                                        plan.data?.plan.length===2?
                                            classes.goal_cards_2_objective_inner_container
                                            :
                                            classes.goal_cards_3_objective_inner_container

                                }
                            >
                                {
                                    plan.data?.plan.map((planData,index)=>
                                        <Grid
                                            item
                                            container
                                            className={classes.goal_inner_container}
                                            direction={"column"}
                                            onClick={()=>history.push("/plan/objetivo/"+planData.objective.id,{objectiveColor:objectiveColor[index]})}
                                        >
                                            <Grid
                                                container
                                                item
                                                className={generalClasses.width_fit_content}                                        >
                                                <Grid
                                                    item
                                                    container
                                                    className={generalClasses.width_fit_content}                                            >
                                                    <Grid
                                                        item
                                                        container
                                                        className={generalClasses.width_fit_content}
                                                    >
                                                        <GoalSvg type={planData.objective.type} color={objectiveColor[index]}/>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        style={{maxWidth:196}}
                                                    >
                                                        <Typography noWrap variant={"h4"} style={{marginBottom:"-6px"}}>
                                                            {planData.objective.name}
                                                        </Typography>
                                                        <Grid
                                                            item
                                                            container
                                                            style={{marginRight:"70px"}}
                                                        >
                                                            <Grid
                                                                item
                                                            >
                                                                <Typography variant={"caption"}>
                                                                    {planData.objective.date_init && (format(parse(planData.objective.date_init,'yyyy-MM-dd',new Date()),'MMM yyyy',{locale:es}))}
                                                                </Typography>
                                                            </Grid>
                                                            {planData.objective.date_end &&
                                                            <Grid
                                                                item
                                                                container
                                                                className={generalClasses.width_fit_content}                                                        >
                                                                <Grid
                                                                    item
                                                                    style={{margin:"0 10px"}}
                                                                >
                                                                    <RightArowIcon/>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                >
                                                                    <Typography variant={"caption"}>
                                                                        {planData.objective.date_end && (format(parse(planData.objective.date_end,'yyyy-MM-dd',new Date()),'MMM yyyy',{locale:es}))}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    container
                                                    direction={"column"}
                                                >
                                                    <Grid
                                                        item
                                                        container
                                                        justify={"space-between"}
                                                    >
                                                        <Grid
                                                            item
                                                        >
                                                            <Typography variant={"caption"}>
                                                                {""}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                        >
                                                            <Typography variant={"caption"}>
                                                                {""}

                                                            </Typography>

                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                style={{margin:'12px 0 8px'}}
                                            >
                                                <LinearProgress style={{width:"100%"}} variant={"determinate"} value={(planData.initial_investment/(planData.data?.reduce((totalInput,monthData)=>{
                                                    return totalInput+monthData.input
                                                },0)+planData.initial_investment))*100} classes={{
                                                    colorPrimary: progressBarClasses[index].colorPrimary,
                                                    barColorPrimary: progressBarClasses[index].barColorPrimary,
                                                    root: progressBarClasses[index].root
                                                }}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                justify={"space-between"}
                                                alignItems={"center"}
                                            >
                                                <Grid
                                                    item
                                                >
                                                    <Typography variant={"body2"} style={{fontSize:14}}>
                                                        Aportado:
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                >
                                                    <Typography variant={"body2"} style={{fontFamily:'Avenir Heavy'}}>
                                                        <NumberFormat
                                                            thousandSeparator={config.thousand_separator}
                                                            decimalSeparator={config.decimal_separator}
                                                            displayType={"text"}
                                                            decimalScale={0}
                                                            value={(planData.initial_investment/(planData.data?.reduce((totalInput,monthData)=>{
                                                                return totalInput+monthData.input
                                                            },0)+planData.initial_investment))*100}
                                                        />
                                                        %
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            {
                                                plan.data?.is_active===true &&
                                                <Grid
                                                    item
                                                    container
                                                    justify={"space-between"}
                                                    alignItems={"center"}
                                                >
                                                    <Grid
                                                        item
                                                    >
                                                        <Typography variant={"body2"} style={{fontSize:14}}>
                                                            Probabilidad de conseguirlo:
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                    >
                                                        <Typography variant={"body2"} style={{fontFamily:'Avenir Heavy'}}>
                                                            <NumberFormat
                                                                thousandSeparator={config.thousand_separator}
                                                                decimalSeparator={config.decimal_separator}
                                                                displayType={"text"}
                                                                decimalScale={0}
                                                                value={planData.objective.probability}
                                                            />
                                                            %
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            }

                                        </Grid>
                                    )
                                }
                                {
                                    plan.data?.plan.length<4 &&
                                    <Grid
                                        item
                                        container
                                        style={{width:276,background:colors['--white'],marginRight:24,border:`1px dashed ${colors['--gray-dark']}`,
                                            borderRadius:2, padding:'16px'}}
                                        direction={"column"}
                                        justify={"center"}
                                        alignItems={"center"}
                                    >
                                        <Grid
                                            item
                                        >
                                            <Typography align={"center"} variant={"body2"} style={{fontSize:14,color:colors['--gray']}}>
                                                Si quieres agregar otra meta puedes hacerlo desde tu plan
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                        {/*
                            SECOND ROW
                        */}
                        <Grid
                            item
                            container
                            style={{marginTop:24}}
                        >
                            {
                                /*
                                FIRST COLUMN
                                 */
                            }
                            {
                                plan.data?.is_active===true?
                                    <Grid
                                        item
                                        container
                                        className={classes.initial_distribution_container}
                                        direction={"column"}
                                        justify={"space-between"}
                                    >
                                        <Grid
                                            item
                                            container
                                            direction={"column"}
                                        >
                                            <Grid
                                                item
                                            >
                                                <Typography variant={'h4'}>
                                                    Aportación acumulada
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                style={{padding:'16px 0' }}
                                            >
                                                {
                                                    plan.data?.plan.map((planData,index)=>{
                                                        return <Grid
                                                            item
                                                        >
                                                            <div style={{width:`${(planData.saving/plan.data?.global_info.saving)*244}px`,borderRadius:24,background:objectiveColor[index],height:8}}/>
                                                        </Grid>
                                                    })
                                                }
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                            >
                                                {
                                                    plan.data?.plan.map((planData,index)=>{
                                                        return <Grid
                                                            item
                                                            container
                                                            justify={"space-between"}
                                                        >
                                                            <Grid
                                                                item
                                                                container
                                                                className={generalClasses.width_fit_content}
                                                                alignItems={"center"}
                                                            >
                                                                <Grid
                                                                    item
                                                                    style={{width:8,height:8, borderRadius:2, background:colors['--goal-primary-100']}}
                                                                />
                                                                <Grid
                                                                    item
                                                                    style={{marginLeft:4}}
                                                                >
                                                                    <Typography variant={"caption"}>
                                                                        {planData.objective.name}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                            >
                                                                <Typography variant={"caption"} style={{fontFamily:'Avenir Heavy'}}>
                                                                    <NumberFormat
                                                                        thousandSeparator={config.thousand_separator}
                                                                        decimalSeparator={config.decimal_separator}
                                                                        displayType={"text"}
                                                                        decimalScale={0}
                                                                        value={planData.saving}
                                                                        suffix={config.currency_suffix}
                                                                        prefix={config.currency_prefix}
                                                                    />
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    })
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            direction={"column"}
                                        >
                                            <Grid
                                                item
                                                container
                                                direction={"column"}
                                            >
                                                <Grid
                                                    item
                                                    style={{padding:'16px 0'}}
                                                >
                                                    <Typography variant={"h4"}>
                                                        Seguimiento de aportaciones
                                                    </Typography>
                                                </Grid>
                                                <ScrollableGrid

                                                    style={{height:97, overflowY:'scroll'}}
                                                >
                                                    <Grid
                                                        item
                                                        container
                                                    >
                                                        {
                                                            monthsPaid?.map(month=>{
                                                                if(compareDesc(month.date,new Date())===1){
                                                                    return <Grid
                                                                        item
                                                                        container
                                                                    >
                                                                        <Grid
                                                                            item
                                                                            sm={6}
                                                                        >
                                                                            <Typography variant={"caption"}>
                                                                                {format(month.date, 'MMM yyyy', {locale: es})}
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            container
                                                                            sm={3}
                                                                            justify={"center"}
                                                                        >
                                                                            <Typography variant={"caption"}>
                                                                                <NumberFormat
                                                                                    thousandSeparator={config.thousand_separator}
                                                                                    decimalSeparator={config.decimal_separator}
                                                                                    displayType={"text"}
                                                                                    decimalScale={0}
                                                                                    value={month.real}
                                                                                    suffix={config.currency_suffix}
                                                                                    prefix={config.currency_prefix}
                                                                                />
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid
                                                                            item
                                                                            container
                                                                            justify={"center"}
                                                                            sm={3}
                                                                        >
                                                                            {
                                                                                month.input_done===false?
                                                                                    <CloseFilledIcon fill={colors['--error']}/>
                                                                                    :
                                                                                    <CheckedFilledIcon fill={colors['--success']} style={{height:16,width:16}}/>
                                                                            }
                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                                return null
                                                            })
                                                        }
                                                    </Grid>
                                                </ScrollableGrid>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                style={{marginTop:16}}
                                            >
                                                <Button
                                                    style={{padding:'8px 15px'}}
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={()=> {
                                                    }}
                                                >
                                                    Resolver cuota no aportada
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid
                                        item
                                        container
                                        className={classes.initial_distribution_container}
                                        direction={"column"}
                                    >
                                        <Grid
                                            item
                                        >
                                            <Typography variant={"h4"} style={{marginBottom:8}}>
                                                Distribución del aporte inicial
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                        >
                                            <Typography variant={"body2"} style={{fontSize:14}}>
                                                Las metas que quieres alcanzar antes reciben mayor porcentaje del aporte inicial.
                                                Así, el progreso será mayor.
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            justify={"center"}
                                        >
                                            <Grid
                                                item
                                            >
                                                <ChartRadialBarGoalsVsInitialInvestment
                                                    data={plan.data?.plan.map((planData,index)=>
                                                        {
                                                            return {"name": planData.objective.type,"value":planData.initial_investment,"fill":objectiveColor[index]}
                                                        }
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            container
                                            direction={"column"}
                                            alignItems={"center"}
                                        >
                                            <Grid
                                                item
                                            >
                                                <Typography variant={'h4'} style={{color:colors['--black-soft']}}>
                                                    <NumberFormat
                                                        thousandSeparator={config.thousand_separator}
                                                        decimalSeparator={config.decimal_separator}
                                                        displayType={"text"}
                                                        decimalScale={0}
                                                        value={plan.data?.plan.reduce((totalInitialInvestment,planData)=>totalInitialInvestment+planData.initial_investment,0)}
                                                        suffix={config.currency_suffix}
                                                        prefix={config.currency_prefix}
                                                    /> /&nbsp;{plan.data?.plan.length>1?`${plan.data?.plan.length} metas`:`${plan.data?.plan.length} meta`}
                                                </Typography>
                                            </Grid>
                                            {
                                                plan.data?.plan.map((planData,index)=>
                                                    <Grid
                                                        item
                                                        container
                                                        alignItems={"center"}
                                                        className={generalClasses.width_fit_content}
                                                    >
                                                        <Grid
                                                            item
                                                            style={{height:8,width:8,background:objectiveColor[index],borderRadius:2}}
                                                        />
                                                        <Grid
                                                            item
                                                        >
                                                            <Typography display={"inline"} variant={"caption"}>
                                                                &nbsp;{planData.objective.name} -&nbsp;
                                                            </Typography>
                                                            <Typography display={"inline"} variant={"caption"}>
                                                                {planData.objective.time_horizon>1?`${planData.objective.time_horizon} años`:`${planData.objective.time_horizon} años`}:
                                                            </Typography>
                                                            <Typography display={"inline"} variant={"caption"} style={{fontFamily:'Avenir Heavy'}}>
                                                                &nbsp;
                                                                <NumberFormat
                                                                    thousandSeparator={config.thousand_separator}
                                                                    decimalSeparator={config.decimal_separator}
                                                                    displayType={"text"}
                                                                    decimalScale={0}
                                                                    value={planData.initial_investment}
                                                                    suffix={config.currency_suffix}
                                                                    prefix={config.currency_prefix}
                                                                />
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                            }
                            {
                                /*CHART COMPORTAMIENTO DE TU PLAN*/
                            }
                            <Responsive displayIn={'Laptop'}>
                                <Grid
                                    item
                                    container
                                    style={{height:400,width:876,background:colors['--white'],marginRight:24,boxShadow: '0px 4px 8px rgba(219, 225, 231, 0.3), 0px 8px 20px #F0F5F9',
                                        borderRadius:4, padding:'16px'}}
                                    direction={"column"}
                                >
                                    <Grid
                                        item
                                        container
                                    >
                                        <Tabs
                                            value={charValue}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            onChange={handleChange}
                                        >
                                            <Tab label="Comportamiento de tu plan" />
                                            <Tab label="Patrimonio"/>
                                        </Tabs>
                                    </Grid>
                                    <Grid
                                        item
                                        style={{position:'relative',top:-1, width:'100%'}}
                                    >
                                        <Divider/>
                                    </Grid>
                                    {
                                        /*SWITCH CHART CONTAINER*/
                                    }
                                    {
                                        charValue===0?
                                            <Grid
                                                item
                                                container
                                                style={{
                                                    padding:"16px"
                                                }}
                                            >
                                                <Grid
                                                    container
                                                    item
                                                    direction={"column"}
                                                    justify={"space-around"}
                                                    style={{width:"fit-content",height:"inherit",marginRight:38}}
                                                >
                                                </Grid>
                                                <Grid
                                                    container
                                                    item
                                                    direction={"column"}
                                                    alignItems={"center"}
                                                    className={generalClasses.width_fit_content}
                                                    style={{position:'relative',left:`${plan.data?.global_info.months_plan_active*720/(longerTimeHorizonPlusDuration*12)}px`}}
                                                >
                                                    <Grid
                                                        item
                                                        className={generalClasses.width_fit_content}
                                                    >
                                                        <Typography variant={"caption"} style={{fontSize:"10px"}}>
                                                            Hoy
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item

                                                    >
                                                        <Divider orientation={"vertical"} style={{width:"2px",height:210}}/>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    container
                                                    style={{width:"750px",paddingTop:30,marginLeft:"-10px"}}
                                                >
                                                    <Grid
                                                        item
                                                        style={{width:"inherit"}}
                                                    >
                                                        <ChartBarSavingGoal
                                                            height={100}
                                                            goalNumber={plan.data?.plan.length}
                                                            data={[...new Array(longerTimeHorizonPlusDuration)].map((arrayElement,index)=> {
                                                                return {
                                                                    name:getYear(new Date())+index,
                                                                    goalNames:{
                                                                        saving:plan.data?.plan[0]?.objective?.name,
                                                                        savingGoal2:plan.data?.plan[1]?.objective?.name,
                                                                        savingGoal3:plan.data?.plan[2]?.objective?.name,
                                                                        savingGoal4:plan.data?.plan[3]?.objective?.name,
                                                                    },
                                                                    saving:plan.data?.plan[0].data?.reduce((totalInput,monthlyData)=>{
                                                                        let year=getYear(parse(monthlyData.date,'dd-MM-yyyy',new Date()))
                                                                        let indexYear=getYear(new Date())+index
                                                                        if(year===indexYear){
                                                                            return totalInput+monthlyData.input
                                                                        }else{
                                                                            return totalInput
                                                                        }
                                                                    },0),
                                                                    savingGoal2:plan.data?.plan[1]?.data?.reduce((totalInput,monthlyData)=>{
                                                                        let year=getYear(parse(monthlyData.date,'dd-MM-yyyy',new Date()))
                                                                        let indexYear=getYear(new Date())+index
                                                                        if(year===indexYear){
                                                                            return totalInput+monthlyData.input
                                                                        }else{
                                                                            return totalInput
                                                                        }
                                                                    },0),
                                                                    savingGoal3:plan.data?.plan[2]?.data?.reduce((totalInput,monthlyData)=>{
                                                                        let year=getYear(parse(monthlyData.date,'dd-MM-yyyy',new Date()))
                                                                        let indexYear=getYear(new Date())+index
                                                                        if(year===indexYear){
                                                                            return totalInput+monthlyData.input
                                                                        }else{
                                                                            return totalInput
                                                                        }
                                                                    },0),
                                                                    savingGoal4:plan.data?.plan[3]?.data?.reduce((totalInput,monthlyData)=>{
                                                                        let year=getYear(parse(monthlyData.date,'dd-MM-yyyy',new Date()))
                                                                        let indexYear=getYear(new Date())+index
                                                                        if(year===indexYear){
                                                                            return totalInput+monthlyData.input
                                                                        }else{
                                                                            return totalInput
                                                                        }
                                                                    },0),
                                                                }
                                                            })}
                                                            dataMaxValue={[...new Array(longerTimeHorizonPlusDuration)].map((arrayElement,index)=> {
                                                                return {
                                                                    name:getYear(new Date())+index,
                                                                    saving:plan.data?.plan[0].data?.slice(index*12,(index+1)*12).reduce((totalInput,monthlyData)=>{
                                                                        if(monthlyData.input>monthlyData.output){
                                                                            return totalInput+monthlyData.input
                                                                        }else{
                                                                            return totalInput+monthlyData.output
                                                                        }
                                                                    },0)
                                                                }
                                                            }).reduce((maxSaving,yearSaving)=>{
                                                                if(maxSaving<yearSaving.saving){
                                                                    maxSaving=yearSaving.saving
                                                                }
                                                                return maxSaving
                                                            },0)}
                                                            dataMinValue={0}
                                                            color={colors['--goal-primary-100']}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        style={{marginTop:-40,width:"inherit"}}
                                                    >
                                                        <ChartBarSavingGoal
                                                            height={100}
                                                            goalNumber={plan.data?.plan.length}
                                                            data={[...new Array(longerTimeHorizonPlusDuration)].map((arrayElement,index)=> {
                                                                return {
                                                                    name:getYear(new Date())+index,
                                                                    goalNames:{
                                                                        saving:plan.data?.plan[0]?.objective?.name,
                                                                        savingGoal2:plan.data?.plan[1]?.objective?.name,
                                                                        savingGoal3:plan.data?.plan[2]?.objective?.name,
                                                                        savingGoal4:plan.data?.plan[3]?.objective?.name,
                                                                    },
                                                                    saving:plan.data?.plan[0].data?.reduce((totalOutput, monthlyData) => {
                                                                        let year=getYear(parse(monthlyData.date,'dd-MM-yyyy',new Date()))
                                                                        let indexYear=getYear(new Date())+index
                                                                        if(year===indexYear){
                                                                            return totalOutput-monthlyData.output
                                                                        }else{
                                                                            return totalOutput
                                                                        }
                                                                    }, 0),
                                                                    savingGoal2:plan.data?.plan[1]?.data?.reduce((totalOutput, monthlyData) => {
                                                                        let year=getYear(parse(monthlyData.date,'dd-MM-yyyy',new Date()))
                                                                        let indexYear=getYear(new Date())+index
                                                                        if(year===indexYear){
                                                                            return totalOutput-monthlyData.output
                                                                        }else{
                                                                            return totalOutput
                                                                        }
                                                                    }, 0),
                                                                    savingGoal3:plan.data?.plan[2]?.data?.reduce((totalOutput, monthlyData) => {
                                                                        let year=getYear(parse(monthlyData.date,'dd-MM-yyyy',new Date()))
                                                                        let indexYear=getYear(new Date())+index
                                                                        if(year===indexYear){
                                                                            return totalOutput-monthlyData.output
                                                                        }else{
                                                                            return totalOutput
                                                                        }
                                                                    }, 0),
                                                                    savingGoal4:plan.data?.plan[3]?.data?.reduce((totalOutput, monthlyData) => {
                                                                        let year=getYear(parse(monthlyData.date,'dd-MM-yyyy',new Date()))
                                                                        let indexYear=getYear(new Date())+index
                                                                        if(year===indexYear){
                                                                            return totalOutput-monthlyData.output
                                                                        }else{
                                                                            return totalOutput
                                                                        }
                                                                    }, 0),
                                                                }
                                                            })}
                                                            isXaxisHiden={true}
                                                            dataMinValue={-[...new Array(longerTimeHorizonPlusDuration)].map((arrayElement,index)=> {
                                                                return {
                                                                    name:getYear(new Date())+index,
                                                                    saving:plan.data?.plan[0].data?.slice(index*12,(index+1)*12).reduce((totalInput,monthlyData)=>{
                                                                        if(monthlyData.input>monthlyData.output){
                                                                            return totalInput+monthlyData.input
                                                                        }else{
                                                                            return totalInput+monthlyData.output
                                                                        }
                                                                    },0)
                                                                }
                                                            }).reduce((maxSaving,yearSaving)=>{
                                                                if(maxSaving<yearSaving.saving){
                                                                    maxSaving=yearSaving.saving
                                                                }
                                                                return maxSaving
                                                            },0)}
                                                            dataMaxValue={0}
                                                            color={colors['--goal-primary-100']}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    container
                                                    alignItems={"center"}
                                                    style={{width:"fit-content",margin:"0 0 8px 8px"}}
                                                >
                                                    <Typography variant={"caption"}>
                                                        Edad:
                                                    </Typography>
                                                    <Grid
                                                        item
                                                        container
                                                        style={{marginLeft:"8px",height:"24px",width:"722.28px",background: colors["--white"], border:`1px solid ${colors["--gray-dark"]}`, borderRadius:"2px"}}
                                                    >
                                                        {
                                                            [...new Array(longerTimeHorizonPlusDuration)].map((year,index)=>{
                                                                return <Grid
                                                                    item
                                                                    container
                                                                    alignItems={"center"}
                                                                    justify={"center"}
                                                                    style={index===([...new Array(longerTimeHorizonPlusDuration)].length-1)?{width:`calc(100% / ${[...new Array(longerTimeHorizonPlusDuration)].length})`}:{borderRight:`1px solid ${colors["--gray-dark"]}`,width:`calc(100% / ${[...new Array(longerTimeHorizonPlusDuration)].length})`}}
                                                                >
                                                                    <Typography variant={"caption"} style={{fontSize:"10px"}}>
                                                                        {
                                                                            ((longerTimeHorizonPlusDuration>=50 && ((differenceInCalendarYears(new Date(), parse(user.family_situation.birthday, "dd/MM/yyyy", new Date())) + index)%2===0)) || longerTimeHorizonPlusDuration<50) &&
                                                                            (differenceInCalendarYears(new Date(), parse(user.family_situation.birthday, "dd/MM/yyyy", new Date())) + index)

                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            })
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid
                                                item
                                                container
                                                direction={"column"}
                                                style={{
                                                    padding:"16px",
                                                }}
                                            >
                                                <Grid
                                                    item
                                                    container
                                                    style={{width:"750px",marginBottom:5,marginLeft:"50px"}}
                                                >
                                                    <ChartAreaPatrimony
                                                        data={plan.data?.plan[longerPlanDataArrayNumber.index].data?.map((monthlyData,index)=>{
                                                                let totalInitialInvestment=plan.data?.plan.reduce((totalInitialInvestment,planData)=>{
                                                                    return totalInitialInvestment+planData.initial_investment
                                                                },0)
                                                                let totalInput=plan.data?.plan.reduce((totalInput,planData)=> {
                                                                    let input=planData.data[index]?.input===undefined?0:planData.data[index]?.input
                                                                    return totalInput + input
                                                                },0)
                                                                let totalPerformance=plan.data?.plan.reduce((totalPerformance,planData)=> {
                                                                    let performance=planData.data[index]?.performance===undefined?0:planData.data[index]?.performance
                                                                    return totalPerformance + performance
                                                                },0)
                                                                let totalOutput=plan.data?.plan.reduce((totalOutput,planData)=> {
                                                                    let output=planData.data[index]?.output===undefined?0:planData.data[index]?.output
                                                                    return totalOutput + output
                                                                },0)
                                                                return{
                                                                    name:index===0?'':monthlyData.date,
                                                                    patrimony:index===0?(totalInitialInvestment+totalInput+totalPerformance-totalOutput):(totalInput+totalPerformance-totalOutput),
                                                                }
                                                            }
                                                        ).map(
                                                            (sum => monthlyData => sum={
                                                                name:monthlyData.name.split('-')[2],
                                                                patrimony:monthlyData.patrimony+sum.patrimony
                                                            })({name:"",patrimony:0})
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    container
                                                    alignItems={"center"}
                                                    style={{width:"fit-content",margin:"0 0 8px 8px"}}
                                                >
                                                    <Typography variant={"caption"}>
                                                        Edad:
                                                    </Typography>
                                                    <Grid
                                                        item
                                                        container
                                                        style={{marginLeft:"8px",height:"24px",width:"722.28px",background: colors["--white"], border:`1px solid ${colors["--gray-dark"]}`, borderRadius:"2px"}}
                                                    >
                                                        {
                                                            [...new Array(longerTimeHorizonPlusDuration)].map((year,index)=>{
                                                                return <Grid
                                                                    item
                                                                    container
                                                                    alignItems={"center"}
                                                                    justify={"center"}
                                                                    style={index===([...new Array(longerTimeHorizonPlusDuration)].length-1)?{width:`calc(100% / ${[...new Array(longerTimeHorizonPlusDuration)].length})`}:{borderRight:`1px solid ${colors["--gray-dark"]}`,width:`calc(100% / ${[...new Array(longerTimeHorizonPlusDuration)].length})`}}
                                                                >
                                                                    <Typography variant={"caption"} style={{fontSize:"10px"}}>
                                                                        {
                                                                            ((longerTimeHorizonPlusDuration>=50 && ((differenceInCalendarYears(new Date(), parse(user.family_situation.birthday, "dd/MM/yyyy", new Date())) + index)%2===0)) || longerTimeHorizonPlusDuration<50) &&
                                                                            (differenceInCalendarYears(new Date(), parse(user.family_situation.birthday, "dd/MM/yyyy", new Date())) + index)

                                                                        }
                                                                    </Typography>
                                                                </Grid>
                                                            })
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                    }
                                    <Grid
                                        item
                                        container
                                        style={{marginLeft:65}}
                                    >
                                        {
                                            charValue===0 &&
                                            plan.data?.plan.map((planData,index)=>
                                                <Grid
                                                    item
                                                    container
                                                    className={generalClasses.width_fit_content}
                                                    style={{marginRight:16}}
                                                    alignItems={"center"}
                                                >
                                                    <Grid
                                                        item
                                                        style={{height:8,width:8,background:objectiveColor[index],borderRadius:2}}
                                                    />
                                                    <Grid
                                                        item
                                                    >
                                                        <Typography display={"inline"} variant={"caption"}>
                                                            &nbsp;{planData.objective.name}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </Responsive>
                            <Responsive displayIn={['Tablet','Mobile']}>
                                <Grid
                                    container
                                    style={{background: colors['--white'],
                                        boxShadow: 'rgb(219 225 231 / 30%) 0px 4px 8px, rgb(240 245 249) 0px 8px 20px',
                                        borderRadius: 4,
                                        padding: 16,
                                        marginTop: 20,
                                        width: '100vw',
                                    }}
                                    direction={"column"}
                                >
                                    <Grid
                                        item
                                    >
                                        <Tabs
                                            value={charValue}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            onChange={handleChange}
                                        >
                                            <Tab label="Tu plan" />
                                            <Tab label="Patrimonio"/>
                                        </Tabs>
                                    </Grid>
                                    {
                                        charValue===0?
                                            <Grid
                                                item
                                                container
                                                style={{overflowX:'auto'}}
                                            >
                                                <Grid
                                                    container
                                                    style={{width:750}}
                                                >
                                                    <Grid
                                                        item
                                                        style={{width:750}}
                                                    >
                                                        <ChartBarSavingGoal
                                                            height={100}
                                                            goalNumber={plan.data?.plan.length}
                                                            data={[...new Array(longerTimeHorizonPlusDuration)].map((arrayElement,index)=> {
                                                                return {
                                                                    name:getYear(new Date())+index,
                                                                    saving:plan.data?.plan[0].data?.slice(index*12,(index+1)*12).reduce((totalInput,monthlyData)=>{
                                                                        return totalInput+monthlyData.input
                                                                    },0),
                                                                    savingGoal2:plan.data?.plan[1]?.data?.slice(index*12,(index+1)*12).reduce((totalInput,monthlyData)=>{
                                                                        return totalInput+monthlyData.input
                                                                    },0),
                                                                    savingGoal3:plan.data?.plan[2]?.data?.slice(index*12,(index+1)*12).reduce((totalInput,monthlyData)=>{
                                                                        return totalInput+monthlyData.input
                                                                    },0),
                                                                    savingGoal4:plan.data?.plan[3]?.data?.slice(index*12,(index+1)*12).reduce((totalInput,monthlyData)=>{
                                                                        return totalInput+monthlyData.input
                                                                    },0),
                                                                }
                                                            })}
                                                            dataMaxValue={[...new Array(longerTimeHorizonPlusDuration)].map((arrayElement,index)=> {
                                                                return {
                                                                    name:getYear(new Date())+index,
                                                                    saving:plan.data?.plan[0].data?.slice(index*12,(index+1)*12).reduce((totalInput,monthlyData)=>{
                                                                        if(monthlyData.input>monthlyData.output){
                                                                            return totalInput+monthlyData.input
                                                                        }else{
                                                                            return totalInput+monthlyData.output
                                                                        }
                                                                    },0)
                                                                }
                                                            }).reduce((maxSaving,yearSaving)=>{
                                                                if(maxSaving<yearSaving.saving){
                                                                    maxSaving=yearSaving.saving
                                                                }
                                                                return maxSaving
                                                            },0)}
                                                            dataMinValue={0}
                                                            color={colors['--goal-primary-100']}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        style={{width:750}}
                                                    >
                                                        <ChartBarSavingGoal
                                                            height={100}
                                                            goalNumber={plan.data?.plan.length}
                                                            data={[...new Array(longerTimeHorizonPlusDuration)].map((arrayElement,index)=> {
                                                                return {
                                                                    name:getYear(new Date())+index,
                                                                    saving:plan.data?.plan[0].data?.slice(index*12,(index+1)*12).reduce((totalOutput,monthlyData)=>{
                                                                        return totalOutput-monthlyData.output
                                                                    },0),
                                                                    savingGoal2:plan.data?.plan[1]?.data?.slice(index*12,(index+1)*12).reduce((totalOutput,monthlyData)=>{
                                                                        return totalOutput-monthlyData.output
                                                                    },0),
                                                                    savingGoal3:plan.data?.plan[2]?.data?.slice(index*12,(index+1)*12).reduce((totalOutput,monthlyData)=>{
                                                                        return totalOutput-monthlyData.output
                                                                    },0),
                                                                    savingGoal4:plan.data?.plan[3]?.data?.slice(index*12,(index+1)*12).reduce((totalOutput,monthlyData)=>{
                                                                        return totalOutput-monthlyData.output
                                                                    },0),
                                                                }
                                                            })}
                                                            isXaxisHiden={true}
                                                            dataMinValue={-[...new Array(longerTimeHorizonPlusDuration)].map((arrayElement,index)=> {
                                                                return {
                                                                    name:getYear(new Date())+index,
                                                                    saving:plan.data?.plan[0].data?.slice(index*12,(index+1)*12).reduce((totalInput,monthlyData)=>{
                                                                        if(monthlyData.input>monthlyData.output){
                                                                            return totalInput+monthlyData.input
                                                                        }else{
                                                                            return totalInput+monthlyData.output
                                                                        }
                                                                    },0)
                                                                }
                                                            }).reduce((maxSaving,yearSaving)=>{
                                                                if(maxSaving<yearSaving.saving){
                                                                    maxSaving=yearSaving.saving
                                                                }
                                                                return maxSaving
                                                            },0)}
                                                            dataMaxValue={0}
                                                            color={colors['--goal-primary-100']}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            :
                                            <Grid
                                                item
                                                container
                                                style={{overflowX:'auto'}}
                                            >
                                                <Grid
                                                    container
                                                    style={{width:750}}
                                                >
                                                    <Grid
                                                        item
                                                        style={{width:750}}
                                                    >
                                                        <ChartAreaPatrimony
                                                            data={plan.data?.plan[longerPlanDataArrayNumber.index].data?.map((monthlyData,index)=>{
                                                                    let totalInitialInvestment=plan.data?.plan.reduce((totalInitialInvestment,planData)=>{
                                                                        return totalInitialInvestment+planData.initial_investment
                                                                    },0)
                                                                    let totalInput=plan.data?.plan.reduce((totalInput,planData)=> {
                                                                        let input=planData.data[index]?.input===undefined?0:planData.data[index]?.input
                                                                        return totalInput + input
                                                                    },0)
                                                                    let totalPerformance=plan.data?.plan.reduce((totalPerformance,planData)=> {
                                                                        let performance=planData.data[index]?.performance===undefined?0:planData.data[index]?.performance
                                                                        return totalPerformance + performance
                                                                    },0)
                                                                    let totalOutput=plan.data?.plan.reduce((totalOutput,planData)=> {
                                                                        let output=planData.data[index]?.output===undefined?0:planData.data[index]?.output
                                                                        return totalOutput + output
                                                                    },0)
                                                                    return{
                                                                        name:index===0?'':monthlyData.date,
                                                                        patrimony:index===0?(totalInitialInvestment+totalInput+totalPerformance-totalOutput):(totalInput+totalPerformance-totalOutput),
                                                                    }
                                                                }
                                                            ).map(
                                                                (sum => monthlyData => sum={
                                                                    name:monthlyData.name.split('-')[2],
                                                                    patrimony:monthlyData.patrimony+sum.patrimony
                                                                })({name:"",patrimony:0})
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        container
                                                        alignItems={"center"}
                                                    >
                                                        <Grid
                                                            item
                                                            container
                                                            style={{height:"24px",width:"722.28px",background: colors["--white"], border:`1px solid ${colors["--gray-dark"]}`, borderRadius:"2px"}}
                                                        >
                                                            {
                                                                [...new Array(longerTimeHorizonPlusDuration)].map((year,index)=>{
                                                                    return <Grid
                                                                        item
                                                                        container
                                                                        alignItems={"center"}
                                                                        justify={"center"}
                                                                        style={index===([...new Array(longerTimeHorizonPlusDuration)].length-1)?{width:`calc(100% / ${[...new Array(longerTimeHorizonPlusDuration)].length})`}:{borderRight:`1px solid ${colors["--gray-dark"]}`,width:`calc(100% / ${[...new Array(longerTimeHorizonPlusDuration)].length})`}}
                                                                    >
                                                                        <Typography variant={"caption"} style={{fontSize:"10px"}}>
                                                                            {
                                                                                ((longerTimeHorizonPlusDuration>=50 && ((differenceInCalendarYears(new Date(), parse(user.family_situation.birthday, "dd/MM/yyyy", new Date())) + index)%2===0)) || longerTimeHorizonPlusDuration<50) &&
                                                                                (differenceInCalendarYears(new Date(), parse(user.family_situation.birthday, "dd/MM/yyyy", new Date())) + index)

                                                                            }
                                                                        </Typography>
                                                                    </Grid>
                                                                })
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                    }
                                    <Grid
                                        item
                                        container
                                    >
                                        {
                                            charValue===0&&
                                            plan.data?.plan.map((planData,index)=>
                                                <Grid
                                                    item
                                                    container
                                                    className={generalClasses.width_fit_content}
                                                    style={{marginRight:16}}
                                                    alignItems={"center"}
                                                >
                                                    <Grid
                                                        item
                                                        style={{height:8,width:8,background:objectiveColor[index],borderRadius:2}}
                                                    />
                                                    <Grid
                                                        item
                                                    >
                                                        <Typography display={"inline"} variant={"caption"}>
                                                            &nbsp;{planData.objective.name}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </Responsive>


                        </Grid>
                        {/*
                            THIRD ROW
                        */}
                        {
                            process.env.REACT_APP_ENVIRONMENT!=='planifica' &&
                            <Grid
                                item
                                container
                                style={{marginTop:24}}
                            >
                                <Grid
                                    item
                                    container
                                    className={classes.keep_plan_updated_container}
                                    direction={"column"}
                                >
                                    <Grid
                                        item
                                        style={{marginBottom:8}}
                                    >
                                        <Typography variant={"h4"}>
                                            Mantén tu plan al día
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                    >
                                        <Typography variant={"body2"}>
                                            Con tus datos financieros al día, más personalizado será tu plan
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                    >
                                        <Link underline={"always"}> Cómo personalizamos tu plan</Link>
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    container
                                    justify={"space-between"}
                                    className={classes.friends_container}
                                >
                                    <Grid
                                        item
                                        container
                                        style={{width:'fit-content'}}
                                        direction={"column"}
                                    >
                                        <Grid
                                            item
                                        >
                                            <Typography variant={"h4"} style={{color:colors['--brand-accent-100']}}>
                                                !Tú te ahorras {config.currency_prefix}25{config.currency_suffix} y tus amigos estarán más cerca de alcanzar sus metas!
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                        >
                                            <Typography variant={"body2"} style={{color:colors['--white']}}>
                                                Te reduciremos {config.currency_prefix}25{config.currency_suffix} de tu próxima aportación mensual si compartes con tus amigos
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                            className={classes.share_with_friends_button}
                                        >
                                            <Button
                                                style={{background:colors['--brand-main-100'],boxShadow:'none', borderRadius:100}}
                                                variant={"contained"}
                                                color={"primary"}
                                                onClick={()=>{
                                                }}
                                            >
                                                Compartir con mis amigos
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        item
                                    >
                                        <PromoImage/>
                                    </Grid>
                                </Grid>
                            </Grid>

                        }
                    </Grid>
                </Box>
            </Box>
        </Grid>
    )
}
