import React, {useEffect} from "react";
import {
    Container,
    Grid,
    List,
    Typography,
    ListItem,
    Avatar,
    ListItemAvatar,
    ListItemText,
    withStyles
} from "@material-ui/core";
import {colors} from "../../styles/colors/colors";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {Helmet} from "react-helmet";
import {logotypeSrc} from "../../utils/logotype";

const AvatarLegal=withStyles(theme=>({
    root:{
        backgroundColor:colors['--white'],
        color:colors['--black-soft'],
        [theme.breakpoints.down("sm")]:{
            display:'none'
        }
    }
}))(Avatar)
const ListItemAvatarLegal=withStyles(theme=>({
    root:{
        [theme.breakpoints.down("sm")]:{
            display:'none'
        }
    }
}))(ListItemAvatar)
const ListItemLegal=withStyles(theme=>({
    root:{
        paddingTop:0,
        paddingBottom:0
    }
}))(ListItem)

export default function LegalPrivacy(){
    useEffect(() => {
        window.dataLayer.push({
            'event':'setTitle',
            'title':'Politica de privacidad'
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const logoSrc=logotypeSrc()
    return(
        <Container
            maxWidth={"md"}
            style={{padding:'20px 0'}}
        >
            <Helmet>
                <title>Política de privacidad</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <Grid
                container
                spacing={4}
                style={{padding:'0 20px'}}
            >
                <Grid
                    item
                >
                    <img alt={""} src={logoSrc} style={{width:150}}/>
                </Grid>
                <Grid
                    item
                    container
                    direction={"column"}
                    spacing={4}
                >
                    <Grid
                        item
                        container
                        direction={"column"}
                        alignItems={"center"}
                    >
                        <Grid
                            item
                        >
                            <Typography variant={"h1"}>
                                Política de Privacidad
                            </Typography>
                        </Grid>
                        <Grid
                            item
                        >
                            <Typography variant={"h4"} style={{color:colors['--interactive']}}>
                                Información completa sobre Protección de Datos
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                    >
                        <Typography variant={"body2"}>
                            En cumplimiento del reglamento relativo a la protección de las personas físicas en lo que
                            respecta al tratamiento de datos personales y a la libre circulación de estos datos y a Ley
                            Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los
                            derechos digitales, se le informa que los datos personales proporcionados por usted a través
                            del sitio web inveert.com (en adelante el sitio web), serán tratados en los siguientes términos:
                        </Typography>
                    </Grid>
                    {/*
                    FIRST POINT
                    */}
                    <Grid
                        item
                        container
                        direction={"column"}
                        spacing={1}
                    >
                        <Grid
                            item
                        >
                            <Typography variant={"h3"} style={{color:colors['--inveert-main-100']}}>
                                1. ¿Quién es el responsable del tratamiento de sus datos?
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            direction={"column"}
                        >
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    INVEERT INTELIGENCIA ARTIFICIAL RACIONAL S.L. (en adelante INVEERT)
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    CIF: B90411349.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    DOMICILIO: C/ Tabladilla nº 7, Portal B, 3ª A - 41013, SEVILLA.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Datos de contacto del Delegado de Protección de Datos (DPO): privacidad@inveert.com
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*
                    SECOND POINT
                    */}
                    <Grid
                        item
                        container
                        direction={"column"}
                        spacing={1}
                    >
                        <Grid
                            item
                        >
                            <Typography variant={"h3"} style={{color:colors['--inveert-main-100']}}>
                                2. ¿Con qué finalidad tratamos sus datos personales?
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            direction={"column"}
                        >
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    En INVEERT tratamos la información que nos facilitan las personas interesadas con el fin de:
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <List>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Informar al usuario acerca de nuestros productos y servicios, cada vez
                                                que lo solicite a través del sitio web o por correo electrónico.
                                            </Typography>}
                                        />
                                    </ListItemLegal>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Contestar y gestionar las consultas, comentarios y sugerencias de los usuarios del sitio web.
                                            </Typography>}
                                        />
                                    </ListItemLegal>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Responder a las consultas de usuarios a través del asistente virtual de la web, donde los datos como la IP serán tratados por INVEERT únicamente para mejorar el entendimiento y la visibilidad de los contenidos en la web.
                                            </Typography>}
                                        />
                                    </ListItemLegal>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Gestionar su participación en procesos de selección, cuando nos haga llegar sus datos (incluido su currículo) con dicha finalidad.
                                            </Typography>}
                                        />
                                    </ListItemLegal>
                                </List>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Obtener información del uso del sitio web, analizando el número de páginas visitadas,
                                    el número de visitas, así como la actividad de los visitantes y su frecuencia de
                                    utilización. A estos efectos, INVEERT utiliza información estadística elaborada por el
                                    Proveedor de Servicios de Internet que no permite identificar al interesado en ningún momento.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*
                    THIRD POINT
                    */}
                    <Grid
                        item
                        container
                        direction={"column"}
                        spacing={1}
                    >
                        <Grid
                            item
                        >
                            <Typography variant={"h3"} style={{color:colors['--inveert-main-100']}}>
                                3. ¿Por cuánto tiempo conservaremos sus datos?
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            direction={"column"}
                            spacing={2}
                        >
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Los datos personales facilitados por el usuario para solicitar nuestros servicios
                                    serán tratados mientras sea necesario para cumplir la relación contractual con los
                                    mismos. Los datos personales se conservarán posteriormente mientras puedan derivarse
                                    responsabilidades de su tratamiento, siendo completamente eliminados tras este periodo.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Cuando el usuario envíe sus datos para contactar con INVEERT, realizar consultas o
                                    sugerencias, los datos se conservarán mientras sea necesario para tal fin y puedan
                                    derivarse responsabilidades del tratamiento.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Cuando una persona envíe sus datos personales para un proceso de selección en
                                    INVEERT, los datos serán tratados durante su participación en el proceso, y si no
                                    fuera seleccionado, se conservarán durante un periodo de un año, con el fin de poder
                                    contar con su participación en futuros procesos.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*
                    FOURTH POINT
                    */}
                    <Grid
                        item
                        container
                        direction={"column"}
                        spacing={1}
                    >
                        <Grid
                            item
                        >
                            <Typography variant={"h3"} style={{color:colors['--inveert-main-100']}}>
                                4. ¿Cuál es la legitimación para el tratamiento de sus datos?
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            direction={"column"}
                        >
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Le indicamos la base legal para el tratamiento de sus datos:
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <List>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Consentimiento del interesado: Los datos personales obtenidos a través
                                                de los formularios del sitio web serán tratados únicamente en base al
                                                consentimiento otorgado por el interesado, aceptando la casilla dispuesta
                                                para tal fin. Este consentimiento puede ser revocado en cualquier momento
                                                por parte del usuario.
                                            </Typography>}
                                        />
                                    </ListItemLegal>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Ejecución de un contrato: Los datos personales obtenidos para la
                                                prestación de nuestros servicios serán tratados en base al cumplimiento
                                                de un contrato en el que el interesado es parte.
                                            </Typography>}
                                        />
                                    </ListItemLegal>
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*
                    FIFTH POINT
                    */}
                    <Grid
                        item
                        container
                        direction={"column"}
                        spacing={1}
                    >
                        <Grid
                            item
                        >
                            <Typography variant={"h3"} style={{color:colors['--inveert-main-100']}}>
                                5. ¿A qué destinatarios se comunicarán sus datos?
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                        >
                            <Typography variant={"body2"}>
                                En caso de ser necesario para la prestación de alguno de nuestros servicios, es
                                posible que los datos de los usuarios sean comunicados a un tercero, como entidades
                                que completen nuestra oferta de servicios, con la finalidad de gestionar y posibilitar
                                dichos servicios. Esta cesión será en todo caso necesaria y se realizará respetando las
                                exigencias de la normativa de protección de datos.
                            </Typography>
                        </Grid>
                    </Grid>
                    {/*
                    SIXTH POINT
                    */}
                    <Grid
                        item
                        container
                        direction={"column"}
                        spacing={1}
                    >
                        <Grid
                            item
                        >
                            <Typography variant={"h3"} style={{color:colors['--inveert-main-100']}}>
                                6. Política de protección de menores
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                        >
                            <Typography variant={"body2"}>
                                INVEERT prohíbe el acceso y uso del portal a los menores de 18 años. Los datos aportados
                                por los usuarios a través de los formularios de la web tienen la aceptación formal del
                                interesado de ser mayor de edad.
                            </Typography>
                        </Grid>
                    </Grid>
                    {/*
                    SEVENTH POINT
                    */}
                    <Grid
                        item
                        container
                        direction={"column"}
                        spacing={1}
                    >
                        <Grid
                            item
                        >
                            <Typography variant={"h3"} style={{color:colors['--inveert-main-100']}}>
                                7. ¿Cuáles son sus derechos cuando nos facilita sus datos?
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            direction={"column"}
                            spacing={2}
                        >
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Cualquier persona tiene derecho a obtener confirmación sobre si en INVEERT estamos
                                    tratando, o no, datos personales que les conciernen.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Las personas interesadas tienen derecho a acceder a sus datos personales, así como
                                    a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su
                                    supresión cuando, entre otros motivos, los datos ya no sean necesarios para los
                                    fines que fueron recogidos. Igualmente tiene derecho a la portabilidad de sus datos.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    En determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    En determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán oponerse al tratamiento de sus datos. En este caso, INVEERT dejará de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Podrá ejercitar materialmente sus derechos de la siguiente forma: dirigiéndose a la dirección del responsable del tratamiento.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Cuando se realice el envío de comunicaciones comerciales utilizando como base jurídica el interés legítimo del responsable, el interesado podrá oponerse al tratamiento de sus datos con ese fin.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Si ha otorgado su consentimiento para alguna finalidad concreta, tiene derecho a retirar el consentimiento otorgado en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    En caso de que sienta vulnerados sus derechos en lo concerniente a la protección de sus datos personales, especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos, puede presentar una reclamación ante la Autoridad de Control en materia de Protección de Datos competente a través de su sitio web: www.agpd.es
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                    {/*
                    EIGHTH POINT
                    */}
                    <Grid
                        item
                        container
                        direction={"column"}
                        spacing={1}
                    >
                        <Grid
                            item
                        >
                            <Typography variant={"h3"} style={{color:colors['--inveert-main-100']}}>
                                8. ¿Cómo hemos obtenido sus datos?
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                        >
                            <Typography variant={"body2"}>
                                Los datos personales que tratamos en INVEERT proceden del propio interesado, previa autorización.
                            </Typography>
                        </Grid>
                    </Grid>
                    {/*
                    NINTH POINT
                    */}
                    <Grid
                        item
                        container
                        direction={"column"}
                        spacing={1}
                    >
                        <Grid
                            item
                        >
                            <Typography variant={"h3"} style={{color:colors['--inveert-main-100']}}>
                                9. Modificación política de privacidad
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                        >
                            <Typography variant={"body2"}>
                                INVEERT se reserva el derecho a modificar, en cualquier momento, la presente Política de Privacidad comprometiéndose a, en caso de modificar las condiciones de manera sustancial, comunicar las nuevas condiciones que se hayan podido introducir al usuario. Es por ello que se recomienda la revisión periódica de nuestra Política de Privacidad, ya que resultará de aplicación aquella que se encuentre vigente en el momento de uso de las Páginas Web y Apps.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}
