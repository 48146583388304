import {addYears, differenceInCalendarMonths, differenceInCalendarYears, format, parse} from "date-fns";

export function onboardingGoalsFormHelpersParseFormData(firstObjectiveAndUserData){
    const {firstObjective,user,config}=firstObjectiveAndUserData
    switch (firstObjective.type){
        case "EDUCATION":
            const educationDurationMonths=differenceInCalendarMonths(
                parse(firstObjective['date_end'],'dd/MM/yyyy',new Date()),
                parse(firstObjective['date_start'],'dd/MM/yyyy',new Date())
            )
            const educationTerm=differenceInCalendarYears(
                parse(firstObjective['date_start'],'dd/MM/yyyy',new Date()),
                new Date()
            )
            const educationDurationType=educationDurationMonths>24 ? "ANNUAL" : "UNIQUE"
            const educationProtectionType=educationDurationMonths>24 ? "SHORT_TERM" : "CAPITAL"

            return {
                type:firstObjective.type,
                output_type:educationDurationType,
                protection_type:educationProtectionType,
                name:"Educacion"+(firstObjective['beneficiary']!=="me"? firstObjective.beneficiary_name!==null? " de "+firstObjective['beneficiary_name']:"":""),
                amount:firstObjective.cost,
                amount_type:educationDurationType,
                term:educationTerm,
                term_type:"YEAR",
                duration:educationDurationMonths,
                duration_type:"MONTHLY",
                receiver:firstObjective.beneficiary,
                recipient:firstObjective.beneficiary==="me"?"me":firstObjective.beneficiary_name,
                date_init:format(parse(firstObjective['date_start'],'dd/MM/yyyy',new Date()),"yyyy-MM-dd"),
                date_end:format(parse(firstObjective['date_end'],'dd/MM/yyyy',new Date()),"yyyy-MM-dd")
            }
        case "TRAVEL":
            const travelTerm=differenceInCalendarYears(
                parse(firstObjective['date_start'],'dd/MM/yyyy',new Date()),
                new Date()
            )
            return {
                type:firstObjective.type,
                output_type:"UNIQUE",
                protection_type:"CAPITAL",
                name:"Viaje a "+firstObjective.travel_location,
                amount:firstObjective.cost_final,
                amount_type:"UNIQUE",
                term:travelTerm,
                term_type:"YEAR",
                duration:(differenceInCalendarMonths(
                    parse(firstObjective['date_end'],'dd/MM/yyyy',new Date()),
                    parse(firstObjective['date_start'],'dd/MM/yyyy',new Date())
                )),
                duration_type:"MONTHLY",
                //stop_savings:false,
                date_init:format(parse(firstObjective['date_start'],'dd/MM/yyyy',new Date()),"yyyy-MM-dd"),
                date_end:format(parse(firstObjective['date_end'],'dd/MM/yyyy',new Date()),"yyyy-MM-dd"),
                extra_info:{total_amount:firstObjective.cost}
            }
        case "CUSTOM":
            const customTerm=differenceInCalendarYears(
                parse(firstObjective['date_start'],'dd/MM/yyyy',new Date()),
                new Date()
            )
            const customProtectionType=firstObjective.receive_money==="UNIQUE"?"CAPITAL":"SHORT_TERM"
            return {
                type:firstObjective.type,
                output_type:firstObjective.receive_money,
                protection_type:customProtectionType,
                name:firstObjective.goal_name+(firstObjective['beneficiary']!=="me"? firstObjective.beneficiary_name!==null? " de "+firstObjective['beneficiary_name']:"":""),
                amount:firstObjective.cost,
                amount_type:firstObjective.receive_money,
                term:customTerm,
                term_type:"YEAR",
                duration:firstObjective.receive_money==="UNIQUE"?
                    undefined
                        :
                    (differenceInCalendarMonths(
                    parse(firstObjective['date_end'],'dd/MM/yyyy',new Date()),
                    parse(firstObjective['date_start'],'dd/MM/yyyy',new Date())
                )),
                duration_type:firstObjective.receive_money==="UNIQUE"?undefined:"MONTHLY",
                receiver:firstObjective.beneficiary,
                recipient:firstObjective.beneficiary==="me"?"me":firstObjective.beneficiary_name,
                date_init:format(parse(firstObjective['date_start'],'dd/MM/yyyy',new Date()),"yyyy-MM-dd"),
                date_end:firstObjective.receive_money==="UNIQUE"?undefined:format(parse(firstObjective['date_end'],'dd/MM/yyyy',new Date()),"yyyy-MM-dd")
            }
        case "REAL_STATE":
            const realStateTerm=differenceInCalendarYears(
                parse(firstObjective['date_start'],'dd/MM/yyyy',new Date()),
                new Date()
            )
            return {
                type:firstObjective.type,
                output_type:"UNIQUE",
                protection_type:"CAPITAL",
                name:process.env.REACT_APP_ENVIRONMENT==='planifica'?"Cuota inicial casa":"Entrada casa"+(firstObjective['beneficiary']!=="me"? firstObjective.beneficiary_name!==null? " de "+firstObjective['beneficiary_name']:"":""),
                amount:firstObjective.cost_final,
                amount_type:"UNIQUE",
                term:realStateTerm,
                term_type:"YEAR",
                receiver:firstObjective.beneficiary,
                recipient:firstObjective.beneficiary==="me"?"me":firstObjective.beneficiary_name,
                date_init:format(parse(firstObjective['date_start'],'dd/MM/yyyy',new Date()),"yyyy-MM-dd"),
                extra_info:{total_amount:firstObjective.cost}
            }
        case "RETIREMENT":
            return {
                type:firstObjective.type,
                output_type:"MONTHLY",
                protection_type:"LONG_TERM",
                name:"Mi jubilación",
                amount:firstObjective.retirement_complement,
                amount_type:"MONTHLY",
                term:firstObjective.retirement_age,
                term_type:"AGE",
                stop_savings: true,
                date_init: format(addYears(parse(user.family_situation.birthday,'dd/MM/yyyy',new Date()),firstObjective.retirement_age),'yyyy-MM-dd'),
                date_end:format(addYears(parse(user.family_situation.birthday,'dd/MM/yyyy',new Date()),user.family_situation.gender==="H"?config?.death_age_man:config?.death_age_woman),'yyyy-MM-dd'),
                extra_info: {monthly_self_employed_fee:firstObjective.monthly_self_employed_fee}

            }
        default:
            return
    }
}
export function onboardingGoalsFormHelpersParseRetirementFormData(firstObjectiveAndUserData){
    const {user,firstObjective}=firstObjectiveAndUserData
    return {
        "years_contributed": firstObjective.contributing_years,
        "age": differenceInCalendarYears(
            new Date(),
            parse(user['family_situation'].birthday,'dd/MM/yyyy',new Date())
        ),
        "retirement_age": firstObjective.retirement_age,
        "gender": user.family_situation.gender,
        "current_children": user.family_situation.current_children,
        "net_income": firstObjective.employment_status==="salaried"?firstObjective.gross_annual_salary : firstObjective.monthly_self_employed_fee,
        "is_freelance": firstObjective.employment_status!=="salaried"
    }
}

export function onboardingGoalsEducationBeneficiaryRadioGroup(user){
    let radioGroup
    const {family_situation}=user
    if(family_situation?.has_partner===true && family_situation.current_children>0){
        radioGroup={
            title:"",
            name:"beneficiary",
            showRequiredSign:false,
            rowDirection:false,
            items:[
                {
                    value:"me",
                    label:"Para mi",
                    imageSrc:null
                },
                {
                    value:"partner",
                    label:"Para mi pareja",
                    imageSrc:null
                },
                {
                    value:"son",
                    label:"Para mi hijo/a",
                    imageSrc:null
                },
                {
                    value:"other",
                    label:"Para alguien más",
                    imageSrc:null
                }
            ]
        }
    }else if(family_situation?.has_partner===false && family_situation.current_children>0){
        radioGroup={
            title:"",
            name:"beneficiary",
            showRequiredSign:false,
            rowDirection:false,
            items:[
                {
                    value:"me",
                    label:"Para mi",
                    imageSrc:null
                },
                {
                    value:"son",
                    label:"Para mi hijo/a",
                    imageSrc:null
                },
                {
                    value:"other",
                    label:"Para alguien más",
                    imageSrc:null
                }
            ]
        }
    }else if(family_situation?.has_partner===true && family_situation.current_children===0){
        radioGroup={
            title:"",
            name:"beneficiary",
            showRequiredSign:false,
            rowDirection:false,
            items:[
                {
                    value:"me",
                    label:"Para mi",
                    imageSrc:null
                },
                {
                    value:"partner",
                    label:"Para mi pareja",
                    imageSrc:null
                },
                {
                    value:"other",
                    label:"Para alguien más",
                    imageSrc:null
                }
            ]
        }
    }else{
        radioGroup={
            title:"",
            name:"beneficiary",
            showRequiredSign:false,
            rowDirection:false,
            items:[
                {
                    value:"me",
                    label:"Para mi",
                    imageSrc:null
                },
                {
                    value:"other",
                    label:"Para alguien más",
                    imageSrc:null
                }
            ]
        }
    }
    return radioGroup
}
export function onboardingGoalsRealStateBeneficiaryRadioGroup(user){
    let radioGroup
    const {family_situation}=user
    if(family_situation?.has_partner===true && family_situation.current_children>0){
        radioGroup={
            title:"",
            name:"beneficiary",
            showRequiredSign:false,
            rowDirection:false,
            items:[
                {
                    value:"me",
                    label:"Para mi y mi pareja",
                    imageSrc:null
                },
                {
                    value:"son",
                    label:"Para mi hijo/a",
                    imageSrc:null
                },
                {
                    value:"other",
                    label:"Para alguien más",
                    imageSrc:null
                }
            ]
        }
    }else if(family_situation?.has_partner===false && family_situation.current_children>0){
        radioGroup={
            title:"",
            name:"beneficiary",
            showRequiredSign:false,
            rowDirection:false,
            items:[
                {
                    value:"me",
                    label:"Para mi",
                    imageSrc:null
                },
                {
                    value:"son",
                    label:"Para mi hijo/a",
                    imageSrc:null
                },
                {
                    value:"other",
                    label:"Para alguien más",
                    imageSrc:null
                }
            ]
        }
    }else if(family_situation?.has_partner===true && family_situation.current_children===0){
        radioGroup={
            title:"",
            name:"beneficiary",
            showRequiredSign:false,
            rowDirection:false,
            items:[
                {
                    value:"me",
                    label:"Para mi y mi pareja",
                    imageSrc:null
                },
                {
                    value:"other",
                    label:"Para alguien más",
                    imageSrc:null
                }
            ]
        }
    }else{
        radioGroup={
            title:"",
            name:"beneficiary",
            showRequiredSign:false,
            rowDirection:false,
            items:[
                {
                    value:"me",
                    label:"Para mi",
                    imageSrc:null
                },
                {
                    value:"other",
                    label:"Para alguien más",
                    imageSrc:null
                }
            ]
        }
    }
    return radioGroup
}

export function onboardingGoalsCustomBeneficiaryRadioGroup(user){
    let radioGroup
    const {family_situation}=user
    if(family_situation?.has_partner===true && family_situation.current_children>0){
        radioGroup={
            title:"",
            name:"beneficiary",
            showRequiredSign:false,
            rowDirection:false,
            items:[
                {
                    value:"me",
                    label:"Para mi",
                    imageSrc:null
                },
                {
                    value:"us",
                    label:"Para mi y mi pareja",
                    imageSrc:null
                },
                {
                    value:"partner",
                    label:"Para mi pareja",
                    imageSrc:null
                },
                {
                    value:"son",
                    label:"Para mi hijo/a",
                    imageSrc:null
                },
                {
                    value:"other",
                    label:"Para alguien más",
                    imageSrc:null
                }
            ]
        }
    }else if(family_situation?.has_partner===false && family_situation.current_children>0){
        radioGroup={
            title:"",
            name:"beneficiary",
            showRequiredSign:false,
            rowDirection:false,
            items:[
                {
                    value:"me",
                    label:"Para mi",
                    imageSrc:null
                },
                {
                    value:"son",
                    label:"Para mi hijo/a",
                    imageSrc:null
                },
                {
                    value:"other",
                    label:"Para alguien más",
                    imageSrc:null
                }
            ]
        }
    }else if(family_situation?.has_partner===true && family_situation.current_children===0){
        radioGroup={
            title:"",
            name:"beneficiary",
            showRequiredSign:false,
            rowDirection:false,
            items:[
                {
                    value:"me",
                    label:"Para mi",
                    imageSrc:null
                },
                {
                    value:"us",
                    label:"Para mi y mi pareja",
                    imageSrc:null
                },
                {
                    value:"partner",
                    label:"Para mi pareja",
                    imageSrc:null
                },
                {
                    value:"other",
                    label:"Para alguien más",
                    imageSrc:null
                }
            ]
        }
    }else{
        radioGroup={
            title:"",
            name:"beneficiary",
            showRequiredSign:false,
            rowDirection:false,
            items:[
                {
                    value:"me",
                    label:"Para mi",
                    imageSrc:null
                },
                {
                    value:"other",
                    label:"Para alguien más",
                    imageSrc:null
                }
            ]
        }
    }
    return radioGroup
}