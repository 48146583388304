import React from "react";
import {ReactComponent as EducationSvg} from "../../assets/goals/goal-education.svg";
import {ReactComponent as TravelSvg} from "../../assets/goals/goal-travel.svg";
import {ReactComponent as RetirementSvg} from "../../assets/goals/goal-retirement.svg";
import {ReactComponent as RealStateSvg} from "../../assets/goals/goal-real-state.svg";
import {ReactComponent as CustomSvg} from "../../assets/goals/goal-custom.svg";
import {ReactComponent as SafetyNetSvg} from "../../assets/goals/goal-safety-net.svg";

function GoalSvg(props){
    const{type, color, width}=props
    switch (type){
        case "EDUCATION":
            return <EducationSvg fill={color} width={width?width:48}/>
        case "TRAVEL":
            return <TravelSvg fill={color} width={width?width:48}/>
        case "CUSTOM":
            return <CustomSvg fill={color} width={width?width:48}/>
        case "RETIREMENT":
            return <RetirementSvg fill={color} width={width?width:48}/>
        case "REAL_STATE":
            return <RealStateSvg fill={color} width={width?width:48}/>
        case "SAFETY_NET":
            return <SafetyNetSvg fill={color} width={width?width:48}/>
        default:
            return <EducationSvg fill={color} width={width?width:48}/>
    }
}
export default GoalSvg