import React, {useEffect} from "react";
import {
    Avatar,
    Container,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText, TableBody, TableCell, TableHead, TableRow, Table, TableContainer,
    Typography,
    Paper,
    withStyles, Link
} from "@material-ui/core";
import {colors} from '../../styles/colors/colors'
import {Helmet} from "react-helmet";
import {logotypeSrc} from "../../utils/logotype";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const rows = [
    {  id: 'Consent', source: 'Google', duration: '20 años', type: 'Analítica', description: 'Se usa para el funcionamiento del mapa de Google en la página de contacto.' },
    {  id: 'hubspotutk', source: 'Hubspot', duration: '13 meses', type: 'Analítica', description: 'Esta cookie se usa para hacer seguimiento de la identidad de un visitante. Se pasa a HubSpot en el envío de formularios y se usa al eliminar la duplicación de contactos.' },
    {  id: '__hstc', source: 'Hubspot', duration: '13 meses', type: 'Analítica', description: 'Cookie principal para el seguimiento de los visitantes . Contiene el dominio, utk, marca de tiempo inicial, marca de tiempo más reciente, marca de tiempo actual  y número de sesión.' },
    {  id: '_gaexp', source: 'Google', duration: '90 días', type: 'Analítica', description: 'Cookie estadística de Google. Optimiza la funcionalidad del sitio web.' },
    {  id: 'ajs_anonymous_id', source: 'Segment', duration: '1 año', type: 'Analítica', description: 'Se usa para la integración con la plataforma Segment.io para recopilar datos de actividad de usuarios.' },
    {  id: 'ajs_user_id', source: 'Segment', duration: '1 año', type: 'Analítica', description: 'integración con la plataforma Segment.io para recopilar datos de actividad de usuarios.' },
    {  id: '_gcl_au', source: 'Google', duration: 'Sesión', type: 'Analítica', description: 'Cookie estadística de Google. Optimiza la funcionalidad del sitio web.' },
    {  id: '_ga', source: 'Google', duration: '2 años', type: 'Analítica', description: 'Se usa para controlar los visitantes únicos de la web, almacena un identificador de cliente único.' },
    {  id: '_fbp', source: 'Facebook', duration: '2 años', type: 'Analítica', description: 'Utilizada por Facebook para proporcionar una serie de productos publicitarios como pujas en tiempo real de terceros anunciantes.' },
];

const AvatarLegal=withStyles(theme=>({
    root:{
        backgroundColor:colors['--white'],
        color:colors['--black-soft'],
        [theme.breakpoints.down("sm")]:{
            display:'none'
        }
    }
}))(Avatar)
const ListItemAvatarLegal=withStyles(theme=>({
    root:{
        [theme.breakpoints.down("sm")]:{
            display:'none'
        }
    }
}))(ListItemAvatar)
const ListItemLegal=withStyles(theme=>({
    root:{
        paddingTop:0,
        paddingBottom:0
    }
}))(ListItem)

export default function LegalCookies(){
    useEffect(() => {
        window.dataLayer.push({
            'event':'setTitle',
            'title':'Politica de cookies'
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const logoSrc=logotypeSrc()
    return(
        <Container
            maxWidth={"md"}
            style={{padding:'20px 0'}}
        >
            <Helmet>
                <title>Política de cookies</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <Grid
                container
                spacing={4}
                style={{padding:'0 20px'}}
            >
                <Grid
                    item
                >
                    <img alt={""} src={logoSrc} style={{width:150}}/>
                </Grid>
                <Grid
                    item
                    container
                    direction={"column"}
                    spacing={4}
                >
                    <Grid
                        item
                        container
                        direction={"column"}
                        alignItems={"center"}
                    >
                        <Grid
                            item
                        >
                            <Typography variant={"h1"}>
                                Política de Cookies
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                    >
                        <Typography variant={"body2"}>
                            En cumplimiento de la entrada en vigor de la modificación de la «Ley de Servicios de la Sociedad de la Información» (LSSICE) establecida por el Real Decreto 13/2012, es de obligación obtener el consentimiento expreso del usuario de todas las páginas web que usan cookies prescindibles, antes de que éste navegue por ellas.
                        </Typography>
                    </Grid>
                    {/*
                    FIRST POINT
                    */}
                    <Grid
                        item
                        container
                        direction={"column"}
                        spacing={1}
                    >
                        <Grid
                            item
                        >
                            <Typography variant={"h3"} style={{color:colors['--inveert-main-100']}}>
                                ¿Qué son las cookies?
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            direction={"column"}
                            spacing={2}
                        >
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Las cookies son archivos que se pueden descargar y almacenar en el equipo del usuario a través de las páginas web.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Son herramientas que tienen un papel esencial para la prestación de numerosos servicios de la sociedad de la información. Entre otros, permiten a una página web almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información obtenida, se pueden utilizar para reconocer al usuario y mejorar el servicio ofrecido.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*
                    SECOND POINT
                    */}
                    <Grid
                        item
                        container
                        direction={"column"}
                        spacing={1}
                    >
                        <Grid
                            item
                        >
                            <Typography variant={"h3"} style={{color:colors['--inveert-main-100']}}>
                                Tipos de cookies
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            direction={"column"}
                        >
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Según quien sea la entidad que gestione el dominio desde donde se envían las cookies y trate los datos que se obtengan se pueden distinguir dos tipos:
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <List>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Cookies propias: aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.
                                            </Typography>}
                                        />
                                    </ListItemLegal>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Cookies de terceros: aquéllas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.
                                            </Typography>}
                                        />
                                    </ListItemLegal>
                                </List>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    En el caso de que las cookies sean instaladas desde un equipo o dominio gestionado por el propio editor pero la información que se recoja mediante éstas sea gestionada por un tercero, no pueden ser consideradas como cookies propias.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Existe también una segunda clasificación según el plazo de tiempo que permanecen almacenadas en el navegador del cliente, pudiendo tratarse de:
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <List>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Cookies de sesión: diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. Se suelen emplear para almacenar información que solo interesa conservar para la prestación del servicio solicitado por el usuario en una sola ocasión (p.e. una lista de productos adquiridos).
                                            </Typography>}
                                        />
                                    </ListItemLegal>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Cookies persistentes: los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.
                                            </Typography>}
                                        />
                                    </ListItemLegal>
                                </List>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Por último, existe otra clasificación con cinco tipos de cookies según la finalidad para la que se traten los datos obtenidos:
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <List>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Cookies técnicas: aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un pedido, realizar el proceso de compra de un pedido, realizar la solicitud de inscripción o participación en un evento, utilizar elementos de seguridad durante la navegación, almacenar contenidos para la difusión de vídeos o sonido o compartir contenidos a través de redes sociales.
                                            </Typography>}
                                        />
                                    </ListItemLegal>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Cookies de personalización: permiten al usuario acceder al servicio con algunas características de carácter general predefinidas en función de una serie de criterios en el terminal del usuario como por ejemplo serian el idioma, el tipo de navegador a través del cual accede al servicio, la configuración regional desde donde accede al servicio, etc.
                                            </Typography>}
                                        />
                                    </ListItemLegal>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Cookies de análisis: permiten al responsable de las mismas, el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para la elaboración de perfiles de navegación de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.
                                            </Typography>}
                                        />
                                    </ListItemLegal>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Cookies publicitarias: permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios.
                                            </Typography>}
                                        />
                                    </ListItemLegal>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Cookies de publicidad comportamental: almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.
                                            </Typography>}
                                        />
                                    </ListItemLegal>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Cookies de redes sociales externas: se utilizan para que los visitantes puedan interactuar con el contenido de diferentes plataformas sociales (facebook, youtube, twitter, linkedIn, etc..) y que se generen únicamente para los usuarios de dichas redes sociales. Las condiciones de utilización de estas cookies y la información recopilada se regula por la política de privacidad de la plataforma social correspondiente.
                                            </Typography>}
                                        />
                                    </ListItemLegal>
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*
                    THIRD POINT
                    */}
                    <Grid
                        item
                        container
                        direction={"column"}
                        spacing={1}
                    >
                        <Grid
                            item
                        >
                            <Typography variant={"h3"} style={{color:colors['--inveert-main-100']}}>
                                Cookies utilizadas en www.inveert.com
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            direction={"column"}
                            spacing={2}
                        >
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    A continuación, se identifican las cookies que están siendo utilizadas en este portal así como su tipología y función:
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <List>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Desde la web de inveert no se utilizan cookies propias.
                                            </Typography>}
                                        />
                                    </ListItemLegal>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Las cookies terceras utilizadas son las siguientes:
                                            </Typography>}
                                        />
                                    </ListItemLegal>
                                </List>
                            </Grid>
                            <Grid
                                item

                            >
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>COOKIE</TableCell>
                                                <TableCell >ORIGEN</TableCell>
                                                <TableCell >DURACIÓN</TableCell>
                                                <TableCell >TIPO</TableCell>
                                                <TableCell >PROPÓSITO</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow key={row.id}>
                                                    <TableCell component="th" scope="row">
                                                        {row.id}
                                                    </TableCell>
                                                    <TableCell >{row.source}</TableCell>
                                                    <TableCell >{row.duration}</TableCell>
                                                    <TableCell >{row.type}</TableCell>
                                                    <TableCell >{row.description}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*
                    FOURTH POINT
                    */}
                    <Grid
                        item
                        container
                        direction={"column"}
                        spacing={1}
                    >
                        <Grid
                            item
                        >
                            <Typography variant={"h3"} style={{color:colors['--inveert-main-100']}}>
                                Desactivación y eliminación de cookies
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            direction={"column"}
                        >
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Tienes la opción de permitir, bloquear o eliminar las cookies instaladas en tu equipo mediante la configuración de las opciones del navegador instalado en su equipo. Al desactivar cookies, algunos de los servicios disponibles podrían dejar de estar operativos. La forma de deshabilitar las cookies es diferente para cada navegador, pero normalmente puede hacerse desde el menú Herramientas u Opciones. También puede consultarse el menú de Ayuda del navegador donde puedes encontrar instrucciones. El usuario podrá en cualquier momento elegir qué cookies quiere que funcionen en este sitio web.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador:
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <List>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Microsoft Internet Explorer o Microsoft Edge:
                                            </Typography>}
                                            secondary={<Link href={'http://windows.microsoft.com/es-es/windows-vista/Block-or-allow-cookies'}>
                                                http://windows.microsoft.com/es-es/windows-vista/Block-or-allow-cookies
                                            </Link>}
                                        />
                                    </ListItemLegal>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Mozilla Firefox:
                                            </Typography>}
                                            secondary={<Link href={'http://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia '}>
                                                http://support.mozilla.org/es/kb/impedir-que-los-sitios-web-guarden-sus-preferencia
                                            </Link>}
                                        />
                                    </ListItemLegal>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Chrome:
                                            </Typography>}
                                            secondary={<Link href={' https://support.google.com/accounts/answer/61416?hl=es '}>
                                                https://support.google.com/accounts/answer/61416?hl=es
                                            </Link>}
                                        />
                                    </ListItemLegal>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Safari:
                                            </Typography>}
                                            secondary={<Link href={'http://safari.helpmax.net/es/privacidad-y-seguridad/como-gestionar-las-cookies/ '}>
                                                http://safari.helpmax.net/es/privacidad-y-seguridad/como-gestionar-las-cookies/
                                            </Link>}
                                        />
                                    </ListItemLegal>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Opera:
                                            </Typography>}
                                            secondary={<Link href={' http://help.opera.com/Linux/10.60/es-ES/cookies.html '}>
                                                http://help.opera.com/Linux/10.60/es-ES/cookies.html
                                            </Link>}
                                        />
                                    </ListItemLegal>
                                </List>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Además, también puede gestionar el almacén de cookies en su navegador a través de herramientas como las siguientes:
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <List>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Your online choices:
                                            </Typography>}
                                            secondary={<Link href={'www.youronlinechoices.com/es/ '}>
                                                www.youronlinechoices.com/es/
                                            </Link>}
                                        />
                                    </ListItemLegal>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Ghostery:
                                            </Typography>}
                                            secondary={<Link href={'www.ghostery.com/ '}>
                                                www.ghostery.com/
                                            </Link>}
                                        />
                                    </ListItemLegal>
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*
                    FIFTH POINT
                    */}
                    <Grid
                        item
                        container
                        direction={"column"}
                        spacing={1}
                    >
                        <Grid
                            item
                        >
                            <Typography variant={"h3"} style={{color:colors['--inveert-main-100']}}>
                                Aceptación de la Política de cookies
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            container
                            direction={"column"}
                            spacing={2}
                        >
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    https://inveert.com asume que usted acepta el uso de cookies. No obstante, muestra información sobre su Política de cookies en la parte inferior o superior de cualquier página del portal con cada inicio de sesión con el objeto de que usted sea consciente.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Ante esta información es posible llevar a cabo las siguientes acciones:
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <List>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Aceptar cookies. No se volverá a visualizar este aviso al acceder a cualquier página del portal durante la presente sesión.
                                            </Typography>}
                                        />
                                    </ListItemLegal>
                                    <ListItemLegal>
                                        <ListItemAvatarLegal>
                                            <AvatarLegal>
                                                <FiberManualRecordIcon fontSize={"small"}/>
                                            </AvatarLegal>
                                        </ListItemAvatarLegal>
                                        <ListItemText
                                            primary={<Typography variant={"body2"}>
                                                Cerrar. Se oculta el aviso en la presente página.
                                            </Typography>}
                                        />
                                    </ListItemLegal>
                                </List>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    Modificar su configuración. Podrá obtener más información sobre qué son las cookies, conocer la Política de cookies de https://inveert,com y modificar la configuración de su navegador.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}
