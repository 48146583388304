import {configureStore,getDefaultMiddleware, createAction} from "@reduxjs/toolkit";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER
} from "redux-persist";
import userReducer from "./user/userSlice"
import apiCallsReducer from "./apiCalls/apiCallsSlice"
import objectivesReducer from "./objectives/objectivesSlice"
import steppersReducer from "./steppers/steppersSlice"
import firstObjectivesReducer from "./firstObjective/firstObjectiveSlice"
import riskProfileReducer from "./riskProfile/riskProfileSlice"
import planReducer from "./plan/planSlice"
import recommendationReducer from "./recommendation/recommendationSlice"
import configSlice from "./config/configSlice";
import storage from 'redux-persist/lib/storage'
import {combineReducers} from "redux";

const persistConfig={
    key:'root',
    version: 1,
    storage
}
/*
    We have to combine all the reducers and lately add persistence to maintain the coherence inside the state
    and dont rehydrate all the state inside every slice we create
 */
const reducers = combineReducers({
    user:userReducer,
    api:apiCallsReducer,
    objectives:objectivesReducer,
    steppers:steppersReducer,
    first_objective:firstObjectivesReducer,
    risk_profile:riskProfileReducer,
    plan:planReducer,
    recommendation:recommendationReducer,
    config:configSlice
})

const resetAction = createAction('reset')

const resettableReducer = (state, action) => {
    if (resetAction.match(action)) {
        return reducers(undefined, action)
    }
    return reducers(state, action)
}

const persistedReducer=persistReducer(persistConfig,resettableReducer)

export const store=configureStore({
    reducer:persistedReducer,
    /*
        redux-persist inject non serializable actions so we have to unchek the tests that react-tooltip do in
        this actions or a warning will show everytime
     */
    middleware:getDefaultMiddleware({
        serializableCheck:{
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
    })
})

export const persistor=persistStore(store)