export function parseSaml (plaintext) {
// The Javascript escape and unescape functions do not correspond
// with what browsers actually do...
    let SAFECHARS = "0123456789" + // Numeric
        "ABCDEFGHIJKLMNOPQRSTUVWXYZ" + // Alphabetic
        "abcdefghijklmnopqrstuvwxyz" +
        "-_.!~*'()"; // RFC2396 Mark characters
    let HEX = "0123456789ABCDEF";
    let encoded = "";
    for (let i = 0; i < plaintext.length; i++) {
        let ch = plaintext.charAt(i);
        if (ch === " ") {
            encoded += "+"; // x-www-urlencoded, rather than %20
        } else if (SAFECHARS.indexOf(ch) !== -1) {
            encoded += ch;
        } else {
            let charCode = ch.charCodeAt(0);
            if (charCode > 255) {
                encoded += "+";
            } else {
                encoded += "%";
                encoded += HEX.charAt((charCode >> 4) & 0xF);
                encoded += HEX.charAt(charCode & 0xF);
            }
        }
    } // for
    return encoded
}