import axios from 'axios'

export async function postFinanbestKyc(data){
    try{
        const response=await axios.post('finanbest/user/add-kyc', data)
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}

export async function postFinanbestDni(data){
    try{
        const response=await axios.post('user/upload-personal-document', data)
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}