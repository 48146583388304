import React from "react";
import NumberFormat from "react-number-format";
import {useSelector} from "react-redux";
import {selectConfig} from "../../redux/config/configSlice";
function NumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;
    const config=useSelector(selectConfig)
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={config.thousand_separator}
            decimalSeparator={false}
            isNumericString
        />
    );
}

export function PhoneNumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={" "}
            decimalSeparator={false}
            isNumericString
            format="### ### ###"
        />
    );
}

export function IbanNumberFormatCustom(props) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            decimalSeparator={false}
            format="#### #### #### ####"
            isNumericString
        />
    );
}


export default NumberFormatCustom
