import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../styles/colors/colors";

export const contractPlanStyles=makeStyles(theme=>({
    header_container:{
        padding:'40px 124px 0',
        background:colors['--white'],
        [theme.breakpoints.down("sm")]:{
            padding:'24px 20px 38px'
        },
    },
    body_container:{
        padding:'40px 124px',
        maxWidth:1350,
        [theme.breakpoints.down("sm")]:{
            padding:'24px 20px 38px'
        },
    },
    boton:{
        [theme.breakpoints.down("sm")]:{
            marginBottom:50
        },
    },
    header_menu_item:{
        borderRight:`1px dashed ${colors['--gray-dark']}`,
        [theme.breakpoints.down("sm")]:{
            borderRight:'inherit'
        },
    }
}))