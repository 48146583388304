import React, {useEffect, useState} from "react";
import RegistrationDialog from "../../components/registration/RegistrationDialog";
import {useHistory, useParams} from "react-router-dom";
import {isValidRestorePassword} from "../../services/oauth/oauthUser";
import {Backdrop} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import SnackbarUtils from "../../utils/SnackbarUtils";

async function isValidUuid(uuid,setLoading,history){
    setLoading(true)
    isValidRestorePassword(uuid).then(response=> {
        if(response.data.message!=='Ok'){
            SnackbarUtils.error('Token inválido')
            history.push('/login')
        }else{

        }
        setLoading(false)
    }).catch(()=>{
        setLoading(false)
        history.push('/login')
    })
}

export default function NewPassword(){
    const history=useHistory()
    const [loading,setLoading]=useState(false)
    const{uuid}=useParams()
    useEffect(() => {
        document.title = "Restaurar password"
        window.dataLayer.push({'title': document.title});
        isValidUuid(uuid,setLoading,history)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return(
        <>
            <Backdrop style={{zIndex:"1501"}} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <RegistrationDialog openRegistrationDialog={true} uuid={uuid}/>
        </>
    )
}