import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {postSimulateRetirement} from "../../services/simulateRetirement/simulateRetirement";
import {
    onboardingGoalsFormHelpersParseFormData,
    onboardingGoalsFormHelpersParseRetirementFormData
} from "../../components/onboardingGoals/form/onboardingGoalsFormHelpers";
import {deleteObjectives, getObjectives, postObjectives, putObjectives} from "../../services/objectives/objectives";
import {deleteObjective, modifyObjective, postObjective} from "../objectives/objectivesSlice";
import {postBusinessIncome, putBusinessIncome} from "../../services/businessIncome/businessIncome";
import {selectNewRiskProfile} from "../riskProfile/riskProfileSlice";
import {postFinancialWealth} from "../../services/financialWealth/financialWealth";
import {getInveertConfig} from "../../services/configVariables/configVariables";
import {addYears, format, parse} from "date-fns";

export const sendRetirementAgeForm = createAsyncThunk(
    'first_objective/sendRetirementAgeForm',
    /*
        The parameters of the funcion are (data, thunkAPI)
        async (userData,thunkAPI)
     */
    async (firstObjectiveAndUserData,thunkAPI)=>{
        const responseSimulatedRetirement=postSimulateRetirement(onboardingGoalsFormHelpersParseRetirementFormData(firstObjectiveAndUserData))
        return Promise.all([responseSimulatedRetirement]).then(responsesData=>{
            /*
                we return a number of objects equal to the number of petitions with the name of the url we are calling
                because we name in the initial state our parameters as the names on the endpoints
             */
            return responsesData.reduce((obj,responseData)=>{
                obj[responseData.url.replace(/-/g,"_")]=responseData.data
                return obj
            },{})
        })
    }
)
export const deletePreviousObjectives=createAsyncThunk(
    'first_objective/deletePreviousObjectives',
    async (data, thunkAPI)=>{

        const responseConfig=getInveertConfig()
        const responseObjectives=getObjectives().then(response=>{
            return response?.data.map(response=>{return deleteObjectives(response.id)})
        })
        if(data.clearLocalStorage===true){
            localStorage.clear()
        }
        return Promise.all([responseObjectives,responseConfig]).then((responsesData) => {
            return responsesData.reduce((obj,responseData)=>{
                if(responseData.url==='config'){
                    obj=responseData.data
                }
                return obj
            },{})
        });

    }
)
export const sendFirstObjectiveForm = createAsyncThunk(
    'first_objective/sendFirstObjectiveForm',
    /*
        The parameters of the funcion are (data, thunkAPI)
        async (userData,thunkAPI)
     */
    async (firstObjectiveAndUserData,thunkAPI)=>{

        let initial_investment
        let private_pension_plan
        let responseFirstObjective
        const config=thunkAPI.getState().config
        const objectives=thunkAPI.getState().objectives
        const safetyNetActiveId=objectives.data.reduce((acc,objective)=>{
            if (objective.type==='SAFETY_NET'&&objective.is_active===false){
                acc=objective.id
            }
            return acc
        },false)
        if(firstObjectiveAndUserData.firstObjective.type==='SAFETY_NET'){
            const requestData={
                type:firstObjectiveAndUserData?.firstObjective.type,
                output_type:'UNIQUE',
                protection_type:'CAPITAL',
                term:firstObjectiveAndUserData?.firstObjective['contributing_years'],
                term_type:'YEAR',
                is_active: true,
                name:process.env.REACT_APP_ENVIRONMENT==='planifica'?'Fondo de emergencia':"Colchón de seguridad",
                amount:firstObjectiveAndUserData?.firstObjective.cost,
                amount_type:'UNIQUE',
                date_init:format(addYears(new Date(),firstObjectiveAndUserData?.firstObjective['contributing_years']),"yyyy-MM-dd"),
                date_end: format(addYears(parse(firstObjectiveAndUserData?.user.family_situation.birthday,'dd/MM/yyyy',new Date()),firstObjectiveAndUserData?.user.family_situation.gender==="H"?config?.death_age_man:config?.death_age_woman),'yyyy-MM-dd')
            }
            if(safetyNetActiveId!==false){
                return putObjectives(requestData,safetyNetActiveId).then(()=>{
                    return getObjectives().then(response=>response.data)
                })
            }else{
                responseFirstObjective=postObjectives(requestData)
                return Promise.all([responseFirstObjective]).then(responsesData=>{
                    /*
                        we return a number of objects equal to the number of petitions with the name of the url we are calling
                        because we name in the initial state our parameters as the names on the endpoints
                     */
                    getObjectives().then(response=> {
                        /*
                        setAmplitudeUserProperties({
                            goals:response.data.filter(objective=>objective.is_active).map(objective=>objective.type)
                        })
                        */
                        window.dataLayer.push({
                            'event':'setGoals',
                            'goals': response?.data.filter(objective=>objective.is_active).map(objective=>objective.type),
                            'initial_investment':initial_investment,
                            'private_pension_plan':private_pension_plan
                        });
                    })
                    return responsesData.reduce((obj,responseData)=>{
                        obj[responseData.url.replace(/-/g,"_")]=responseData.data
                        return obj
                    },{})
                })
            }

        }else{
            const pensionExists=thunkAPI.getState().objectives.data?.reduce((pensionExists,objective)=>{
                if(objective.type==="RETIREMENT" && process.env.REACT_APP_ENVIRONMENT!=='planifica'){
                    pensionExists=true
                }
                return pensionExists
            },false)
            if(pensionExists===true){
                initial_investment=firstObjectiveAndUserData?.firstObjective.contribution-firstObjectiveAndUserData?.firstObjective.contribution_pension_plan
                private_pension_plan=firstObjectiveAndUserData?.firstObjective.contribution_pension_plan
            }else{
                initial_investment=firstObjectiveAndUserData?.firstObjective.contribution
                private_pension_plan=0
            }
            const responsePostFinancialWealth=postFinancialWealth({initial_investment:initial_investment,private_pension_plan:private_pension_plan})
            responseFirstObjective=postObjectives(onboardingGoalsFormHelpersParseFormData({...firstObjectiveAndUserData,config:config}))
            return Promise.all([responseFirstObjective,responsePostFinancialWealth]).then(responsesData=>{
                /*
                    we return a number of objects equal to the number of petitions with the name of the url we are calling
                    because we name in the initial state our parameters as the names on the endpoints
                 */
                getObjectives().then(response=> {
                    /*
                    setAmplitudeUserProperties({
                        goals:response.data.filter(objective=>objective.is_active).map(objective=>objective.type)
                    })
                    */
                    window.dataLayer.push({
                        'event':'setGoals',
                        'goals': response?.data.filter(objective=>objective.is_active).map(objective=>objective.type),
                        'initial_investment':initial_investment,
                        'private_pension_plan':private_pension_plan
                    });
                })
                return responsesData.reduce((obj,responseData)=>{
                    obj[responseData.url.replace(/-/g,"_")]=responseData.data
                    return obj
                },{})
            })
        }
    }
)

export const sendRetirementForm = createAsyncThunk(
    'first_objective/sendRetirementForm',
    /*
        The parameters of the funcion are (data, thunkAPI)
        async (userData,thunkAPI)
     */
    async (firstObjectiveAndUserData,thunkAPI)=>{
        const {firstObjective,user}=firstObjectiveAndUserData
        let responseBusinessIncome
        const config=thunkAPI.getState().config
        const initial_investment=firstObjective.contribution_pension_plan>0?firstObjective.contribution-firstObjective.contribution_pension_plan:firstObjective.contribution
        const private_pension_plan=firstObjective.contribution_pension_plan>0?firstObjective.contribution_pension_plan:0
        const responseFirstObjective=postObjectives(onboardingGoalsFormHelpersParseFormData({...firstObjectiveAndUserData,config:config}))
        //bonus is retirement simulated income
        if(user.income?.business_income[0]?.id===undefined){
            responseBusinessIncome=postBusinessIncome({bonus:firstObjective.retirement_simulated_income,type:firstObjective.employment_status==="salaried"?"Cuenta Ajena(empresa privada)":"Cuenta Propia(autonomo)",years_worked:firstObjective.contributing_years,amount:firstObjective.gross_annual_salary})
        }else{
            responseBusinessIncome=putBusinessIncome({bonus:firstObjective.retirement_simulated_income,type:firstObjective.employment_status==="salaried"?"Cuenta Ajena(empresa privada)":"Cuenta Propia(autonomo)",years_worked:firstObjective.contributing_years,amount:firstObjective.gross_annual_salary},user.income?.business_income[0].id)
        }
        const responsePostFinancialWealth=postFinancialWealth({initial_investment:initial_investment,private_pension_plan:private_pension_plan})
        return Promise.all([responseBusinessIncome,responseFirstObjective,responsePostFinancialWealth]).then(responsesData=>{
            /*
                we return a number of objects equal to the number of petitions with the name of the url we are calling
                because we name in the initial state our parameters as the names on the endpoints
             */
            getObjectives().then(response=> {
                /*
                setAmplitudeUserProperties({
                    goals:response.data.filter(objective=>objective.is_active).map(objective=>objective.type)
                })
                 */
                window.dataLayer.push({
                    'event':'setGoals',
                    'goals': response.data.filter(objective=>objective.is_active).map(objective=>objective.type),
                    'initial_investment':initial_investment,
                    'private_pension_plan':private_pension_plan
                });
            })
            return responsesData.reduce((obj,responseData)=>{
                if(responseData.url.replace(/-/g,"_").match("business_income")){
                    obj.business_income=responseData.data
                }else{
                    obj[responseData.url.replace(/-/g,"_")]=responseData.data
                }
                return obj
            },{})
        })
    }
)

const initialState= {
        type:null,
        beneficiary:null,
        "beneficiary_name":null,
        "date_start":null,
        "date_end":null,
        cost:null,
        contribution:null,
        "cost_final":null,
        "travel_location":null,
        "goal_name":null,
        "receive_money":null,
        "contributing_years":null,
        "gross_annual_salary":null,
        "monthly_self_employed_fee":null,
        "employment_status":null,
        "retirement_age":null,
        "retirement_expenses":null,
        loading:false,
        retirement_simulated_income:null,
        retirement_complement:null,
        contribution_pension_plan:null,
        data:null,
        questionIndex:0,
        route:"/crear-meta",
}

const firstObjectiveSlice = createSlice({
    name:'first_objective',
    initialState: initialState,
    reducers:{
        setRoute(state,action){
          state.route=action.payload
        },
        addFirstObjectiveType(state,action){
            if(state.type===action.payload){
                state.type=action.payload
                state.questionIndex=0
            }else{
                return {...initialState,type:action.payload,questionIndex:0}
            }
        },
        addFirstObjectiveTypeOnPlan(state,action){

                return {
                    ...initialState,
                    type:action.payload,
                    questionIndex:0,
                    contribution:state.contribution,
                    data:state.data,
                    contribution_pension_plan:state.contribution_pension_plan,
                    retirement_simulated_income:state.retirement_simulated_income
                }
        },
        addFirstObjectiveBeneficiary(state,action){
            state.beneficiary_name=state.beneficiary===action.payload?state.beneficiary_name:null
            state.beneficiary=action.payload
            state.questionIndex+=1
        },
        addFirstObjectiveBeneficiaryName(state,action){
            state["beneficiary_name"]=action.payload
            state.questionIndex+=1
        },
        addFirstObjectiveDateStart(state,action){
            state["date_start"]=action.payload
            state.questionIndex+=1
        },
        addFirstObjectiveDateEnd(state,action){
            state["date_end"]=action.payload
            state.questionIndex+=1
        },
        addFirstObjectiveCost(state,action){
            state.cost=parseInt(action.payload)
            state.questionIndex+=1
        },
        addFirstObjectiveContribution(state,action){
            state.contribution=parseInt(action.payload)
            //if(state.type==="RETIREMENT"){
                state.questionIndex+=1
            //}
        },
        addFirstObjectiveCostFinal(state,action){
            state['cost_final']=parseInt(action.payload)
            state.questionIndex+=1
        },
        addFirstObjectiveTravelLocation(state,action){
            state['travel_location']=action.payload
            state.questionIndex+=1
        },
        addFirstObjectiveGoalName(state,action){
            state['goal_name']=action.payload
            state.questionIndex+=1
        },
        addFirstObjectiveReceiveMoney(state,action){
            state['receive_money']=action.payload
            state.questionIndex+=1
        },
        addFirstObjectiveContributingYears(state,action){
            state['contributing_years']=action.payload
            state.questionIndex+=1
        },
        addFirstObjectiveGrossAnnualSalary(state,action){
            state['gross_annual_salary']=action.payload
            if(state.employment_status==="salaried"){
                state.questionIndex+=2
            }else{
                state.questionIndex+=1
            }
        },
        addFirstObjectiveMonthlySelfEmployedFee(state,action){
            state['monthly_self_employed_fee']=action.payload
            state.questionIndex+=1
        },
        addFirstObjectiveEmploymentStatus(state,action){
            state['employment_status']=action.payload
            state.questionIndex+=1

        },
        addFirstObjectiveRetirementAge(state,action){
            state['retirement_age']=action.payload
            state.questionIndex+=1
        },
        addFirstObjectiveRetirementExpenses(state,action){
            state["retirement_expenses"]=action.payload
            state.questionIndex+=1
        },
        addFirstObjectiveRetirementComplement(state,action){
            state.retirement_complement=action.payload
            state.questionIndex+=1
        },
        addFirstObjectiveContributionPensionPlan(state,action){
            state.contribution_pension_plan=action.payload
        },
        nextQuestion(state,action){
            state.questionIndex+=1
        },
        prevQuestion(state,action) {
            if(state.questionIndex===4 && state.type==="RETIREMENT" && state.employment_status==="salaried"){
                state.questionIndex-=2
            }else{
                state.questionIndex -= 1
            }
        },
        resetQuestion(state){
            state.questionIndex=0
        }
    },
    extraReducers:{
        [sendRetirementAgeForm.pending]:(state)=>{
            state.loading=true
        },
        [sendRetirementAgeForm.fulfilled]:(state,action)=>{
            state.retirement_simulated_income=Math.round(action.payload.simulate_retirement)
            state.retirement_complement=null
            state.loading=false
        },
        [sendRetirementAgeForm.rejected]:(state)=>{
            state.loading=false
        },
        [sendFirstObjectiveForm.pending]:(state)=>{
            state.loading=true
        },
        [sendFirstObjectiveForm.fulfilled]:(state,action)=>{
            if(action.payload?.objectives?.type==='SAFETY_NET' || Array.isArray(action.payload)){

            }else{
                const {initial_investment,private_pension_plan}=action.payload.financial_wealth
                /*
                setAmplitudeUserProperties({initial_investment:initial_investment,private_pension_plan:private_pension_plan})

                 */
                window.dataLayer.push({
                    'events':'setInitialInvestment',
                    'initial_investment': initial_investment,
                    'private_pension_plan':private_pension_plan
                });
            }
            state.data=action.payload.objectives
            state.loading=false
            state.route="/perfil-riesgo"
        },
        [sendFirstObjectiveForm.rejected]:(state,action)=>{
            state.loading=false
            state.questionIndex-=1
        },
        [sendRetirementForm.pending]:(state)=>{
            state.loading=true
        },
        [sendRetirementForm.fulfilled]:(state,action)=>{
            state.data=action.payload.objectives
            state.loading=false
            state.route="/perfil-riesgo"
        },
        [sendRetirementForm.rejected]:(state,action)=>{
            state.loading=false
        },
        [deletePreviousObjectives.pending]:(state)=>{
            state.loading=true
        },
        [deletePreviousObjectives.fulfilled]:(state,action)=>{
            state.loading=false
            state.data=null
        },
        [deletePreviousObjectives.rejected]:(state)=>{
            state.loading=false
        },
        [deleteObjective.fulfilled]:(state)=>{
            if(state.data===null){
                state.data={}
            }else{
                state.data=null
            }
        },
        [deletePreviousObjectives.pending]:(state)=>{
            state.loading=true
        },
        [modifyObjective.fulfilled]:(state,action)=>{
            state.loading=false
            state.data=action.payload
        },
        [postObjective.fulfilled]:(state)=>{
            if(state.data===null){
                state.data={}
            }else{
                state.data=null
            }
        },
        [selectNewRiskProfile.fulfilled]: (state,action)=>{
            if(state.data===null){
                state.data={}
            }else{
                state.data=null
            }
        },
    }
})

export const {
    addFirstObjectiveType,
    addFirstObjectiveBeneficiary,
    addFirstObjectiveDateStart,
    addFirstObjectiveDateEnd,
    addFirstObjectiveCost,
    addFirstObjectiveBeneficiaryName,
    addFirstObjectiveContribution,
    addFirstObjectiveCostFinal,
    addFirstObjectiveTravelLocation,
    addFirstObjectiveGoalName,
    addFirstObjectiveReceiveMoney,
    addFirstObjectiveContributingYears,
    addFirstObjectiveGrossAnnualSalary,
    addFirstObjectiveMonthlySelfEmployedFee,
    addFirstObjectiveEmploymentStatus,
    addFirstObjectiveRetirementAge,
    addFirstObjectiveRetirementExpenses,
    addFirstObjectiveRetirementComplement,
    addFirstObjectiveContributionPensionPlan,
    nextQuestion,
    prevQuestion,
    setRoute,
    addFirstObjectiveTypeOnPlan,
    resetQuestion
} = firstObjectiveSlice.actions
export const selectFirstObjective = (state) => state["first_objective"]
export default firstObjectiveSlice.reducer