import React, {useEffect, useState} from "react";
import {
    Grid,
    Typography,
    LinearProgress,
    withStyles,
    MenuItem, FormLabel, Button, FormHelperText, useMediaQuery, useTheme
} from "@material-ui/core";
import {colors} from "../../styles/colors/colors";
import {Field, Form, Formik} from "formik";
import {cecabankJobs} from '../../utils/contractConstants'
import * as Yup from "yup";
import {Checkbox, Select, TextField} from "formik-material-ui";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import SidebarPlan from "../../components/sidebar/SidebarPlan";
import Box from "@material-ui/core/Box";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../../redux/user/userSlice";
import {planStyles} from "../plan/planStyles";
import {
    postKyc,
    selectRecommendation,
    setContractRoute
} from "../../redux/recommendation/recommendationSlice";
import {useHistory} from "react-router-dom";
import {contractPlanStyles} from "../contractPlan/contractPlanStyles";
import InputRadioGroupForm from "../../components/input/InputRadioGroupForm";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import {Responsive} from "../../utils/Responsive";

const radioGroup1={
    title:"¿Obligaciones fiscales en otros paises?",
    name:"fiscalObligations",
    showRequiredSign:false,
    rowDirection:true,
    items:[
        {
            value:'0',
            label:"No",
            imageSrc:null
        },
        {
            value:'1',
            label:"Si",
            imageSrc:null
        }
    ]
}

const radioGroup2={
    title:"¿Tienes o has tenido un cargo público?",
    name:"publicFunctions",
    showRequiredSign:false,
    rowDirection:true,
    items:[
        {
            value:'0',
            label:"No",
            imageSrc:null
        },
        {
            value:'1',
            label:"Si",
            imageSrc:null
        }
    ]
}

const radioGroup3={
    title:"¿Algún familiar o allegado tiene o ha tenido un cargo público?",
    name:"publicFunctionsFamily",
    showRequiredSign:false,
    rowDirection:true,
    items:[
        {
            value:'0',
            label:"No",
            imageSrc:null
        },
        {
            value:'1',
            label:"Si",
            imageSrc:null
        }
    ]
}

const radioGroup4={
    title:"¿Tiene otras fuentes de ingresos (subsidios, rentas, etc)?",
    name:"otherSourcesIncome",
    showRequiredSign:false,
    rowDirection:true,
    items:[
        {
            value:'0',
            label:"No",
            imageSrc:null
        },
        {
            value:'1',
            label:"Si",
            imageSrc:null
        }
    ]
}



const ContractLinearProgress=withStyles(theme=>({
    root:{
        width:'100%'
    },
    colorPrimary:{
        backgroundColor:colors['--gray-soft']
    }
}))(LinearProgress)

const ContractTextField=withStyles(theme=>({
    root:{
        width:280
    }
}))(TextField)


export default function (){
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
    });
    const history=useHistory()
    const dispatch=useDispatch()
    const progress=40
    const [dniFrontFile,setDniFrontFile]=useState(null)
    const [dniBackFile,setDniBackFile]=useState(null)
    const [dniFrontFileError,setDniFrontFileError]=useState(null)
    const [dniBackFileError,setDniBackFileError]=useState(null)
    const validationSchema=Yup.object({
        linkCecabank: Yup.string()
            .nullable()
            .required("Dinos el tipo de documento")
            .oneOf(cecabankJobs,"Dinos el tipo de documento"),
        company: Yup.string()
            .nullable()
            .required("Dinos donde trabajas"),
        fiscalObligations: Yup.string()
            .nullable()
            .oneOf(['0','1'], 'Debes marcarlo'),
        publicFunctions: Yup.string()
            .nullable()
            .oneOf(['0','1'], 'Debes marcarlo'),
        publicFunctionsFamily: Yup.string()
            .nullable()
            .oneOf(['0','1'], 'Debes marcarlo'),
        otherSourcesIncome: Yup.string()
            .nullable()
            .oneOf(['0','1'], 'Debes marcarlo'),
        intervenerCC: Yup.string()
            .nullable()
            .required('¿Eres titular o cotitular?'),
        intervenerCV: Yup.string()
            .nullable()
            .required('¿Eres titular o cotitular?'),


    })
    const user=useSelector(selectUser)
    const classes=planStyles()
    const recommendation=useSelector(selectRecommendation)
    const contractPlanClasses=contractPlanStyles()
    useEffect(() => {
        dispatch(setContractRoute('/contratacion/kyc'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if(history.location.pathname!==recommendation.contract.route){
            history.push("/contratacion/contratos")
        }else{
            dispatch(setContractRoute('/contratacion/kyc'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recommendation.contract.route]);
    return(
        <Grid
            container
            style={{background:colors['--background']}}
        >
            <Backdrop  open={recommendation.loading} style={{zIndex:"100"}}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box style={{flexGrow:"1", display: "flex", overflow: "hidden"}}>
                <Box className={classes.menu_bar_box}>
                    <SidebarPlan user={user} setBeginTour={null}/>
                </Box>
                <Box className={classes.content_container_box}>
                    {/*
                HEADER
            */}
                    <Grid
                        item
                        container
                        direction={"column"}
                        className={contractPlanClasses.header_container}
                    >
                        <Grid
                            item
                            container
                            direction={"column"}
                            spacing={1}
                        >
                            <Grid
                                item
                            >
                                <Typography variant={'h3'}>
                                    Contratación de tu plan
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={'body2'}>
                                    Rellena los siguientes datos para proceder a la contratación de tu plan
                                </Typography>
                            </Grid>
                        </Grid>
                        <Responsive displayIn={'Laptop'}>
                            <Grid
                                item
                                container
                                style={{marginTop:20,maxWidth:1040}}
                            >
                                <Grid
                                    item
                                    container
                                >
                                    <Grid
                                        item
                                        sm={4}
                                        container
                                        className={contractPlanClasses.header_menu_item}
                                    >
                                        <Typography variant={"caption"} >
                                            REGISTRO
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        justify={"center"}
                                        sm={4}
                                        className={contractPlanClasses.header_menu_item}
                                    >
                                        <Typography variant={"caption"} style={{color:progress<(100/3)?colors['--gray-dark']:'inherit'}}>
                                            CUMPLIMIENTO NORMATIVO
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        sm={4}
                                        container
                                        justify={"center"}
                                    >
                                        <Typography variant={"caption"} style={{color:progress<(200/3)?colors['--gray-dark']:'inherit'}}>
                                            CONTRATOS
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Responsive>
                    </Grid>
                    <Grid
                        item
                        container
                    >
                        <ContractLinearProgress variant="determinate" value={progress}/>
                    </Grid>
                    {/*
                BODY
            */}
                    <Grid
                        item
                        container
                        className={contractPlanClasses.body_container}
                        direction={"column"}
                    >
                        <Formik
                            initialValues={{
                                haveLinkCecabank:false,
                                linkCecabank: 'Personas no vinculadas con la entidad',
                                fiscalObligations: null,
                                publicFunctions:null,
                                publicFunctionsFamily:null,
                                otherSourcesIncome:null,
                                company:null,
                                intervenerCC:'Titular',
                                intervenerCV:'Titular'
                            }
                            }
                            onSubmit={(values, { setSubmitting }) => {
                                const processedValues={
                                    ...values,
                                    fiscalObligations:parseInt(values.fiscalObligations),
                                    publicFunctions:parseInt(values.publicFunctions),
                                    publicFunctionsFamily:parseInt(values.publicFunctionsFamily),
                                    otherSourcesIncome:parseInt(values.otherSourcesIncome),

                                }
                                const formData = new FormData()
                                formData.append('front_document',dniFrontFile)
                                formData.append('back_document',dniBackFile)
                                dispatch(postKyc({formData:formData,kyc:processedValues}))
                                setSubmitting(false)

                            }}
                            enableReinitialize
                            validationSchema={validationSchema}
                        >
                            {/*
                submitForm has useful functions like errors or isSubmitting that can be used to block fields when error
                or submission event happen. We are using redux so we dont need them
            */}
                            {({ submitForm,setFieldValue,touched,validateField,validateForm,values,isSubmitting ,isValidating,errors,submitCount, setValues})=>(
                                <Form >
                                    <Grid
                                        container
                                        direction={"column"}
                                        spacing={4}
                                        alignItems={"center"}
                                        justify={"center"}
                                    >
                                        <Grid
                                            item
                                            container
                                            direction={"column"}
                                            spacing={2}
                                        >
                                            <Grid
                                                item
                                            >
                                                <FormLabel component={"legend"}>
                                                    <Typography variant={"caption"} style={{color:colors['--gray-dark']}}>
                                                        DATOS PROFESIONALES
                                                    </Typography>
                                                </FormLabel>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                spacing={2}
                                                justify={isMobile?'center':'space-between'}
                                            >
                                                <Grid
                                                    item
                                                    container
                                                    alignItems={"center"}
                                                    justify={"space-between"}
                                                    style={{width:280}}
                                                >
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        sm={2}
                                                    >
                                                        <Field component={Checkbox} type="checkbox" name="haveLinkCecabank" onChange={()=>setValues({...values,haveLinkCecabank  : values.haveLinkCecabank === true ? false : true,linkCecabank:values.haveLinkCecabank === true?'Personas no vinculadas con la entidad':null})}/>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={9}
                                                        sm={10}
                                                    >
                                                        <Typography variant={"body2"} style={{cursor:'pointer'}} onClick={()=>setValues({...values,haveLinkCecabank  : values.haveLinkCecabank === true ? false : true,linkCecabank:values.haveLinkCecabank === true?'Personas no vinculadas con la entidad':null})}>
                                                            Tengo vinculación con Cecabank
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                                {
                                                    values.haveLinkCecabank===true &&
                                                    <Grid
                                                        item
                                                    >
                                                        <FormControl  variant={"filled"} style={{width:"280px"}} error={touched.linkCecabank===true &&errors.linkCecabank}>
                                                            <InputLabel >Vinculación con Cecabank</InputLabel>
                                                            <Field
                                                                component={Select}
                                                                name="linkCecabank"
                                                            >
                                                                {
                                                                    cecabankJobs.map(element=><MenuItem value={element}>{element}</MenuItem>)
                                                                }
                                                            </Field>
                                                            {
                                                                touched.linkCecabank===true &&
                                                                <FormHelperText error={true}>{errors.linkCecabank}</FormHelperText>
                                                            }
                                                        </FormControl>
                                                    </Grid>
                                                }
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        component={ContractTextField}
                                                        /*
                                                            value on an input cant be null or a warning is raised, we need to initialite it
                                                            on an empty string or undefined, in this case we use undefined cause it is an
                                                            uncontroled element
                                                         */
                                                        type={"string"}
                                                        autoComplete={"off"}
                                                        variant={"filled"}
                                                        name={"company"}
                                                        label={"Empresa"}
                                                        size={"small"}
                                                        InputProps={{
                                                            disableUnderline:true,
                                                        }}
                                                    />
                                                </Grid>
                                                {
                                                    values.haveLinkCecabank===false &&
                                                    <Grid
                                                        item
                                                        style={{width:280}}
                                                    >
                                                    </Grid>
                                                }

                                            </Grid>

                                            <Grid
                                                item
                                                container
                                                alignItems={isMobile?'center':'inherit'}
                                                justify={isMobile?"center":'space-between'}
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    container
                                                    alignItems={"center"}
                                                    style={{width:280}}
                                                >
                                                    <InputRadioGroupForm submitCount={submitCount} error={errors.fiscalObligations} radioGroup={radioGroup1} />

                                                </Grid>
                                                <Grid
                                                    item
                                                    alignItems={"center"}
                                                    style={{width:280}}
                                                >
                                                    <InputRadioGroupForm submitCount={submitCount} error={errors.otherSourcesIncome} radioGroup={radioGroup4} />

                                                </Grid>
                                                <Grid
                                                    item
                                                    style={{width:280}}
                                                >

                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                spacing={2}
                                                justify={isMobile?'center':'space-between'}
                                            >
                                                <Grid
                                                    item
                                                    alignItems={"center"}
                                                    style={{width:280}}
                                                >
                                                    <InputRadioGroupForm submitCount={submitCount} error={errors.publicFunctions} radioGroup={radioGroup2} />

                                                </Grid>
                                                <Grid
                                                    item
                                                    alignItems={"center"}
                                                    style={{width:280}}
                                                >
                                                    <InputRadioGroupForm submitCount={submitCount} error={errors.publicFunctionsFamily} radioGroup={radioGroup3} />

                                                </Grid>
                                                <Grid
                                                    item
                                                    style={{width:280}}
                                                >

                                                </Grid>


                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            container
                                            direction={"column"}
                                            spacing={2}
                                        >
                                            <Grid
                                                item
                                            >
                                                <FormLabel component={"legend"}>
                                                    <Typography variant={"caption"} style={{color:colors['--gray-dark']}}>
                                                        DOCUMENTACIÓN
                                                    </Typography>
                                                </FormLabel>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                spacing={2}
                                                alignItems={isMobile?'center':'space-between'}
                                            >
                                                <Grid
                                                    item
                                                    container
                                                    alignItems={"center"}
                                                    spacing={1}
                                                    justify={isMobile?'center':'space-between'}
                                                    style={{width:280}}
                                                >
                                                    <Grid
                                                        item
                                                    >
                                                        <Typography variant={"body2"} style={{color:colors['--black-soft']}}>
                                                            Frontal del documento
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                    >
                                                        <input
                                                            accept=".pdf"
                                                            className={classes.input}
                                                            style={{ display: 'none' }}
                                                            id="dni-front"
                                                            multiple
                                                            type="file"
                                                            onChange={(event)=> {
                                                                setDniFrontFile(event.currentTarget.files[0])
                                                                setDniFrontFileError(null)
                                                            }}
                                                        />
                                                        <label htmlFor="dni-front">
                                                            <Button variant="outlined" component="span" className={classes.button}>
                                                                Subir archivo
                                                            </Button>
                                                        </label>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                    >
                                                        <FormHelperText error={dniFrontFileError?true:false}>{dniFrontFileError?dniFrontFileError:dniFrontFile?.name?dniFrontFile?.name:''}</FormHelperText>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    container
                                                    alignItems={"center"}
                                                    spacing={1}
                                                    style={{width:280}}
                                                    justify={isMobile?'center':'space-between'}
                                                >
                                                    <Grid
                                                        item
                                                    >
                                                        <Typography variant={"body2"} style={{color:colors['--black-soft']}}>
                                                            Reverso del documento
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                    >
                                                        <input
                                                            accept=".pdf"
                                                            className={classes.input}
                                                            style={{ display: 'none' }}
                                                            id="dni-back"
                                                            multiple
                                                            type="file"
                                                            onChange={(event)=> {
                                                                setDniBackFile(event.currentTarget.files[0])
                                                                setDniBackFileError(null)
                                                            }}
                                                        />
                                                        <label htmlFor="dni-back">
                                                            <Button variant="outlined" component="span" className={classes.button}>
                                                                Subir archivo
                                                            </Button>
                                                        </label>
                                                    </Grid>

                                                    <Grid
                                                        item
                                                    >{

                                                    }
                                                        <FormHelperText error={dniBackFileError?true:false}>{dniBackFileError?dniBackFileError:dniBackFile?.name?dniBackFile?.name:''}</FormHelperText>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            className={contractPlanClasses.boton}
                                            justify={isMobile?'center':'space-between'}
                                            alignItems={"center"}
                                        >
                                            <Button
                                                variant={"contained"}
                                                color={"primary"}
                                                onClick={()=> {
                                                    if(dniFrontFile===null){
                                                        setDniFrontFileError('Suba el documento del dni')
                                                    }else{
                                                        setDniFrontFileError(null)
                                                    }
                                                    if(dniBackFile===null){
                                                        setDniBackFileError('Suba el documento del dni')
                                                    }else{
                                                        setDniBackFileError(null)
                                                    }
                                                    if(dniFrontFile && dniBackFile){
                                                        validateForm().then(submitForm)
                                                    }
                                                }}
                                            >
                                                Continuar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Box>
            </Box>
        </Grid>
    )
}