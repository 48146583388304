import React, {useEffect, useState} from "react";
import {
    Grid,
    Typography,
    LinearProgress,
    withStyles,
    InputAdornment,
    IconButton,
    MenuItem, FormLabel, Button, FormHelperText, useMediaQuery, useTheme
} from "@material-ui/core";
import {colors} from "../../styles/colors/colors";
import {Field, Form, Formik} from "formik";
import {countries,streets,laboral,professions,maritalStatus,provinces,municipalities,regions} from '../../utils/contractConstants'
import * as Yup from "yup";
import {Select, TextField,Checkbox} from "formik-material-ui";
import {TextField as Text} from '@material-ui/core'
import { Autocomplete } from 'formik-material-ui-lab';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {KeyboardDatePicker} from "formik-material-ui-pickers";
import MaskedInput from 'react-text-mask';
import SidebarPlan from "../../components/sidebar/SidebarPlan";
import Box from "@material-ui/core/Box";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../../redux/user/userSlice";
import {planStyles} from "../plan/planStyles";
import {format} from "date-fns"
import {
    postUserRegistration,
    selectRecommendation,
    setContractRoute
} from "../../redux/recommendation/recommendationSlice";
import {useHistory} from "react-router-dom";
import {contractPlanStyles} from "./contractPlanStyles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import {dniNieValidator} from "../../utils/dniNieValidator";
import {ibanValidator} from "../../utils/ibanValidator"
import {phoneValidation} from "../../utils/phoneValidation";
import {Responsive} from "../../utils/Responsive";

function TextMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[
                /[Ee]/,
                /[Ss]/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/
            ]}
            placeholder={'ES55 0130 4518 9339 5002 3822'}
            guide={false}
        />
    );
}

function DniMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[
                /[0-9XYZxyz]/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /[TRWAGMYFPDXBNJZSQVHLCKEtrwagmyfpdxbnjzsqvhlcke]/
            ]}
            placeholder={'34366652-Y'}
            guide={false}
        />
    );
}
function PhoneMaskCustom(props) {
    const {inputRef, ...other} = props;

    return (
        <MaskedInput
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
                /\d/
            ]}
            placeholder={'692 283 123'}
            guide={false}
        />
    );
}

const ContractLinearProgress=withStyles(theme=>({
    root:{
        width:'100%'
    },
    colorPrimary:{
        backgroundColor:colors['--gray-soft']
    }
}))(LinearProgress)

const ContractTextField=withStyles(theme=>({
    root:{
        width:280
    }
}))(TextField)
const ContractKeyboardDatePicker=withStyles(theme=>({
    root:{
        width:280,
    }
}))(KeyboardDatePicker)

export default function (){
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
    });
    const history=useHistory()
    const dispatch=useDispatch()
    const progress=3
    const [showPassword,setShowPassword]=useState(false)
    const processedCountries=countries.map(country=> {
        return {...country, alpha2: country.alpha2.toUpperCase()}
    })
    const countryCodes=countries.map(countrie=>countrie.alpha2.toUpperCase())
    const streetCodes=streets.map(street=>street.code)
    const laboralIds=laboral.map(laboral=>laboral.id)
    const maritalIds=maritalStatus.map(status=>status.id)
    const professionIds=professions.map(profession=>profession.id)
    const cityNames=municipalities.map(municipalitie=>municipalitie.name)
    const provinceNames=provinces.map(province=>province.name)
    const validationSchema=Yup.object({
        email: Yup.string()
            .nullable()
            .email("No es un email válido")
            .required("Email requerido"),
        password: Yup.string()
            .nullable()
            .min(4,"La contraseña es demasiado corta, debe ser cuatro letras mínimo")
            .required("Necesitas una contraseña")
            .matches(/^.*(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$/u, 'La contraseña debe contener como minimo 1 número, 1 letra minúscula y 1 letra mayúscula'),
        passwordConfirmation: Yup.string()
            .required("Confirma la contraseña")
            .nullable()
            .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
        name: Yup.string()
            .nullable()
            .required("Nombre requerido"),
        lastName1: Yup.string()
            .nullable()
            .required("Primer apellido requerido"),
        lastName2: Yup.string()
            .nullable()
            .required("Segundo apellido requerido"),
        documentTypeId: Yup.number()
            .nullable()
            .required("Dinos el tipo de documento")
            .oneOf([0,1],"Dinos el tipo de documento"),
        document: Yup.string()
            .nullable()
            .required("Dinos tu numero de documento")
            .test('documentValid', 'Número de documento inválido',val=>dniNieValidator(val)),
        documentExpirationDate: Yup.date()
            .nullable()
            .required("Fecha de expiración del documento requerida"),
        gender: Yup.string()
            .nullable()
            .required("Dinos el tipo de documento")
            .oneOf(['MALE','FEMALE']),
        birthdate: Yup.date()
            .nullable()
            .required("Fecha de nacimiento requerida"),
        nationality: Yup.string()
            .nullable()
            .required("Dinos tu nacionalidad")
            .oneOf(countryCodes,"Dinos tu nacionalidad"),
        birthCountry: Yup.string()
            .nullable()
            .required("Dinos en que país naciste")
            .oneOf(countryCodes,"Dinos en que país naciste"),
        birthRegion: Yup.string()
            .nullable()
            .required("En que region naciste"),
        birthCity: Yup.string()
            .nullable()
            .required("En que ciudad naciste"),
        country: Yup.string()
            .nullable()
            .required("Dinos en que país resides")
            .oneOf(countryCodes,"Dinos en que país resides"),
        mobile: Yup.string()
            .nullable()
            .required('Dinos tu numero de teléfono')
            .test('len', 'Teléfono no válido', val => phoneValidation(val)),
        postalCode: Yup.string()
            .nullable()
            .required('Dinos tu código postal'),
        region: Yup.string()
            .nullable()
            .required('En que región vives')
            .oneOf(regions,'En que region vives'),
        province: Yup.string()
            .nullable()
            .required('En que provincia vives')
            .oneOf(provinceNames,'En que provincia vives'),
        city: Yup.string()
            .nullable()
            .required('En que ciudad vives')
            .oneOf(cityNames,'En que ciudad vives'),
        addressType: Yup.string()
            .nullable()
            .required("Dinos el tipo de vía")
            .oneOf(streetCodes,"Dinos el tipo de vía"),
        address: Yup.string()
            .nullable()
            .required('Dinos tu direccion'),
        addressNumber: Yup.string()
            .nullable()
            .required('dinos el numero'),
        addressFloor: Yup.string()
            .nullable(),
        residenceCountry: Yup.string()
            .nullable()
            .required("Dinos en que país resides")
            .oneOf(countryCodes),
        maritalStatus: Yup.string()
            .nullable()
            .required("Dinos tu estado civil")
            .oneOf(maritalIds,"Dinos tu estado civil"),
        IBAN: Yup.string()
            .nullable()
            .required('Necesitamos saber el IBAN de tu cuenta')
            .test('validateIban', 'Número de cuenta inválido',val=>ibanValidator(val)),
        laboral: Yup.string()
            .nullable()
            .required('Necesitamos saber tu ocupación')
            .oneOf(laboralIds,'Necesitamos saber tu ocupación'),
        profesion: Yup.string()
            .nullable()
            .required('Necesitamos saber tu sector')
            .oneOf(professionIds,'Necesitamos saber tu sector'),
        variousNationalities: Yup.bool()
            .oneOf([true,false], 'Debes marcarlo')



    })
    const user=useSelector(selectUser)
    const classes=planStyles()
    const recommendation=useSelector(selectRecommendation)
    const contractPlanClasses=contractPlanStyles()
    useEffect(() => {
        dispatch(setContractRoute('/contratacion'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if(history.location.pathname!==recommendation.contract.route){
            history.push("/contratacion/kyc")
        }else{
            dispatch(setContractRoute('/contratacion'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recommendation.contract.route]);
    return(
        <Grid
            container
            style={{background:colors['--background']}}
        >
            <Backdrop  open={recommendation.loading} style={{zIndex:"100"}}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box style={{flexGrow:"1", display: "flex", overflow: "hidden"}}>
                <Box className={classes.menu_bar_box}>
                    <SidebarPlan user={user} setBeginTour={null}/>
                </Box>
                <Box className={classes.content_container_box}>
                    {/*
                HEADER
            */}
                    <Grid
                        item
                        container
                        direction={"column"}
                        className={contractPlanClasses.header_container}
                    >
                        <Grid
                            item
                            container
                            direction={"column"}
                            spacing={1}
                        >
                            <Grid
                                item
                            >
                                <Typography variant={'h3'}>
                                    Contratación de tu plan
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={'body2'}>
                                    Rellena los siguientes datos para proceder a la contratación de tu plan
                                </Typography>
                            </Grid>
                        </Grid>
                        <Responsive displayIn={'Laptop'}>
                            <Grid
                                item
                                container
                                style={{marginTop:20,maxWidth:1040}}
                            >
                                <Grid
                                    item
                                    container
                                >
                                    <Grid
                                        item
                                        sm={4}
                                        container
                                        className={contractPlanClasses.header_menu_item}
                                    >
                                        <Typography variant={"caption"} >
                                            REGISTRO
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        justify={"center"}
                                        sm={4}
                                        className={contractPlanClasses.header_menu_item}
                                    >
                                        <Typography variant={"caption"} style={{color:progress<(100/3)?colors['--gray-dark']:'inherit'}}>
                                            CUMPLIMIENTO NORMATIVO
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        sm={4}
                                        container
                                        justify={"center"}
                                    >
                                        <Typography variant={"caption"} style={{color:progress<(200/3)?colors['--gray-dark']:'inherit'}}>
                                            CONTRATOS
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Responsive>
                    </Grid>
                    <Grid
                        item
                        container
                    >
                        <ContractLinearProgress variant="determinate" value={progress}/>
                    </Grid>
                    {/*
                BODY
            */}
                    <Grid
                        item
                        container
                        className={contractPlanClasses.body_container}
                        direction={"column"}
                    >
                        <Formik
                            initialValues={{
                                email: null,
                                password:null,
                                passwordConfirmation:null,
                                name: null,
                                lastName1: null,
                                lastName2: null,
                                document:null,
                                documentTypeId: null,
                                documentExpirationDate: null,
                                gender: user.family_situation==='H'?'MALE':'FEMALE',
                                birthdate: user.family_situation.birthday,
                                nationality: null,
                                birthCountry: null,
                                birthRegion: null,
                                birthCity: null,
                                country: 'ES',
                                mobile: null,
                                postalCode: null,
                                region: null,
                                province:null,
                                city: null,
                                addressType: null,
                                address: null,
                                addressNumber: null,
                                addressFloor: undefined,
                                residenceCountry: 'ES',
                                maritalStatus: null,
                                IBAN: null,
                                laboral: null,
                                profesion: null,
                                variousNationalities: false
                            }
                            }
                            onSubmit={(values, { setSubmitting }) => {
                                const processedValues={
                                    ...values,
                                    documentExpirationDate:format(values.documentExpirationDate,'dd/MM/yyyy'),
                                    population:values.city,
                                    municipality:values.city,

                                }
                                dispatch(postUserRegistration(processedValues))
                                setSubmitting(false)

                            }}
                            enableReinitialize
                            validationSchema={validationSchema}
                        >
                            {/*
                submitForm has useful functions like errors or isSubmitting that can be used to block fields when error
                or submission event happen. We are using redux so we dont need them
            */}
                            {({ submitForm,setFieldValue,touched,validateField,validateForm,values,isSubmitting ,isValidating,errors,submitCount, setValues})=>(
                                <Form >
                                    <Grid
                                        container
                                        direction={"column"}
                                        spacing={4}
                                        alignItems={"center"}
                                        justify={"center"}
                                    >
                                        <Grid
                                            item
                                            container
                                            direction={"column"}
                                        >
                                            <Grid
                                                item
                                            >
                                                <FormLabel component={"legend"}>
                                                    <Typography variant={"caption"} style={{color:colors['--gray-dark']}}>
                                                        REGISTRA TU CUENTA
                                                    </Typography>
                                                </FormLabel>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                spacing={2}
                                                justify={isMobile?'center':'space-between'}
                                            >
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        component={ContractTextField}
                                                        /*
                                                            value on an input cant be null or a warning is raised, we need to initialite it
                                                            on an empty string or undefined, in this case we use undefined cause it is an
                                                            uncontroled element
                                                         */
                                                        type={"string"}
                                                        autoComplete={"off"}
                                                        variant={"filled"}
                                                        name={"email"}
                                                        label={"Email"}
                                                        size={"small"}
                                                        InputProps={{
                                                            disableUnderline:true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        component={ContractTextField}
                                                        /*
                                                            value on an input cant be null or a warning is raised, we need to initialite it
                                                            on an empty string or undefined, in this case we use undefined cause it is an
                                                            uncontroled element
                                                         */
                                                        type={showPassword===true?"string":"password"}
                                                        autoComplete={"off"}
                                                        variant={"filled"}
                                                        name={"password"}
                                                        label={"Contraseña"}
                                                        size={"small"}
                                                        InputProps={{
                                                            disableUnderline:true,
                                                            endAdornment:
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={()=>setShowPassword(showPassword===false)}
                                                                        onMouseDown={(event)=>event.preventDefault()}
                                                                        aria-label="toggle password visibility"
                                                                        edge={"end"}
                                                                    >
                                                                        {showPassword===true?<VisibilityOff />:<Visibility/>}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                        }}

                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        component={ContractTextField}
                                                        /*
                                                            value on an input cant be null or a warning is raised, we need to initialite it
                                                            on an empty string or undefined, in this case we use undefined cause it is an
                                                            uncontroled element
                                                         */
                                                        type={showPassword===true?"string":"password"}
                                                        autoComplete={"off"}
                                                        variant={"filled"}
                                                        name={"passwordConfirmation"}
                                                        label={"Confirma la contraseña"}
                                                        size={"small"}
                                                        InputProps={{
                                                            disableUnderline:true,
                                                            endAdornment:
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={()=>setShowPassword(showPassword===false)}
                                                                        onMouseDown={(event)=>event.preventDefault()}
                                                                        aria-label="toggle password visibility"
                                                                        edge={"end"}
                                                                    >
                                                                        {showPassword===true?<VisibilityOff />:<Visibility/>}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                        }}

                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            direction={"column"}
                                        >
                                            <Grid
                                                item
                                            >
                                                <FormLabel component={"legend"}>
                                                    <Typography variant={"caption"} style={{color:colors['--gray-dark']}}>
                                                        DATOS PERSONALES
                                                    </Typography>
                                                </FormLabel>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                spacing={2}
                                                justify={isMobile?'center':'space-between'}
                                            >
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        component={ContractTextField}
                                                        /*
                                                            value on an input cant be null or a warning is raised, we need to initialite it
                                                            on an empty string or undefined, in this case we use undefined cause it is an
                                                            uncontroled element
                                                         */
                                                        type={"string"}
                                                        autoComplete={"off"}
                                                        variant={"filled"}
                                                        name={"name"}
                                                        label={"Nombre"}
                                                        size={"small"}
                                                        InputProps={{
                                                            disableUnderline:true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        component={ContractTextField}
                                                        /*
                                                            value on an input cant be null or a warning is raised, we need to initialite it
                                                            on an empty string or undefined, in this case we use undefined cause it is an
                                                            uncontroled element
                                                         */
                                                        type={"string"}
                                                        autoComplete={"off"}
                                                        variant={"filled"}
                                                        name={"lastName1"}
                                                        label={"Primer apellido"}
                                                        size={"small"}
                                                        InputProps={{
                                                            disableUnderline:true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        component={ContractTextField}
                                                        /*
                                                            value on an input cant be null or a warning is raised, we need to initialite it
                                                            on an empty string or undefined, in this case we use undefined cause it is an
                                                            uncontroled element
                                                         */
                                                        type={"string"}
                                                        autoComplete={"off"}
                                                        variant={"filled"}
                                                        name={"lastName2"}
                                                        label={"Segundo apellido"}
                                                        size={"small"}
                                                        InputProps={{
                                                            disableUnderline:true,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                spacing={2}
                                                justify={isMobile?'center':'space-between'}
                                            >
                                                <Grid
                                                    item
                                                >
                                                    <FormControl  variant={"filled"} style={{minWidth:"280px"}} error={touched.documentTypeId===true &&errors.documentTypeId}>
                                                        <InputLabel >Tipo de documento</InputLabel>
                                                        <Field
                                                            component={Select}
                                                            name="documentTypeId"
                                                        >
                                                            <MenuItem value={0}>DNI</MenuItem>
                                                            <MenuItem value={1}>NIE</MenuItem>
                                                        </Field>
                                                        {
                                                            touched.documentTypeId===true &&
                                                            <FormHelperText error={true}>{errors.documentTypeId}</FormHelperText>
                                                        }
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        component={ContractTextField}
                                                        /*
                                                            value on an input cant be null or a warning is raised, we need to initialite it
                                                            on an empty string or undefined, in this case we use undefined cause it is an
                                                            uncontroled element
                                                         */
                                                        type={"string"}
                                                        autoComplete={"off"}
                                                        variant={"filled"}
                                                        name={"document"}
                                                        label={"Número de documento"}
                                                        size={"small"}
                                                        inputProps={{style: {textTransform: 'uppercase'}}}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            disableUnderline:true,
                                                            inputComponent: DniMaskCustom,

                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        component={ContractKeyboardDatePicker}
                                                        name={"documentExpirationDate"}
                                                        autoComplete={"off"}
                                                        cancelLabel={"Atrás"}
                                                        autoOk={true}
                                                        /*
                                                            disableToolbar hides the superior toolbar and dont let you choose between years
                                                            or months
                                                         */
                                                        disablePast
                                                        minDate={Date.now()}
                                                        minDateMessage={"¿El documento no puede estar caducado?"}
                                                        invalidDateMessage={"Formato de fecha no válido, debe ser dd/mm/aaaa"}
                                                        label={"Fecha de expiración"}
                                                        format="dd/MM/yyyy"
                                                        inputProps={{ inputMode: 'numeric'}}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        mask={"__/__/____"}
                                                        placeholder={"__/__/____"}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                spacing={2}
                                                justify={isMobile?'center':'space-between'}
                                            >
                                                <Grid
                                                    item
                                                >
                                                    <FormControl variant={"filled"} style={{minWidth:"280px"}} error={touched.maritalStatus===true && errors.maritalStatus}>
                                                        <InputLabel>Estado civil</InputLabel>
                                                        <Field
                                                            component={Select}
                                                            name="maritalStatus"

                                                        >
                                                            {
                                                                maritalStatus.map(status=><MenuItem value={status.id}>{status.name}</MenuItem>)
                                                            }
                                                        </Field>
                                                        {
                                                            touched.maritalStatus===true &&
                                                            <FormHelperText error={true}>{errors.maritalStatus}</FormHelperText>
                                                        }
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        component={ContractTextField}
                                                        variant={"filled"}
                                                        name={"mobile"}
                                                        label={"Teléfono"}
                                                        size={"small"}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            disableUnderline:true,
                                                            inputComponent: PhoneMaskCustom,

                                                        }}
                                                    />

                                                </Grid>
                                                <Grid
                                                    item
                                                >
                                                    <FormControl variant={"filled"} style={{minWidth:"280px"}} error={touched.nationality===true &&errors.nationality}>
                                                        <InputLabel >Nacionalidad</InputLabel>
                                                        <Field
                                                            component={Select}
                                                            name="nationality"

                                                        >
                                                            {
                                                                processedCountries.map(country=><MenuItem value={country.alpha2}>{country.name}</MenuItem>)
                                                            }
                                                        </Field>
                                                        {
                                                            touched.nationality===true &&
                                                            <FormHelperText error={true}>{errors.nationality}</FormHelperText>
                                                        }
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                spacing={2}
                                                justify={isMobile?'center':'space-between'}
                                            >
                                                <Grid
                                                    item
                                                >
                                                    <FormControl variant={"filled"} style={{minWidth:"280px"}} error={touched.birthCountry===true &&errors.birthCountry}>
                                                        <InputLabel >País donde naciste</InputLabel>
                                                        <Field
                                                            component={Select}
                                                            name="birthCountry"

                                                        >
                                                            {
                                                                processedCountries.map(country=><MenuItem value={country.alpha2}>{country.name}</MenuItem>)
                                                            }
                                                        </Field>
                                                        {
                                                            touched.birthCountry===true &&
                                                            <FormHelperText error={true}>{errors.birthCountry}</FormHelperText>
                                                        }
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        component={ContractTextField}
                                                        /*
                                                            value on an input cant be null or a warning is raised, we need to initialite it
                                                            on an empty string or undefined, in this case we use undefined cause it is an
                                                            uncontroled element
                                                         */
                                                        type={"string"}
                                                        autoComplete={"off"}
                                                        variant={"filled"}
                                                        name={"birthRegion"}
                                                        label={"Region donde naciste"}
                                                        size={"small"}
                                                        InputProps={{
                                                            disableUnderline:true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        component={ContractTextField}
                                                        /*
                                                            value on an input cant be null or a warning is raised, we need to initialite it
                                                            on an empty string or undefined, in this case we use undefined cause it is an
                                                            uncontroled element
                                                         */
                                                        type={"string"}
                                                        autoComplete={"off"}
                                                        variant={"filled"}
                                                        name={"birthCity"}
                                                        label={"Ciudad donde naciste"}
                                                        size={"small"}
                                                        InputProps={{
                                                            disableUnderline:true,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                direction={"column"}
                                                alignItems={isMobile?'center':'inherit'}
                                            >
                                                <Grid
                                                    item
                                                    container
                                                    alignItems={"center"}
                                                    justify={"space-between"}
                                                    style={{width:280}}
                                                >
                                                    <Grid
                                                        item
                                                        xs={3}
                                                        sm={2}
                                                    >
                                                        <Field component={Checkbox} type="checkbox" name="variousNationalities"/>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs={9}
                                                        sm={10}
                                                    >
                                                        <Typography variant={"body2"} style={{cursor:'pointer'}} onClick={()=>setValues({...values,variousNationalities  : values.variousNationalities === true ? false : true})}>
                                                            Tengo varias nacionalidades
                                                        </Typography>
                                                    </Grid>

                                                </Grid>
                                                {
                                                    values.variousNationalities===true &&
                                                    <Grid
                                                        item
                                                        container
                                                        spacing={2}
                                                        justify={isMobile?'center':'space-between'}
                                                    >
                                                        <Grid
                                                            item
                                                        >
                                                            <FormControl variant={"filled"} style={{minWidth:"280px"}} error={touched.nationality1===true &&errors.nationality1}>
                                                                <InputLabel >Nacionalidad</InputLabel>
                                                                <Field
                                                                    component={Select}
                                                                    name="nationality1"

                                                                >
                                                                    {
                                                                        processedCountries.map(country=><MenuItem value={country.alpha2}>{country.name}</MenuItem>)
                                                                    }
                                                                </Field>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                        >
                                                            <FormControl variant={"filled"} style={{minWidth:"280px"}} error={touched.nationality2===true &&errors.nationality2}>
                                                                <InputLabel >Nacionalidad</InputLabel>
                                                                <Field
                                                                    component={Select}
                                                                    name="nationality2"

                                                                >
                                                                    {
                                                                        processedCountries.map(country=><MenuItem value={country.alpha2}>{country.name}</MenuItem>)
                                                                    }
                                                                </Field>
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                        >
                                                            <FormControl variant={"filled"} style={{minWidth:"280px"}} error={touched.nationality3===true &&errors.nationality3}>
                                                                <InputLabel >Nacionalidad</InputLabel>
                                                                <Field
                                                                    component={Select}
                                                                    name="nationality3"

                                                                >
                                                                    {
                                                                        processedCountries.map(country=><MenuItem value={country.alpha2}>{country.name}</MenuItem>)
                                                                    }
                                                                </Field>
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            container
                                            direction={"column"}
                                        >
                                            <Grid
                                                item
                                            >
                                                <FormLabel component={"legend"}>
                                                    <Typography variant={"caption"} style={{color:colors['--gray-dark']}}>
                                                        DATOS FISCALES
                                                    </Typography>
                                                </FormLabel>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                spacing={2}
                                                justify={isMobile?'center':'space-between'}
                                            >
                                                <Grid
                                                    item
                                                >
                                                    <FormControl variant={"filled"} style={{minWidth:"140px"}} error={errors.residenceCountry}>
                                                        <InputLabel>Provincia</InputLabel>
                                                        <Field
                                                            component={Select}
                                                            name="province"

                                                        >

                                                            {
                                                                provinces.map(province=><MenuItem value={province.name}>{province.name}</MenuItem>)
                                                            }
                                                        </Field>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        style={{width:124}}
                                                        component={ContractTextField}
                                                        /*
                                                            value on an input cant be null or a warning is raised, we need to initialite it
                                                            on an empty string or undefined, in this case we use undefined cause it is an
                                                            uncontroled element
                                                         */
                                                        type={"number"}
                                                        autoComplete={"off"}
                                                        variant={"filled"}
                                                        name={"postalCode"}
                                                        label={"Código postal"}
                                                        size={"small"}
                                                        InputProps={{
                                                            disableUnderline:true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                >
                                                    <FormControl variant={"filled"} style={{minWidth:"280px"}} error={touched.region===true &&errors.region}>
                                                        <InputLabel>Comunidad Autónoma</InputLabel>
                                                        <Field
                                                            component={Select}
                                                            name="region"

                                                        >
                                                            {
                                                                regions.map(region=><MenuItem value={region}>{region}</MenuItem>)
                                                            }
                                                        </Field>
                                                        {
                                                            touched.region===true &&
                                                            <FormHelperText error={true}>{errors.region}</FormHelperText>
                                                        }
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        name="city"
                                                        component={Autocomplete}
                                                        options={cityNames}
                                                        getOptionLabel={(option) => option}
                                                        style={{ width: 280 }}
                                                        onChange={(e,value)=>setFieldValue('city',value !== null? value: null)}
                                                        renderInput={(params) => (
                                                            <Text
                                                                {...params}
                                                                error={touched['city'] && !!errors['city']}
                                                                helperText={errors['city']}
                                                                label="Población"
                                                                name='city'
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                justify={isMobile?'center':'space-between'}
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                >
                                                    <FormControl variant={"filled"} style={{minWidth:"140px"}} error={touched.addressType===true &&errors.addressType}>
                                                        <InputLabel >Tipo de vía</InputLabel>
                                                        <Field
                                                            component={Select}
                                                            name="addressType"

                                                        >
                                                            {
                                                                streets.map(street=><MenuItem value={street.code}>{street.name}</MenuItem>)
                                                            }
                                                        </Field>
                                                        {
                                                            touched.addressType===true &&
                                                            <FormHelperText error={true}>{errors.addressType}</FormHelperText>
                                                        }
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        style={{width:isMobile?280:420}}
                                                        component={ContractTextField}
                                                        /*
                                                            value on an input cant be null or a warning is raised, we need to initialite it
                                                            on an empty string or undefined, in this case we use undefined cause it is an
                                                            uncontroled element
                                                         */
                                                        type={"string"}
                                                        autoComplete={"off"}
                                                        variant={"filled"}
                                                        name={"address"}
                                                        label={"Nombre de la calle"}
                                                        size={"small"}
                                                        InputProps={{
                                                            disableUnderline:true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        style={{width:132}}
                                                        component={ContractTextField}
                                                        /*
                                                            value on an input cant be null or a warning is raised, we need to initialite it
                                                            on an empty string or undefined, in this case we use undefined cause it is an
                                                            uncontroled element
                                                         */
                                                        type={"string"}
                                                        autoComplete={"off"}
                                                        variant={"filled"}
                                                        name={"addressNumber"}
                                                        label={"Número"}
                                                        size={"small"}
                                                        InputProps={{
                                                            disableUnderline:true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        style={{width:132}}
                                                        component={ContractTextField}
                                                        /*
                                                            value on an input cant be null or a warning is raised, we need to initialite it
                                                            on an empty string or undefined, in this case we use undefined cause it is an
                                                            uncontroled element
                                                         */
                                                        type={"string"}
                                                        autoComplete={"off"}
                                                        variant={"filled"}
                                                        name={"addressFloor"}
                                                        label={"Piso"}
                                                        size={"small"}
                                                        InputProps={{
                                                            disableUnderline:true,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                justify={isMobile?'center':'space-between'}
                                            >
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        style={{width:isMobile?280: 400}}
                                                        component={ContractTextField}
                                                        /*
                                                            value on an input cant be null or a warning is raised, we need to initialite it
                                                            on an empty string or undefined, in this case we use undefined cause it is an
                                                            uncontroled element
                                                         */
                                                        type={"string"}
                                                        autoComplete={"off"}
                                                        variant={"filled"}
                                                        name={"IBAN"}
                                                        label={"IBAN de tu cuenta corriente"}
                                                        size={"small"}
                                                        inputProps={{style: {textTransform: 'uppercase'}}}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        InputProps={{
                                                            disableUnderline:true,
                                                            inputComponent: TextMaskCustom
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            direction={"column"}
                                        >
                                            <Grid
                                                item
                                            >
                                                <FormLabel component={"legend"}>
                                                    <Typography variant={"caption"} style={{color:colors['--gray-dark']}}>
                                                        DATOS PROFESIONALES
                                                    </Typography>
                                                </FormLabel>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                spacing={2}
                                                justify={isMobile?'center':'space-between'}
                                            >
                                                <Grid
                                                    item
                                                >
                                                    <FormControl variant={"filled"} style={{width:"280px"}} error={touched.laboral===true &&errors.laboral}>
                                                        <InputLabel>Ocupación</InputLabel>
                                                        <Field
                                                            component={Select}
                                                            name="laboral"

                                                        >
                                                            {
                                                                laboral.map(status=><MenuItem value={status.id}>{status.name}</MenuItem>)
                                                            }
                                                        </Field>
                                                        {
                                                            touched.laboral===true &&
                                                            <FormHelperText error={true}>{errors.laboral}</FormHelperText>
                                                        }

                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    item
                                                >
                                                    <FormControl variant={"filled"} style={{width:"280px"}} error={touched.profesion===true &&errors.profesion}>
                                                        <InputLabel>Sector</InputLabel>
                                                        <Field
                                                            component={Select}
                                                            name="profesion"

                                                        >
                                                            {
                                                                professions.map(status=><MenuItem value={status.id}>{status.name}</MenuItem>)
                                                            }
                                                        </Field>
                                                        {
                                                            touched.profesion===true &&
                                                            <FormHelperText error={true}>{errors.profesion}</FormHelperText>
                                                        }

                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    item
                                                    style={{width:280}}
                                                >

                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            className={contractPlanClasses.boton}
                                            justify={isMobile?'center':'space-between'}
                                        >
                                            <Button
                                                variant={"contained"}
                                                color={"primary"}
                                                onClick={()=>validateForm().then(submitForm)}
                                            >
                                                Continuar
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Box>
            </Box>
        </Grid>
    )
}