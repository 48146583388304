import {makeStyles} from "@material-ui/core/styles";

export const teamStyles=makeStyles(theme=>({
    container:{
        marginBottom:"90px",
        [theme.breakpoints.down("sm")]:{
            paddingLeft:"20px",
            paddingRight:"20px",
            marginBottom:"16px",
        }
    }
}))