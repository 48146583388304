import {format} from 'date-fns'
export function onboardingAccountFormHelpersParseFormData(values){
    const {
        name,
        date,
        gender,
        partner,
        sons,
        monthly_income
    }=values

    return {
        family_situation:{
            name:name,
            birthday:format(date,'dd/MM/yyyy'),
            gender:gender==="male"? 'H' : 'M',
            has_partner:partner === "partner",
            current_children:parseInt(sons),
        },
        income: {
            total:monthly_income
        }
    }
}