import React from "react";
import {
    PieChart, Pie
} from 'recharts';
import GoalSvg from "../goalSvg/GoalSvg";

const renderCustomizedLabel = (props) => {
    const {x,y,name,fill}=props
    return (
        <svg
            x={x-16}
            y={y-24}
            width={36}
            height={36}
        >
            <GoalSvg type={name} color={fill} width={32}/>
        </svg>
    );
};

export default function ChartRadialBarGoalsVsInitialInvestment(props){
    const {data,objective_index}=props
    return(
            <PieChart width={180} height={180}>
                <Pie isAnimationActive={false} data={data} dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={45} outerRadius={55} label={renderCustomizedLabel} labelLine={false}/>
                {
                    objective_index>=0 &&
                    <text
                        x={180 / 2}
                        y={180 / 2}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        className="progress-label"
                        style={{fontSize:"24px", fontFamily:"DM Sans", lineHeight:"32px", fill:data[objective_index].fill}}
                    >
                        {((data[objective_index].value / data.reduce((acc, dataRow) => acc + dataRow.value, 0)*100)).toFixed(0)}
                        %
                    </text>
                }
            </PieChart>
    )
}