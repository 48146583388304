//Comentario de prueba
import React, {Suspense, lazy} from 'react';
import { ThemeProvider } from '@material-ui/core/styles'
import {Switch,Route,Redirect} from "react-router-dom";

import themesInveert from "./styles/themes/ThemesInveert";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import {selectPlanExists} from "./redux/plan/planSlice";
import {useSelector} from "react-redux";
import GlobalPosition from "./screens/globalPosition/GlobalPosition";
import ContractPlan from "./screens/contractPlan/ContractPlan";
import Kyc from "./screens/kyc/Kyc";
import SignContracts from "./screens/signContracts/SignContracts";
import Team from "./screens/team/Team";
import LegalPrivacy from "./screens/legal/LegalPrivacy";
import LegalCookies from "./screens/legal/LegalCookies";
import LegalWarning from "./screens/legal/LegalWarnings";
import NewPassword from "./screens/newPassword/NewPassword";
import Verification from "./screens/verification/Verification";
import SaveTokenAfterRedirection from "./screens/saveTokenAfterRedirection/SaveTokenAfterRedirection";
import Login from "./screens/login/Login";
import UserData from "./screens/userData/UserData";
import AppointmentPanel from "./screens/appointmentPanel/AppointmentPanel";

const OnboardingAccount = lazy(()=>import("./screens/onboardingAccount/OnboardingAccount"));
const OnboardingAddGoal = lazy(()=>import("./screens/onboardingAddGoal/OnboardingAddGoal"));
const OnboardingGoals = lazy(()=>import("./screens/onboardingGoals/OnboardingGoals"));
const OnboardingRiskProfile = lazy(()=>import("./screens/onboardingRiskProfile/OnboardingRiskProfile"));
const OnboardingAddRiskProfile = lazy(()=>import("./screens/onboardingAddRiskProfile/OnboardingAddRiskProfile"));
const Plan = lazy(()=>import("./screens/plan/Plan"));
const AddToken = lazy(()=>import("./screens/addToken/AddToken"));
const GoalDetail = lazy(()=>import("./screens/goalDetail/GoalDetail"));
const StartPlan = lazy(()=>import("./screens/startPlan/StartPlan"));

function ProtectedRoute(props){
    let planExists=useSelector(selectPlanExists)
    const isOnboardingUrl=(props.path==="/posicion-global"||props.path==="/plan"||props.path==="/contratacion"||props.path.match("/plan/objetivo"))?false:true
    const isTokenMissing=localStorage.getItem('token')?false:true
    if(isTokenMissing===true){
        return <Route
            path={props.path}
            component={()=><Redirect to={"/"}/>}
        />
    }else{
        if(process.env.REACT_APP_ENVIRONMENT==="NUNCA_ENTRA"){
            if(isOnboardingUrl===true){
                if(planExists===true){
                    if(props.path==="/tu-plan" || props.path==="/perfil-riesgo/formulario" || props.path==="/recomendacion"){
                        return <Route
                            path={props.path}
                            component={()=>props.component}
                        />
                    }
                    else{
                        return <Route
                            path={props.path}
                            component={()=><Redirect to={"/plan"}/>}
                        />
                    }
                }else{
                    return <Route
                        path={props.path}
                        component={()=>props.component}
                    />
                }
            }else{
                if(planExists===true){
                    return <Route
                        path={props.path}
                        component={()=>props.component}
                    />
                }else{
                    return <Route
                        path={props.path}
                        component={()=><Redirect to={"/"}/>}
                    />
                }

            }
        }else{
            return <Route
                path={props.path}
                component={()=>props.component}
            />
        }
    }
}

function App() {

    return (
        <ThemeProvider theme={themesInveert}>
            <Suspense
                fallback={
                    <Backdrop style={{zIndex:"100"}}  open >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                }
            >
                <Switch>
                    <ProtectedRoute
                        path={"/crear-meta/(educacion|casa|personalizada|jubilacion|vacaciones)"}
                        component={<OnboardingGoals/>}
                    />
                    <ProtectedRoute
                        path={"/crear-meta"}
                        component={<OnboardingAddGoal/>}
                    />
                    <ProtectedRoute
                        path={"/perfil-riesgo/formulario"}
                        component={<OnboardingRiskProfile/>}
                    />
                    <ProtectedRoute
                        path={"/perfil-riesgo"}
                        component={<OnboardingAddRiskProfile/>}
                    />
                    {
                        /*
                        <ProtectedRoute
                          path={"/tu-plan"}
                          component={<OnboardingYourPlan/>}
                        />
                         */
                    }
                    <ProtectedRoute
                        path={"/plan/objetivo/:id"}
                        component={<GoalDetail/>}
                    />
                    <ProtectedRoute
                        path={"/plan"}
                        component={<Plan/>}
                    />
                    <ProtectedRoute
                        path={"/recomendacion"}
                        component={<StartPlan/>}
                    />
                    <ProtectedRoute
                        path={"/posicion-global"}
                        component={<GlobalPosition/>}
                    />
                    <Route
                        path={"/token/:token"}
                        component={AddToken}
                    />
                    <Route
                        path={'/contratacion/contratos'}
                        component={SignContracts}
                    />
                    <Route
                        path={'/contratacion/kyc'}
                        component={Kyc}
                    />
                    <Route
                        path={'/contratacion'}
                        component={ContractPlan}
                    />
                    <Route
                        path={"/sobre-nosotros"}
                        component={Team}

                    />
                    <Route
                        path={"/privacidad"}
                        component={LegalPrivacy}
                    />
                    <Route
                        path={"/cookies"}
                        component={LegalCookies}
                    />
                    <Route
                        path={"/aviso-legal"}
                        component={LegalWarning}
                    />
                    <Route
                        path={'/restaurar-contraseña/:uuid'}
                        component={NewPassword}
                    />
                    <Route
                        path={'/verificacion'}
                        component={Verification}
                    />
                    <Route
                        path={'/auto-login/:token'}
                        component={SaveTokenAfterRedirection}
                    />
                    <Route
                        path={'/login'}
                        component={Login}
                    />
                    <Route
                        path={'/datos-personales'}
                        component={UserData}
                    />
                    <Route
                        path={'/panel-citas'}
                        component={AppointmentPanel}
                    />
                    <Route
                        path={"/"}
                        component={OnboardingAccount}

                    />
                </Switch>
            </Suspense>
        </ThemeProvider>
    );
}

export default App
