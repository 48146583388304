import React, {useEffect} from "react";
import {
    Grid,
    Typography,
    LinearProgress,
    withStyles, Button
} from "@material-ui/core";
import {colors} from "../../styles/colors/colors";
import SidebarPlan from "../../components/sidebar/SidebarPlan";
import Box from "@material-ui/core/Box";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../../redux/user/userSlice";
import {planStyles} from "../plan/planStyles";
import {
    getContracts,

    selectRecommendation,
    setContractRoute
} from "../../redux/recommendation/recommendationSlice";
import {useHistory} from "react-router-dom";
import {contractPlanStyles} from "../contractPlan/contractPlanStyles";

import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import {parseSaml} from "../../utils/parseSaml";
import {Responsive} from "../../utils/Responsive";

const ContractLinearProgress=withStyles(theme=>({
    root:{
        width:'100%'
    },
    colorPrimary:{
        backgroundColor:colors['--gray-soft']
    }
}))(LinearProgress)

export default function SignContracts (){

    const history=useHistory()
    const dispatch=useDispatch()
    const progress=80
    const user=useSelector(selectUser)
    const classes=planStyles()
    const recommendation=useSelector(selectRecommendation)
    const contractPlanClasses=contractPlanStyles()
    useEffect(() => {
        dispatch(setContractRoute('/contratacion/contratos'))
        dispatch(getContracts())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        if(history.location.pathname!==recommendation.contract.route){
            history.push("/contratacion/contratos")
        }else{
            dispatch(setContractRoute('/contratacion/kyc'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recommendation.contract.route]);
    return(
        <Grid
            container
            style={{background:colors['--background']}}
        >
            <Backdrop  open={recommendation.loading} style={{zIndex:"100"}}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box style={{flexGrow:"1", display: "flex", overflow: "hidden"}}>
                <Box className={classes.menu_bar_box}>
                    <SidebarPlan user={user} setBeginTour={null}/>
                </Box>
                <Box className={classes.content_container_box}>
                    {/*
                HEADER
            */}
                    <Grid
                        item
                        container
                        direction={"column"}
                        className={contractPlanClasses.header_container}
                    >
                        <Grid
                            item
                            container
                            direction={"column"}
                            spacing={1}
                        >
                            <Grid
                                item
                            >
                                <Typography variant={'h3'}>
                                    Contratación de tu plan
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={'body2'}>
                                    Rellena los siguientes datos para proceder a la contratación de tu plan
                                </Typography>
                            </Grid>
                        </Grid>
                        <Responsive displayIn={'Laptop'}>
                            <Grid
                                item
                                container
                                style={{marginTop:20,maxWidth:1040}}
                            >
                                <Grid
                                    item
                                    container
                                >
                                    <Grid
                                        item
                                        sm={4}
                                        container
                                        className={contractPlanClasses.header_menu_item}
                                    >
                                        <Typography variant={"caption"} >
                                            REGISTRO
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        justify={"center"}
                                        sm={4}
                                        className={contractPlanClasses.header_menu_item}
                                    >
                                        <Typography variant={"caption"} style={{color:progress<(100/3)?colors['--gray-dark']:'inherit'}}>
                                            CUMPLIMIENTO NORMATIVO
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        sm={4}
                                        container
                                        justify={"center"}
                                    >
                                        <Typography variant={"caption"} style={{color:progress<(200/3)?colors['--gray-dark']:'inherit'}}>
                                            CONTRATOS
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Responsive>
                    </Grid>
                    <Grid
                        item
                        container
                    >
                        <ContractLinearProgress variant="determinate" value={progress}/>
                    </Grid>
                    {/*
                BODY
            */}
                    <Grid
                        item
                        container
                        className={contractPlanClasses.body_container}
                        direction={"column"}ç
                        spacing={3}
                    >
                        {
                            Object.keys(recommendation.contract.services).map(key=>
                                <Grid
                                    item
                                    container
                                >
                                    <Grid
                                        item
                                    >
                                        <Typography variant={"caption"} style={{color:colors['--gray-dark']}}>
                                            {key==='mutual'?'FONDOS DE INVERSIÓN':key==='pension'?'FONDOS DE PENSIONES':'DEPÓSITOS'}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        container
                                        spacing={1}
                                    >
                                        {
                                            recommendation.contract.services[key].map(service=>
                                                <Grid
                                                    item
                                                    container
                                                    alignItems={"center"}
                                                    spacing={2}
                                                >
                                                    <Grid
                                                        item
                                                    >
                                                        <Typography variant={"body2"}>
                                                            {service.product.name}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                    >
                                                        <Button
                                                            variant={"outlined"}
                                                            size={"small"}
                                                            onClick={()=>{
                                                                const correctSaml=recommendation.contract?.saml.reduce((correctSaml,saml)=>{
                                                                    if(saml.profile_id===service.product.profile_id){
                                                                        correctSaml=saml.saml.data
                                                                    }
                                                                    return correctSaml
                                                                },null)
                                                                if(correctSaml){
                                                                    let form = document.createElement("form")
                                                                    form._submit_function_ = form.submit
                                                                    form.setAttribute("method", "POST")
                                                                    form.setAttribute("action", process.env.REACT_APP_LOGALTY_URL);
                                                                    let hiddenFieldSaml=document.createElement("input")
                                                                    hiddenFieldSaml.setAttribute("type","hidden")
                                                                    hiddenFieldSaml.setAttribute("value", parseSaml(correctSaml));
                                                                    hiddenFieldSaml.setAttribute("name", "saml_assertion");
                                                                    form.appendChild(hiddenFieldSaml)
                                                                    document.body.appendChild(form);
                                                                    form._submit_function_()



                                                                }
                                                            }}
                                                        >
                                                            Contratar
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            )
                        }
                    </Grid>
                </Box>
            </Box>
        </Grid>
    )
}