import React, {useEffect, useState} from "react";
import {Grid, Typography, Divider} from "@material-ui/core";
import {getAuthUser} from "../../services/oauth/oauthUser";
import {useHistory} from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import {getUsers} from "../../services/user/user";
import {colors} from "../../styles/colors/colors";

export default function AppointmentPanel(){
    const [loading,setLoading]=useState(true)
    const [users,setUsers]=useState([])
    const history=useHistory()
    useEffect(() => {
        document.title = "Panel de citas"
        window.dataLayer.push({'title': document.title});
        getAuthUser()
            .then(response=> {
                if(response.data?.is_admin === true){
                    getUsers().then(response=>{
                        const users=response.data.filter(user=>user?.appointments.length>0)
                        setLoading(false)
                        setUsers(users)
                    })
                }else{
                    history.push('/')
                }
            })
            .catch(err=>history.push('/'))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return(
        <Grid
        container
        direction={"column"}
        style={{backgroundColor:colors['--background'], minHeight:'100vh', padding:24}}
        alignItems={"center"}
        >
            <Backdrop style={{zIndex:"100"}}  open={loading} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid
                container
                style={{maxWidth:876, width:'100%',padding:24}}
            >
                <Grid
                    item
                >
                    <Typography variant={"h1"}>
                        Panel de citas
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                style={{maxWidth:876, width:'100%',backgroundColor:colors['--white'],padding:24, boxShadow: `0px 4px 8px rgba(219, 225, 231, 0.3), 0px 8px 20px ${colors["--gray-soft"]}`,
                    borderRadius:"2px",}}
                direction={"column"}

            >
                <Grid
                    container
                    justify={"space-between"}
                >
                    <Grid
                        item
                        xs={3}
                        style={{padding:'0 5px'}}
                    >
                        <Typography variant={"body2"} style={{fontFamily:'Avenir Heavy'}}>
                            Nombre
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        style={{padding:'0 5px'}}
                    >
                        <Typography variant={"body2"} style={{fontFamily:'Avenir Heavy'}}>
                            Correo
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        style={{padding:'0 5px'}}
                    >
                        <Typography variant={"body2"} style={{fontFamily:'Avenir Heavy'}}>
                            Teléfono
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={3}
                        style={{padding:'0 5px'}}
                    >
                        <Typography variant={"body2"} style={{fontFamily:'Avenir Heavy'}}>
                            Fecha de la cita
                        </Typography>
                    </Grid>
                    <Divider style={{width:'100%'}}/>
                </Grid>
                {
                    users.length===0?
                        <Grid
                            container
                            justify={"center"}
                            style={{marginTop:24}}
                        >
                            <Typography variant={"body2"} style={{color:colors['--gray-dark']}}>
                                No hay citas
                            </Typography>
                        </Grid>
                        :
                    users.map(user=>
                        user?.appointments.map(appointment=>
                            <Grid
                                container
                                justify={"space-between"}
                                style={{marginTop:24}}
                            >
                                <Grid
                                    item
                                    xs={3}
                                    style={{padding:'0 5px'}}
                                >
                                    <Typography variant={"body2"} noWrap>
                                        {user?.family_situation?.name}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                    style={{padding:'0 5px'}}
                                >
                                    <Typography noWrap variant={"body2"} >
                                        {user?.email}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                    style={{padding:'0 5px'}}
                                >
                                    <Typography variant={"body2"} >
                                        {user?.phone}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={3}
                                    style={{padding:'0 5px'}}
                                >
                                    <Typography variant={"body2"}>
                                        {appointment.date_appointment}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    )
                }

            </Grid>
        </Grid>
    )
}