import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from "react-router-dom";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Provider } from 'react-redux';
import {store} from './redux/store'
import {persistor} from "./redux/store";
import { PersistGate } from 'redux-persist/integration/react'
import configAxios from "./services/config/ConfigAxios";
import configAxiosInterceptors from "./services/config/ConfigAxiosInterceptors";
import {es} from 'date-fns/locale'
import {SnackbarProvider} from "notistack";
import {SnackbarUtilsConfigurator} from "./utils/SnackbarUtils";
import {Helmet} from "react-helmet";
import {isotypeSrc} from "./utils/logotype";

//initAmplitude();
configAxios(process.env.REACT_APP_API_URL)
configAxiosInterceptors(store)

ReactDOM.render(
    <React.StrictMode>
        <Helmet>
            <link rel='icon' href={isotypeSrc()}/>
        </Helmet>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Router>
                        <SnackbarProvider  maxSnack={1} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                            {/*
                                We call snackbar in here to make possible to access the snack everywhere in our code,
                                even on regular js functions
                            */}
                            <SnackbarUtilsConfigurator />
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                                <App />
                            </MuiPickersUtilsProvider>
                        </SnackbarProvider>
                </Router>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
        document.getElementById('root')
        );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
