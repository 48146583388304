export const colors={
    /*
        Brand main
     */
    "--brand-main-100":process.env.REACT_APP_ENVIRONMENT==='finsei'?"#00B4C1":process.env.REACT_APP_ENVIRONMENT==='planifica'?'rgba(124, 176, 199, 1)':"#00B4C1",
    "--brand-main-90":"#1ABBC7",
    "--brand-main-50":"#80D9E0",
    "--brand-main-30":"#B3E9EC",
    "--brand-main-15":process.env.REACT_APP_ENVIRONMENT==='planifica'?"rgba(124, 176, 199, 0.7)":"#D9F4F6",
    "--brand-main-10":"#E5F7F9",
    "--brand-main-5":"#F2FBFC",
    /*
        Brand accent
     */
    "--brand-accent-100":process.env.REACT_APP_ENVIRONMENT==='finsei'?"#FF622D":process.env.REACT_APP_ENVIRONMENT==='planifica'?'#CDCDDE':"#FFAB47",
    "--brand-accent-90":"#FFB359",
    "--brand-accent-50":"#FFD5A3",
    "--brand-accent-30":"#FFE6C8",
    "--brand-accent-15":"#FFF2E3",
    /*
        Interactive
     */
    "--interactive":process.env.REACT_APP_ENVIRONMENT==='finsei'?"#013D92":process.env.REACT_APP_ENVIRONMENT==='planifica'?'#144693':"#074C7F",
    "--interactive-1":"#076BB7",
    "--interactive-2":"#C5E4FC",
    "--interactive-3":"#063E66",
    "--interactive-4":process.env.REACT_APP_ENVIRONMENT==='finsei'?"#97B3CB":process.env.REACT_APP_ENVIRONMENT==='planifica'?'rgba(124, 176, 199, 1)':"#00B4C1",
    /*
        Goals
     */
    "--goal-primary-100":"#1982B6",
    "--goal-primary-90": "#308EBD",
    "--goal-primary-50": "#8CC0DA",
    "--goal-primary-30": "#BAD9E9",
    "--goal-primary-15": "#DDECF4",
    "--goal-primary-10": "#E8F2F8",

    "--goal-secondary-100":"#0098A3",
    "--goal-secondary-90": "#1AA2AC",
    "--goal-secondary-50": "#80CBD1",
    "--goal-secondary-30": "#B3E0E3",
    "--goal-secondary-15": "#D9F0F1",
    "--goal-secondary-10": "#D9F0F1",

    "--goal-tertiary-100":"#125D82",
    "--goal-tertiary-90": "#2A6D8F",
    "--goal-tertiary-50": "#88AEC0",
    "--goal-tertiary-30": "#B8CEDA",
    "--goal-tertiary-15": "#DBE7EC",
    "--goal-tertiary-10": "#DBE7EC",

    "--goal-quaternary-100":"#056E72",
    "--goal-quaternary-90": "#1E7D80",
    "--goal-quaternary-50": "#82B7B8",
    "--goal-quaternary-30": "#B4D3D5",
    "--goal-quaternary-15": "#D9E9EA",
    "--goal-quaternary-10": "#D9E9EA",

    /*
        Other colors
     */
    "--black":"#000306",
    "--black-soft":"#5A5C5E",
    "--white":"#FFFFFF",
    "--gray-dark":"#AABACA",
    "--gray":"#DBE1E7",
    "--gray-soft":"#F0F5F9",
    "--background":"#F9FCFF",
    "--error":"#E22E59",
    "--success":"#0EE7A6",
    "--green-aqua":"#DBEFE9",

    /*
        Gradient colors
     */

    "--gradient-brand-main-100-90":"linear-gradient(180deg, #00B4C1 0%, #1ABBC7 100%)",
    "--gradient-brand-main-1-2":"linear-gradient(180deg, #00B4C1 0%, #80D9E0 100%)",
    "--gradient-brand-main-3-4":"linear-gradient(180deg, #B3E9EC 0%, #D9F4F6 100%)",
    "--gradient-goal-primary-100-90":"linear-gradient(180deg, #1982B6 0%, #4FA4D2 100%, #308EBD 100%)",
    "--gradient-goal-primary-1-2":"linear-gradient(180deg, #1982B6 0%, #8CC0DA 100%)",
    "--gradient-goal-primary-3-4":"linear-gradient(180deg, #BAD9E9 0%, #DDECF4 100%)",
    "--gradient-goal-secondary-100-90":"linear-gradient(180deg, #0098A3 0%, #1AA2AC 100%)",
    "--gradient-goal-secondary-1-2":"linear-gradient(180deg, #0098A3 0%, #80CBD1 100%)",
    "--gradient-goal-secondary-3-4":"linear-gradient(180deg, #B3E0E3 0%, #D9F0F1 100%)",
    "--gradient-goal-tertiary-100-90":"linear-gradient(180deg, #125D82 0%, #2A6D8F 100%)",
    "--gradient-goal-tertiary-1-2":"linear-gradient(180deg, #125D82 0%, #88AEC0 100%)",
    "--gradient-goal-tertiary-3-4":"linear-gradient(180deg, #B8CEDA 0%, #DBE7EC 100%)",
    "--gradient-goal-quaternary-100-90":"linear-gradient(180deg, #056E72 0%, #1E7D80 100%)",
    "--gradient-goal-quaternary-1-2":"linear-gradient(180deg, #056E72 0%, #82B7B8 100%)",
    "--gradient-goal-quaternary-3-4":"linear-gradient(180deg, #B4D3D5 0%, #D9E9EA 100%)",
    "--gradient-brand-accent":"linear-gradient(180deg, #FFAB47 0%, #FFD5A3 100%)",
    "--gradient-interactive":"linear-gradient(180deg, #076BB7 0%, #074C7F 0.01%, #076BB7 100%)",

}