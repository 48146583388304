import React, {useState} from "react";
import {
    Button,
    Dialog,
    Divider,
    IconButton,
    InputAdornment,
    Link,
    Tab,
    Tabs,
    Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
//import * as Yup from "yup";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {logotypeSrc} from "../../utils/logotype";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {selectUser, setIsRegisterVisible} from "../../redux/user/userSlice";
import * as Yup from "yup";
import {
    changePassword,
    getResendVerificationEmail,
    login,
    putUser,
    sendChangePasswordEmail
} from "../../services/oauth/oauthUser";
import {useHistory} from "react-router-dom";
import SnackbarUtils from "../../utils/SnackbarUtils";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";

const RegistrationDialogStyles=makeStyles(theme=>({
    container:{
        padding:35,
        [theme.breakpoints.down("sm")]:{
            padding:20
        }
    }
}))

export default function RegistrationDialog(props) {
    const validationSchema = Yup.object({
            tabValue: Yup.number()
                // eslint-disable-next-line
                .oneOf[0, 1, 2, 3, 4],
            email: Yup.string()
                .nullable()
                .email("No es un email válido")
                .when("tabValue", {
                        is: 0,
                        then: Yup.string().required("Email requerido")
                    }
                )
                .when("tabValue", {
                        is: 1,
                        then: Yup.string().required("Email requerido")
                    }
                )
                .when("tabValue", {
                        is: 2,
                        then: Yup.string().required("Email requerido")
                    }
                )
                .when("tabValue", {
                        is: 3,
                        then: Yup.string().required("Email requerido")
                    }
                ),
            password: Yup.string()
                .nullable()
                .when("tabValue", {
                        is: 0,
                        then: Yup.string()
                            .min(4, "La contraseña es demasiado corta, debe ser cuatro letras mínimo")
                            .matches(/^.*(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$/u, 'La contraseña debe contener como minimo 1 número, 1 letra minúscula y 1 letra mayúscula')
                            .required("Necesitas una contraseña")
                    }
                )
                .when("tabValue", {
                        is: 1,
                        then: Yup.string().required("Necesitas una contraseña")
                    }
                )
                .when("tabValue", {
                        is: 4,
                        then: Yup.string()
                            .min(4, "La contraseña es demasiado corta, debe ser cuatro letras mínimo")
                            .matches(/^.*(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).*$/u, 'La contraseña debe contener como minimo 1 número, 1 letra minúscula y 1 letra mayúscula')
                            .required("Necesitas una contraseña")
                    }
                ),
            passwordConfirmation: Yup.string()
                .nullable()
                .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
                .when("tabValue", {
                    is: 0,
                    then: Yup.string().required("Confirma la contraseña")
                })
                .when("tabValue", {
                        is: 4,
                        then: Yup.string().required("Necesitas una contraseña")
                    }
                ),
        }
    )
    const user =useSelector(selectUser)
    const logoSrc = logotypeSrc()
    const history = useHistory()
    const dispatch = useDispatch()
    const classes = RegistrationDialogStyles()
    const {openRegistrationDialog} = props
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showFooter, setShowFooter] = useState(false)
    return (
        <Dialog open={openRegistrationDialog} style={{maxWidth: 400}}
                onClose={() => dispatch(setIsRegisterVisible(false))}>
            <Backdrop style={{zIndex:"1501"}}  open={loading} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Formik
                initialValues={{
                    email: null,
                    password: null,
                    passwordConfirmation: null,
                    tabValue: (
                        history.location.pathname.includes('/restaurar-contraseña') ?
                            4
                            :
                            (history.location.pathname === '/' || history.location.pathname === '/login') ?
                                1
                                :
                                (history.location.pathname === '/plan' && user.is_active===true && user.email_verified_at===null)?
                                    2
                                    :
                                    0
                    )
                }}
                validationSchema={validationSchema}
                onSubmit={(values, {setSubmitting}) => {
                    setLoading(true)
                    if (values.tabValue === 1) {
                        login({email: values.email, password: values.password}).then(response => {
                            dispatch({type:'reset'})
                            localStorage.setItem('token', response.data?.access_token)
                            window.dataLayer.push({
                                'event': 'login',
                                'email': values.email,
                            });
                            dispatch(setIsRegisterVisible(false))
                            history.push('/plan')
                        }).catch(response => {
                        }).finally(() => {
                            setSubmitting(false)
                            setLoading(false)
                            dispatch(setIsRegisterVisible(false))
                        })
                    } else if (values.tabValue === 3) {
                        sendChangePasswordEmail({email: values.email}).then(response => {
                            SnackbarUtils.success('Te enviaremos el email para cambiar contraseña si el correo existe en nuestra base de datos')
                            setTimeout(() => history.go(0), 3000);
                        }).catch(() => {
                        }).finally(() => {
                            setSubmitting(false)
                            setLoading(false)
                            dispatch(setIsRegisterVisible(false))
                        })
                    } else if (values.tabValue === 4) {
                        changePassword({nuevaContraseña: values.password, uuid: props?.uuid}).then(response => {
                                SnackbarUtils.success('Contraseña cambiada correctamente')
                                setTimeout(() => history.push('/login'), 3000);
                            }
                        ).catch(() => SnackbarUtils.error('No se pudo modificar la contraseña, intentelo de nuevo más tarde')).finally(() => {
                            setSubmitting(false)
                            setLoading(false)
                            dispatch(setIsRegisterVisible(false))
                        })
                    } else if (values.tabValue === 0) {
                        putUser({email: values.email, password: values.password, is_active: true}).then(response => {
                            SnackbarUtils.success('Te enviaremos el email de verificación a tu correo')
                            window.dataLayer.push({
                                'event': 'signup',
                                'email': values.email,
                            });
                            setTimeout(() => history.push('/login'), 3000);
                        }).catch(() => setShowFooter(true)).finally(() => {
                            setSubmitting(false)
                            setLoading(false)
                            dispatch(setIsRegisterVisible(false))
                        })
                    } else if (values.tabValue === 2) {
                        getResendVerificationEmail().then(response => {
                            SnackbarUtils.success('Te hemos enviado un mail de verificación')
                            setTimeout(() => history.push('/login'), 3000);
                        }).catch(response => SnackbarUtils.success('No se ha podido enviar la verificación, inténtelo de nuevo más tarde')).finally(() => {
                            setSubmitting(false)
                            setLoading(false)
                            dispatch(setIsRegisterVisible(false))
                        })
                    }

                }}
            >{({submitForm, validateForm, errors, submitCount, values, setValues}) => (
                <Grid
                    container
                    direction={"column"}
                    className={classes.container}
                >
                    <Grid
                        item
                        container
                        direction={"column"}
                        alignItems={"center"}
                        spacing={2}
                    >
                        <Grid
                            item
                        >
                            <img alt='' src={logoSrc} style={
                                {width: 153}
                            }
                            />
                        </Grid>
                        <Grid
                            item
                            container
                            spacing={2}
                        >
                            <Grid
                                item
                                container
                                justify={"center"}
                            >
                                {
                                    values.tabValue === 0 ?
                                        <Tabs
                                            value={0}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            onChange={(event, value) => setValues({...values, tabValue: value})}
                                            style={{minWidth: 'inherit'}}
                                        >
                                            <Tab label="REGISTRO"/>
                                        </Tabs>
                                        :
                                        values.tabValue === 1 ?
                                            <Tabs
                                                value={0}
                                                indicatorColor="primary"
                                                textColor="primary"
                                                onChange={(event, value) => setValues({...values, tabValue: value})}
                                                style={{minWidth: 'inherit'}}
                                            >
                                                <Tab label="LOGIN"/>
                                            </Tabs>
                                            :
                                            values.tabValue === 2 ?
                                                <Tabs
                                                    value={0}
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    onChange={(event, value) => setValues({...values, tabValue: value})}
                                                    style={{minWidth: 'inherit'}}
                                                >
                                                    <Tab label="REENVIAR VERIFICACIÓN"/>
                                                </Tabs>
                                                :
                                                <Tabs
                                                    value={0}
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    onChange={(event, value) => setValues({...values, tabValue: value})}
                                                    style={{minWidth: 'inherit'}}
                                                >
                                                    <Tab label="RESTAURAR CONTRASEÑA"/>
                                                </Tabs>
                                }
                            </Grid>
                            {
                                values.tabValue === 0?
                                <Grid
                                    item
                                    container
                                >
                                    <Typography variant={"caption"}>
                                        Para poder realizar cambios en el plan y ver tu recomendación de inversión
                                        necesitas estar registrado
                                    </Typography>
                                </Grid>
                                    :
                                    values.tabValue === 2?
                                        <Grid
                                            item
                                            container
                                        >
                                            <Typography variant={"caption"}>
                                                Ya estás registrado. Si no has recibido el correo de verificación,
                                                introduce tu correo y te lo reenviaremos.
                                            </Typography>
                                        </Grid>
                                        :
                                        <></>
                            }
                            <Grid
                                container
                            >
                                <Form style={{width: '100%'}}>
                                    <Grid
                                        container
                                        direction={"column"}
                                        spacing={2}
                                    >
                                        <Grid
                                            item
                                            container
                                            direction={"column"}
                                        >
                                            {
                                                values.tabValue !== 4 &&
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        component={TextField}
                                                        /*
                                                            value on an input cant be null or a warning is raised, we need to initialite it
                                                            on an empty string or undefined, in this case we use undefined cause it is an
                                                            uncontroled element
                                                         */
                                                        fullWidth
                                                        type={"string"}
                                                        autoComplete={"off"}
                                                        variant={"filled"}
                                                        name={"email"}
                                                        label={"Email"}
                                                        size={"small"}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                        }}
                                                    />
                                                </Grid>
                                            }
                                            {
                                                (values.tabValue === 0 || values.tabValue === 1 || values.tabValue === 4) &&
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        fullWidth
                                                        component={TextField}
                                                        /*
                                                            value on an input cant be null or a warning is raised, we need to initialite it
                                                            on an empty string or undefined, in this case we use undefined cause it is an
                                                            uncontroled element
                                                         */
                                                        type={showPassword === true ? "string" : "password"}
                                                        autoComplete={"off"}
                                                        variant={"filled"}
                                                        name={"password"}
                                                        label={"Contraseña"}
                                                        size={"small"}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            endAdornment:
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => setShowPassword(showPassword === false)}
                                                                        onMouseDown={(event) => event.preventDefault()}
                                                                        aria-label="toggle password visibility"
                                                                        edge={"end"}
                                                                    >
                                                                        {showPassword === true ? <VisibilityOff/> :
                                                                            <Visibility/>}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                        }}

                                                    />
                                                </Grid>
                                            }
                                            {
                                                (values.tabValue === 0 || values.tabValue === 4) &&
                                                <Grid
                                                    item
                                                >
                                                    <Field
                                                        fullWidth
                                                        component={TextField}
                                                        /*
                                                            value on an input cant be null or a warning is raised, we need to initialite it
                                                            on an empty string or undefined, in this case we use undefined cause it is an
                                                            uncontroled element
                                                         */
                                                        type={showPassword === true ? "string" : "password"}
                                                        autoComplete={"off"}
                                                        variant={"filled"}
                                                        name={"passwordConfirmation"}
                                                        label={"Confirma la contraseña"}
                                                        size={"small"}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            endAdornment:
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        onClick={() => setShowPassword(showPassword === false)}
                                                                        onMouseDown={(event) => event.preventDefault()}
                                                                        aria-label="toggle password visibility"
                                                                        edge={"end"}
                                                                    >
                                                                        {showPassword === true ? <VisibilityOff/> :
                                                                            <Visibility/>}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                        }}

                                                    />
                                                </Grid>
                                            }
                                        </Grid>
                                        <Grid
                                            item
                                            container
                                            direction={"column"}
                                        >
                                            <Grid
                                                item
                                                container
                                            >
                                                {
                                                    values.tabValue === 0 ?
                                                        <Button
                                                            variant={"contained"}
                                                            color={"primary"}
                                                            fullWidth
                                                            onClick={() => validateForm().then(submitForm)}
                                                        >
                                                            Registrar cuenta
                                                        </Button>
                                                        :
                                                        values.tabValue === 1 ?
                                                            <Button
                                                                variant={"contained"}
                                                                color={"primary"}
                                                                fullWidth
                                                                onClick={() => validateForm().then(submitForm)}
                                                            >
                                                                Iniciar sesión
                                                            </Button>
                                                            :
                                                            values.tabValue === 2 ?
                                                                <Button
                                                                    variant={"contained"}
                                                                    color={"primary"}
                                                                    fullWidth
                                                                    onClick={() => validateForm().then(submitForm)}
                                                                >
                                                                    Reenviar verificación
                                                                </Button>
                                                                :
                                                                <Button
                                                                    variant={"contained"}
                                                                    color={"primary"}
                                                                    fullWidth
                                                                    onClick={() => validateForm().then(submitForm)}
                                                                >
                                                                    Enviar restauración
                                                                </Button>


                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Form>
                            </Grid>
                        </Grid>
                        {
                            (values.tabValue === 0 || values.tabValue === 1) &&
                            <Grid
                                item
                                container
                                justify={"center"}
                            >
                                {
                                    values.tabValue === 0 ?
                                        <></>
                                        :
                                        <Link underline={"always"} onClick={() => setValues({...values, tabValue: 3})}>
                                            ¿Has olvidado tu contraseña?
                                        </Link>
                                }
                            </Grid>
                        }
                        {
                            showFooter === true &&
                            <>
                                <Grid
                                    item
                                    container
                                >
                                    <Divider style={{width: '100%'}}/>
                                </Grid>
                                <Grid
                                    item
                                    container
                                >
                                    {
                                        values.tabValue === 0 ?
                                            <Grid
                                                item
                                            >
                                                <Typography variant={"caption"} display={"inline"}>
                                                    Si ya tienes una cuenta previamente creada, solo necesitas acceder a
                                                    ella pulsando&nbsp;
                                                </Typography>
                                                <Link underline={"always"} style={{fontSize: 12}}
                                                      onClick={() => setValues({...values, tabValue: 1})}>
                                                    AQUÍ
                                                </Link>
                                            </Grid>
                                            :
                                            <Grid
                                                item
                                            >
                                                <Typography variant={"caption"} display={"inline"}>
                                                    Si aun no tienes una cuenta creada, crea una haciendo click&nbsp;
                                                </Typography>
                                                <Link underline={"always"} style={{fontSize: 12}}
                                                      onClick={() => setValues({...values, tabValue: 0})}>
                                                    AQUÍ
                                                </Link>
                                            </Grid>
                                    }
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>
            )
            }
            </Formik>
        </Dialog>
    )
}