import axios from 'axios'

/*
    returned value response anatomy:
    response={
        config:{},
        data:{} ó "",
        headers:{},
        request:{},
        status:integer,
        statusText:""
    }
 */
export async function postSimulateRetirement(data){
    try{
        const response=await axios.post('simulate-retirement', data)
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}