import axios from 'axios'
/*
    returned value response anatomy:
    response={
        config:{},
        data:{} ó "",
        headers:{},
        request:{},
        status:integer,
        statusText:""
    }

    EXPECTED DATA
    {
        type:string,
        output_type:"UNIQUE/MONTHLY/ANNUAL",
        name:string (optional),
        amount:number,
        amount_type:"UNIQUE/MONTHLY/ANNUAL",
        term:number,
        term_type:"YEAR/AGE",
        duration:number (optional),
        duration_type:"MONTHLY/ANNUAL",
        expenses:number (optional),
        expenses_type:"UNIQUE/MONTHLY/ANNUAL"(optional),
        stop_savings: boolean (optional),
        receiver:string (optional),
        zip_code:string (optional)

    }
 */
export async function postObjectives(data){
    try{
        const response=await axios.post('objectives', data)
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}
export async function putObjectives(data,id){
    try{
        const response=await axios.put('objectives/'+id, data)
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}
export async function getObjectives(){
    try{
        const response=await axios.get('objectives')
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}
export async function deleteObjectives(id){
    try{
        const response=await axios.delete('objectives/'+id)
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}
export async function getObjectivesActivePlanById(planId){
    try{
        const response=await axios.get('active-plans/'+planId+'/objectives')
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}