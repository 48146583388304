import {createMuiTheme} from "@material-ui/core/styles";
import {colors} from "../colors/colors";
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'

const breakpoints = createBreakpoints({})

/*
* The default spacing property in grid is 8px that means that you multiply the number of that property for 8px
* Taking into account the properties of inveert layout:
* HORIZONTAL LAYOUT DESKTOP GRID
*   *Base Unit 8px
*   *Number of columns 12
*   *Column width auto
*   *Gutter between columns 16px
*/
const themesInveert = createMuiTheme({

    palette: {
        primary: {
            main:colors["--interactive"],
            /* We have to add contrastText to white because there is no other way to put the button text color to white*/
            contrastText: colors["--white"]
        },
    },
    typography: {
        fontFamily: 'DM Sans',
        h1:{
            color:colors["--black"],
            fontSize:"40px",
            lineHeight:"52px",
        },
        h2:{
            color:colors["--black"],
            fontSize:"32px",
            lineHeight:"44px"
        },
        h3:{
            color:colors["--black"],
            fontSize:"20px",
            lineHeight:"32px"
        },
        h4:{
            color:colors["--black"],
            fontSize:"16px",
            lineHeight:"24px"
        },
        h5:{
            color:colors["--black"],
            fontSize:"12px",
            lineHeight:"16px"
        },
        body2:{
            color:colors["--black"],
            fontFamily:"Avenir Book",
            fontSize:"16px",
            lineHeight:"24px"
        },
        body1:{
            color:colors["--black"],
            fontFamily:"Avenir Book",
            fontSize:"20px",
            lineHeight:"28px"
        },
        subtitle1:{
            color:colors["--black"],
            fontFamily:"Avenir Heavy",
            fontSize:"20px",
            lineHeight:"24px"
        },
        subtitle2:{
            color:colors["--black"],
            fontFamily:"Avenir Heavy",
            fontSize:"12px",
            lineHeight:"16px"
        },
        caption:{
            color:colors["--black"],
            fontFamily:"Avenir Book",
            fontSize:"12px",
            lineHeight:"16px"
        },
        button:{
            fontFamily:"DM Sans",
            fontSize:"16px",
            lineHeight:"24px",
            textTransform:"initial",
        },
    },
    overrides: {
        MuiButton: {
            contained:{
                padding:"8px 32px",
                borderRadius:"2px",
                boxShadow: `0px 4px 4px rgba(203, 230, 255, 0.25), 0px 2px 4px ${colors["--interactive"]}`,
                '&:hover': {
                    backgroundColor: colors["--interactive-3"]
                },
                '&$disabled': {
                    backgroundColor: colors["--interactive-2"],
                    color:colors["--white"]
                },
            },
            outlined:{
                fill:"white",
                border:`1px solid ${colors["--black"]}`,
                borderRadius:"2px",
                padding:"8px 40px",
                '&:hover': {
                    fill:colors["--white"],
                    border:`1px solid ${colors["--interactive-1"]}`,
                    color:colors["--interactive-1"],
                },
            }
        },
        /*
            change color of the asterisk in required fields
         */
        MuiFormLabel:{
            root:{
                fontSize:"16px",
                fontFamily:"Avenir Book",
                color:colors["--black-soft"],
                '&.Mui-focused': {
                    color:colors["--black"]
                }
            },
            asterisk:{
                color: colors["--error"]
            }
        },
        /*
            change color and border of input
         */
        MuiFilledInput:{
            input:{
                padding:'25px 12px 6px'
            },
            root:{
                height:50,
                fontSize:"16px",
                borderRadius: "2px",
                border: `solid 1px ${colors["--gray"]}`,
                backgroundColor:colors["--white"],
                '&:hover': {
                    border: `solid 1px ${colors["--interactive"]}`,
                    backgroundColor: colors["--white"],
                },
                '&.Mui-focused':{
                    backgroundColor:colors["--white"],
                    border:`solid 1px ${colors["--interactive-4"]}`,
                    height:50
                }
            },
        },
        /*
            change label style inside input
         */
        MuiInputLabel:{
            root:{
                '&.Mui-focused':{
                    color:colors["--brand-main-100"]
                }
            },
            filled:{
                color:colors["--black-soft"]
            },
            outlined:{
                color:colors["--black-soft"]
            }
        },
        MuiFormControlLabel:{
            root:{
                border: "1px solid transparent",
                background:colors["--background"],
                boxSizing: "border-box",
                borderRadius: "2px",
                marginLeft:"0",
                marginRight:"10px",
                marginTop:"12px",
                marginBottom:"12px",
                '&:hover': {
                    border: `solid 1px ${colors["--interactive"]}`,
                },
            },
            label:{
                marginRight:"12px"
            }
        },
        /*
            tooltip general styles
         */
        MuiTooltip:{
            tooltip:{
                backgroundColor:colors["--white"],
                color:colors["--black"],
                border: `0.4px solid ${colors["--interactive"]}`,
                boxShadow: `0px 4px 8px rgba(219, 225, 231, 0.3), 0px 8px 20px ${colors["--gray-soft"]}`,
                borderRadius: "6px",
            },
            tooltipArrow:{
                background:colors["--white"]
            },
            arrow:{
                width: 17,
                fontSize:16,
                "&::before": {
                    border: `1px solid ${colors["--interactive"]}`,
                    backgroundColor: "white",
                    boxSizing: "border-box"
                }
            }
        },
        MuiFormControl:{
          root:{
              margin:"12px 0"
          }
        },
        /*
            Styles for radio groups inside the muiformcontrol
         */
        MuiFormGroup:{
            root:{
                margin:"0"
            }
        },
        /*
            Styles for textfield
         */
        MuiTextField:{
            root:{
                margin:"12px 0"
            }
        },
        /*
            Styles of date keyboardinput
         */
        MuiInput:{
            input:{
              fontSize:"16px"
            },
            underline:{
                '&:before':{
                    borderBottom:`1px solid ${colors["--gray"]}`
                },
                '&:after':{
                    borderBottom:`1px solid ${colors["--brand-main-100"]}`
                },
                '&:hover:not(.Mui-disabled):before':{
                    borderBottom:`1px solid ${colors["--interactive"]}`
                }
            }
        },
    /*
        Styles of the helpers in the texteAreas
     */
        MuiFormHelperText:{
            contained:{
                marginLeft:"0",
            },
            root:{
                color:colors["--black-soft"]
            }
        },
        MuiStepIcon:{
            root:{
                color:colors["--gray-dark"],
                fontFamily:"DM Sans",
                fontWeight:"bold",
                transform:"scale(0.8)",
                '&.MuiStepIcon-active':{
                    color:colors["--success"]
                },
                '&.MuiStepIcon-completed':{
                    color:colors["--success"]
                },
            }
        },
        MuiStepConnector:{
            vertical:{
                padding:"4px 0"
            },
            lineVertical:{
                borderLeftStyle:"dashed",
                borderLeft: `1px dashed ${colors["--gray"]}`
            }
        },
        MuiDivider:{
            root:{
                background:colors["--gray"]
            }
        },
        MuiLink:{
            root:{
                fontSize:"12px",
                fontFamily:"Avenir Heavy",
                cursor:"pointer"
            }
        },
        MuiBackdrop:{
            root:{
                color: colors["--white"],
            }
        },
        MuiChip:{
            root:{
                backgroundColor: colors["--brand-main-15"],
                color: colors["--brand-main-100"],
                marginRight:"4px"
            },
            outlined:{
                height:"fit-content",
                fontSize:"12px",
                color:colors["--interactive-4"],
                border: `1px solid ${colors["--interactive-4"]}`
            }
        },
        MuiSlider:{
            root:{
                  color:colors["--white"]
            },
            rail:{
                height:"8px",
                borderRadius:"24px",
                backgroundColor:colors["--gray-dark"]
            },
            track:{
                height:"8px",
                backgroundColor:colors["--interactive"],
                borderRadius:"24px",
            },
            thumb:{
                width:"20px",
                height:"20px",
                boxShadow: "0px 0px 2px rgba(235, 241, 243, 0.94), 0px 2px 2px rgba(235, 236, 236, 0.92), 0px 1px 3px rgba(53, 53, 53, 0.96)",
                marginTop:"-7px"
            },
            valueLabel:{
                left:"auto",
                boxShadow: `0px 4px 8px rgba(219, 225, 231, 0.3), 0px 8px 20px ${colors["--gray-soft"]}`,
                borderRadius: "4px"
            }
        },
        MuiStepLabel:{
            label:{
                color: colors["--interactive-3"],
                fontWeight:"800",
                '&.MuiStepLabel-active':{
                    color:colors["--interactive"],
                    fontWeight:"800"
                },
                '&.MuiStepLabel-completed':{
                    color: colors["--interactive-3"],
                    fontWeight:"800"
                },
                '&>div':{
                    color:colors["--black"],
                    fontWeight:"400"
                }
            }
        },
        MuiDialog:{
            root:{
                marginLeft:"auto",
                marginRight:"auto"
            },
            paper:{
                [breakpoints.down("sm")]:{
                    margin:"20px"
                }
            }
        },
        MuiTableContainer:{
            root:{
                '&::-webkit-scrollbar': {
                    width: '8px'
                },
                '&::-webkit-scrollbar-track': {
                    background:colors["--gray-soft"],
                    borderRadius:"24px",
                    margin:"80px 0 10px"
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: colors["--gray"],
                    borderRadius:"24px",
                    height:"36px"
                },

            }
        },
        MuiTable:{
            stickyHeader:{
                padding:"0 12px 0"
            }
        },
        MuiTableCell:{
            body:{
                padding:"8px 16px"
            }
        },
        MuiList:{
            root:{
                outline:"none"
            }
        },
        MuiCheckbox:{
            colorSecondary:{
                paddingLeft:0,
                paddingRight:0,
                color:colors['--brand-main-100'],
                '&.Mui-checked':{
                    color:colors['--brand-main-100'],
                }
            }
        }


    }
})

export default themesInveert