import axios from 'axios'

export async function postFinanbestUser(data){
    try{
        const response=await axios.post('finanbest/user', data)
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}
export async function postFinanbestLogin(data){
    try{
        const response=await axios.post('finanbest/auth/authenticate', data)
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}