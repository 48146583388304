import React from "react";
import {Container, Grid, Divider, Typography, withStyles} from "@material-ui/core";
import {teamStyles} from "./teamStyles";
import HeaderOnboarding from "../../components/landing/LandingHeader";
import {colors} from "../../styles/colors/colors";
import ProfilePhotoSrcChema from '../../assets/team/foto-chema-postigo-inveert-equipo-fundador.jpg'
import ProfilePhotoSrcEduardo from '../../assets/team/foto-eduardo-peralta-inveert-equipo-fundador.jpg'
import ProfilePhotoSrcIgnacio from '../../assets/team/foto-ignacio-peralta-inveert-equipo-fundador.jpg'
import ProfilePhotoSrcAlberto from '../../assets/team/foto-alberto-galdames-inveert-equipo.jpg'
import ProfilePhotoSrcIsabel from '../../assets/team/foto-Isabel-Preto-inveert-equipo.jpg'
import {LinkedIn} from "@material-ui/icons"
import {Responsive} from "../../utils/Responsive";

const TeamDivider = withStyles(theme=>({
    root:{
        background:colors['--brand-main-100'],
        height:4
    }
}))(Divider)

export default function Team(){
    const classes=teamStyles()
    return(
        <div style={{width:"100%", overflow:"hidden"}}>
            <Container className={classes.container}>
                <HeaderOnboarding />
                <Grid
                    container
                    direction={"column"}
                    style={{marginTop:70}}
                    spacing={8}
                >
                    <Grid
                        item
                        container
                        spacing={2}
                        style={{zIndex:2}}
                    >
                        <Grid
                            item
                        >
                            <Typography variant={"h1"}>
                                Cambiaremos la forma de invertir tu dinero para conseguir tus metas
                            </Typography>
                        </Grid>
                        <Grid
                            item
                        >
                            <Typography variant={"body2"}>
                                Inveert es el primer asistente digital de Planificación Financiera, que calcula la cantidad
                                mínima que debes ahorrar cada mes y define un plan de inversión óptimo para alcanzar tus
                                objetivos financieros con mucho menos esfuerzo. Lo hacemos todo de manera fácil, personalizado
                                y con todo el control por tu parte.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        spacing={4}
                    >
                        <Grid
                            item
                            container
                            style={{width:'fit-content'}}
                            lg={6}
                            md={6}
                            sm={12}
                            direction={"column"}
                            alignItems={"center"}
                            spacing={2}
                        >
                            <Grid
                                item
                                style={{width:'100%'}}
                            >
                                <TeamDivider/>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"h2"} style={{color:colors['--brand-main-100']}}>
                                    MISIÓN
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant={"body2"}>
                                    “Que cualquier persona, independientemente de su patrimonio, tenga acceso a un
                                    asesoramiento financiero personalizado de calidad, fácil de entender y que le haga
                                    ver que es posible conseguir aquellos objetivos que realmente le importan. Primando
                                    su interés por encima de la entidad financiera.”
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            style={{width:'fit-content'}}
                            lg={6}
                            md={6}
                            sm={12}
                            direction={"column"}
                            alignItems={"center"}
                            spacing={2}
                        >
                            <Responsive displayIn={'Laptop'}>
                            </Responsive>
                            <Grid
                                item
                                style={{width:'100%',zIndex:2}}
                            >
                                <TeamDivider/>
                            </Grid>
                            <Grid
                                item
                                style={{zIndex:2}}
                            >
                                <Typography variant={"h2"} style={{color:colors['--brand-main-100']}}>
                                    VISIÓN
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                style={{zIndex:2}}
                            >
                                <Typography variant={"body2"}>
                                    “Queremos maximizar las probabilidades de que todo el mundo alcance sus objetivos
                                    vitales. Lo haremos optimizando sus recursos financieros actuales y ayudandoles para
                                    que vean crecer su dinero. Queremos que vivan mejor hoy y mañana.”
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            {/*SECTION2*/}
            <div style={{background:colors['--background']}}>
                <Container className={classes.container}>
                    <Grid
                        container
                        direction={"column"}
                        style={{marginTop:70, background:colors['--background']}}
                        spacing={8}
                    >
                        <Grid
                            item
                            container
                            direction={"column"}
                            spacing={2}
                        >
                            <Grid
                                item
                            >
                                <Typography variant={"h2"}>
                                    ¿Quiénes forman Inveert?
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                lg={8}
                                md={12}
                                sm={12}
                            >
                                <Typography variant={"body2"}>
                                    Inveert cuenta con un equipo promotor con amplia experiencia dentro del sector
                                    financiero, contando con perfiles muy complementarios entre ellos (negocio, producto y tecnología).
                                    El resto del equipo tiene una gran solvencia para poder ofrecer a los usuarios un servicio de calidad.
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            direction={"column"}
                            spacing={2}
                        >
                            <Grid
                                item
                            >
                                <Typography variant={"h3"}>
                                    SOCIOS FUNDADORES
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                container
                                justify={"space-between"}
                                spacing={2}
                            >
                                <Grid
                                    item
                                    container
                                    direction={"column"}
                                    alignItems={"center"}
                                    md={4}
                                    sm={12}
                                    style={{maxWidth:350}}
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        style={{width:'100%'}}
                                    >
                                        <img alt='' src={ProfilePhotoSrcEduardo} style={{width:'inherit'}}/>
                                    </Grid>
                                    <Grid
                                        item
                                    >
                                        <Typography variant='h4' style={{color:colors['--brand-main-100']}}>
                                            Eduardo Peralta
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                    >
                                        <Typography variant='h4' >
                                            CEO
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                    >
                                        <Typography variant='body2' >
                                            Cuenta con más de 12 años de experiencia como Asesor Financiero senior,
                                            trabajando entre Madrid y Londres en Fisher Investments, UBS y Tressis.
                                            Fue socio fundador de la startup betMaster – Social Sports Betting.
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                    >
                                        <LinkedIn fontSize={"large"} style={{cursor:'pointer'}}/>
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    container
                                    direction={"column"}
                                    alignItems={"center"}
                                    md={4}
                                    sm={12}
                                    style={{maxWidth:350}}
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        style={{width:'100%'}}
                                    >
                                        <img alt='' src={ProfilePhotoSrcChema} style={{width:'inherit'}}/>
                                    </Grid>
                                    <Grid
                                        item
                                    >
                                        <Typography variant='h4' style={{color:colors['--brand-main-100']}}>
                                            Chema Postigo
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                    >
                                        <Typography variant='h4' >
                                            COO
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                    >
                                        <Typography variant='body2' >
                                            Ha trabajado más de 30 años en Banca, como Director de Canales online en
                                            entidades como Caja El Monte, Cajasol, Banca Cívica y Caixabank. También es
                                            Socio fundador de la empresa de Consultoría Tecnológica tiThink.
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                    >
                                        <LinkedIn fontSize={"large"} style={{cursor:'pointer'}}/>
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    container
                                    direction={"column"}
                                    alignItems={"center"}
                                    md={4}
                                    sm={12}
                                    style={{maxWidth:350}}
                                    spacing={1}
                                >
                                    <Grid
                                        item
                                        style={{width:'100%'}}
                                    >
                                        <img alt='' src={ProfilePhotoSrcIgnacio} style={{width:'inherit'}}/>
                                    </Grid>
                                    <Grid
                                        item
                                    >
                                        <Typography variant='h4' style={{color:colors['--brand-main-100']}}>
                                            Ignacio Peralta
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                    >
                                        <Typography variant='h4' >
                                            CTO
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                    >
                                        <Typography variant='body2' >
                                            30 años en procesos de transformación tecnológica en empresas de consultoría,
                                            Telcos y como director de tecnología, organización y operaciones en Banca.
                                            Socio fundador de la empresa de Consultoría Tecnológica tiThink.
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                    >
                                        <LinkedIn fontSize={"large"} style={{cursor:'pointer'}}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            {/*SECTION3*/}
            <Container className={classes.container}>
                <Grid
                    container
                    direction={"column"}
                    spacing={2}
                >
                    <Grid
                        item
                        container
                        justify={"flex-end"}
                    >
                        <Typography variant={"h3"}>
                            RESTO DEL EQUIPO
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        justify={"center"}
                        spacing={2}
                    >
                        <Grid
                            item
                            container
                            direction={"column"}
                            alignItems={"center"}
                            md={4}
                            sm={12}
                            style={{maxWidth:350}}
                            spacing={1}
                        >
                            <Grid
                                item
                                style={{width:'100%'}}
                            >
                                <img alt='' src={ProfilePhotoSrcIsabel} style={{width:'inherit'}}/>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant='h4' style={{color:colors['--brand-main-100']}}>
                                    Isabel Preto
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant='h4' >
                                    CMO
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            direction={"column"}
                            alignItems={"center"}
                            md={4}
                            sm={12}
                            style={{maxWidth:350}}
                            spacing={1}
                        >
                            <Grid
                                item
                                style={{width:'100%'}}
                            >
                                <img alt='' src={ProfilePhotoSrcAlberto} style={{width:'inherit'}}/>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant='h4' style={{color:colors['--brand-main-100']}}>
                                    Alberto Galdames
                                </Typography>
                            </Grid>
                            <Grid
                                item
                            >
                                <Typography variant='h4' >
                                    VP Engineering
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}