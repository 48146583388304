import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../styles/colors/colors";

export const globalPositionsStyles=makeStyles(theme=>({
    content_container_box:{
        maxHeight:"100vh",
        overflowX:"hidden",
        width:"calc(100% - 56px)",
        [theme.breakpoints.down("sm")]:{
            width:"100vw",
            maxHeight: "fit-content",
            overflow: "hidden"
        }
    },
    content_container:{
        margin:'24px 100px',
        [theme.breakpoints.down("sm")]:{
            margin:0,
            padding:'24px 20px 38px'
        }
    },
    goal_inner_container:{
        cursor:'pointer',
        width:276,background:colors['--white'],
        marginRight:24,boxShadow: '0px 4px 8px rgba(219, 225, 231, 0.3), 0px 8px 20px #F0F5F9',
        borderRadius:4, padding:'16px',
        [theme.breakpoints.down("sm")]:{
            marginRight:16,
        },
    },
    goal_cards_1_objective_inner_container:{
        '& :hover':{
        },
        [theme.breakpoints.down("sm")]:{
            flex:"0 0 auto",
            width:`${((276*2) + 16 + 24)}px`,
            '& :hover':{
                transform: 'none'
            },
        }
    },
    goal_cards_2_objective_inner_container:{
        '& :hover':{
        },
        [theme.breakpoints.down("sm")]:{
            flex:"0 0 auto",
            width:`${((276*3) + (16*2) + 24)}px`,
            '& :hover':{
                transform: 'none'
            },
        }
    },
    goal_cards_3_objective_inner_container:{
        '& :hover':{
        },
        [theme.breakpoints.down("sm")]:{
            flex:"0 0 auto",
            width:`${((276*4) + (16*3) + 24)}px`,
            '& :hover':{
                transform: 'none'
            },
        }
    },
    goal_card_can_add_4_objectives_container:{
        '& :hover':{
            transform: 'none'
        },
    },
    goal_cards_container:{
        flexDirection:"column",
        justifyContent:"space-between",
        marginTop:"10px",
        [theme.breakpoints.down("sm")]:{
            flexDirection:"row",
            justifyContent:"flex-start",
            width:"calc(100vw - 20px)",
            overflow: "scroll !important"
        }
    },
    initial_distribution_container:{
        width:276,
        background:colors['--white'],
        marginRight:24,
        boxShadow: '0px 4px 8px rgba(219, 225, 231, 0.3), 0px 8px 20px #F0F5F9',
        borderRadius:4,
        padding:'16px',
        [theme.breakpoints.down("sm")]:{
            width:'100%',
            marginRight:0
        }
    },
    keep_plan_updated_container:{
        width:276,
        background:colors['--white'],
        marginRight:24,
        boxShadow: '0px 4px 8px rgba(219, 225, 231, 0.3), 0px 8px 20px #F0F5F9',
        borderRadius:4,
        padding:'16px',
        [theme.breakpoints.down("sm")]:{
            width:'100%',
            marginRight:0,
            marginBottom:24
        }
    },
    friends_container:{
        width:876,
        background:colors['--black'],
        marginRight:24,
        boxShadow: '0px 4px 8px rgba(219, 225, 231, 0.3), 0px 8px 20px #F0F5F9',
        borderRadius:4,
        padding:'16px',
        [theme.breakpoints.down("sm")]:{
            width:'100%',
            marginRight:0,
            marginBottom:48,
            justifyContent: 'center'
        }
    },
    share_with_friends_button:{
        marginTop:12,
        width:'fit-content',
        [theme.breakpoints.down("sm")]:{
            alignSelf:'center',
            marginBottom:12
        }
    }

}))