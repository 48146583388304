import axios from 'axios'

export async function getUsers(){
    try{
        const response=await axios.get('users')
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}

export async function getUserById(id){
    try{
        const response=await axios.get('user/'+id)
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}
