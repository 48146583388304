import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    getActivePlanById,
    getActivePlanPerformanceById,
    getActivePlanTracingById,
    getPlans,
    postPlans
} from "../../services/plans/plans";
import {parse,format} from "date-fns";
import {getObjectives} from "../../services/objectives/objectives";
import {sendFirstObjectiveForm} from "../firstObjective/firstObjectiveSlice";
import {postFinancialWealth} from "../../services/financialWealth/financialWealth";

const initialState={
    loading:false,
    data:null,
    add_goal_questions:null,
    questionIndex:0,
    isGoalQuestionVisible:false,
    isInitialInvestmentDialogVisible:false,
    reloadPlanFlag:0,
    planExists:false,
    isTourDone:false,
    error:false,
    tracing:null,
    performance:null
}
export const postFirstPlanData = createAsyncThunk(
    'plan/postFirstPlanData',
    /*
        The parameters of the funcion are (data, thunkAPI)
        async (userData,thunkAPI)
     */
    async (data,thunkAPI)=>{
        const {firstObjective,riskProfile,user}=data
        const planParsed={
            birthday: format(parse(user.family_situation.birthday,'dd/MM/yyyy',new Date()),'yyyy-MM-dd'),
            initial_investment:firstObjective.data.type==="RETIREMENT"?firstObjective.contribution-firstObjective.contribution_pension_plan:firstObjective.contribution,
            private_pension_plan:firstObjective.contribution_pension_plan?firstObjective.contribution_pension_plan:0,
            gender:user.family_situation.gender,
            risk_profile:riskProfile.data.profile,
            objectives:[{...firstObjective.data,expenses_type:"MONTHLY",expenses:1}],
        }
       return postPlans(planParsed).then(response=>{
                return response.data
            }
        )
    }
)
export const postPlanData = createAsyncThunk(
    'plan/postPlanData',
    /*
        The parameters of the funcion are (data, thunkAPI)
        async (userData,thunkAPI)
     */
    async (data,thunkAPI)=>{
        const {firstObjective,riskProfile,user}=data
        return getObjectives().then(response=>{
            const planParsed={
                birthday: format(parse(user.family_situation.birthday,'dd/MM/yyyy',new Date()),'yyyy-MM-dd'),
                initial_investment:firstObjective?.contribution_pension_plan?firstObjective.contribution-firstObjective.contribution_pension_plan:firstObjective.contribution,
                private_pension_plan:firstObjective?.contribution_pension_plan?firstObjective.contribution_pension_plan:0,
                gender:user.family_situation.gender,
                risk_profile:riskProfile.data.profile,
                objectives:response.data,
            }
            return postPlans(planParsed).then(response=>{
                return response.data
            }
            )
        })
    }
)

export const getPlanData = createAsyncThunk(
    'plan/getPlanData',
    /*
        The parameters of the funcion are (data, thunkAPI)
        async (userData,thunkAPI)
     */
    async (thunkAPI)=>{
        return getPlans().then(response=> {
            if(response.data.is_active===true){
                return getActivePlanById(response.data.id).then(response=>response.data)
            }else{
                return response.data
            }
        })
    }
)

export const getTracingData = createAsyncThunk(
    'plan/getTracingData',
    /*
        The parameters of the funcion are (data, thunkAPI)
        async (userData,thunkAPI)
     */
    async (data,thunkAPI)=>{
        const id = thunkAPI.getState().plan.data.id
        return getActivePlanTracingById(id).then(response=> response.data)
    }
)

export const getPerformanceData = createAsyncThunk(
    'plan/getPerformanceData',
    /*
        The parameters of the funcion are (data, thunkAPI)
        async (userData,thunkAPI)
     */
    async (idObjective,thunkAPI)=>{
        const idPlan = thunkAPI.getState().plan.data.id
        return getActivePlanPerformanceById(idPlan,idObjective).then(response=> response.data)
    }
)

export const setInitialInvestment = createAsyncThunk(
    'plan/setInitialInvestment',
    /*
        The parameters of the funcion are (data, thunkAPI)
        async (userData,thunkAPI)
     */
    async (data,thunkAPI)=>{
        const {firstObjective}=data
        const initial_investment=firstObjective.contribution_pension_plan>0?firstObjective.contribution-firstObjective.contribution_pension_plan:firstObjective.contribution
        const private_pension_plan=firstObjective.contribution_pension_plan>0?firstObjective.contribution_pension_plan:0
        return postFinancialWealth({initial_investment:initial_investment,private_pension_plan:private_pension_plan}).then(response=>response.data)
    }
)
const planSlice=createSlice({
    name:"plan",
    initialState:initialState,
    reducers:{
        setIsTourDone(state,action){
          return{...state,isTourDone: action.payload}
        },
        selectGoal(state,action){
            return{...state,add_goal_questions: {type:action.payload}, questionIndex:0}
        },
        resetPlanData(state,action){
            return{...initialState}
        },
        addGoalPrevQuestion(state, action) {
            state.questionIndex -= 1;
        },
        addGoalNextQuestion(state, action) {
            state.questionIndex += 1;
        },
        setIsGoalQuestionVisible(state,action){
            state.isGoalQuestionVisible=action.payload
        },
        setIsInitialInvestmentDialogVisible(state,action){
            state.isInitialInvestmentDialogVisible=action.payload
        },
        setPlanError(state,action){
            state.error=action.payload
        },
        resetPerformance(state,action){
            state.performance=null
        }
    },
    extraReducers: {
        [postFirstPlanData.pending]:(state)=>{
            state.loading=true
        },
        [postFirstPlanData.fulfilled]:(state,action)=>{
            state.data=action.payload
            if(action.payload.plan.length===0){
                state.planExists=false
            }else{
                state.planExists=true
            }
            state.loading=false
        },
        [postFirstPlanData.rejected]:(state)=>{
            state.loading=false
            state.planExists=false
        },
        [postPlanData.pending]:(state)=>{
            state.loading=true
            state.isInitialInvestmentDialogVisible=false
        },
        [postPlanData.fulfilled]:(state,action)=>{
            state.data=action.payload
            state.loading=false
        },
        [postPlanData.rejected]:(state)=>{
            state.loading=false
        },
        [getPlanData.pending]:(state)=>{
            state.loading=true
            state.isInitialInvestmentDialogVisible=false
        },
        [getPlanData.fulfilled]:(state,action)=>{
            state.data=action.payload
            if(action.payload.plan.length===0){
                state.planExists=false
            }else{
                state.planExists=true
            }
            state.loading=false
            state.error=false
        },
        [getPlanData.rejected]:(state)=>{
            state.loading=false
            state.planExists=false
            state.error=true
        },
        [getTracingData.pending]:(state)=>{
            state.loading=true
        },
        [getTracingData.fulfilled]:(state,action)=>{
            state.tracing=action.payload
            state.loading=false
            state.error=false
        },
        [getTracingData.rejected]:(state)=>{
            state.loading=false
            state.error=true
        },
        [getPerformanceData.pending]:(state)=>{
            state.loading=true
        },
        [getPerformanceData.fulfilled]:(state,action)=>{
            state.performance=Object.keys(action.payload)?.map((performanceKey, index) => {
                const performanceObject = action.payload[performanceKey]
                return {
                    month_number: index,
                    name: index === 0 ? 0 : Math.trunc((index / 12)),
                    max_performance: [performanceObject.expected_performance,performanceObject.max_performance],
                    min_performance: [performanceObject.expected_performance,performanceObject.min_performance],
                    expected_performance: performanceObject.expected_performance,
                    real: performanceObject.real
                }
            })
            state.loading=false
            state.error=false
        },
        [getPerformanceData.rejected]:(state)=>{
            state.loading=false
            state.error=true
        },
        [sendFirstObjectiveForm.pending]:(state)=>{
            state.loading=true
        },
        [sendFirstObjectiveForm.fulfilled]:(state)=>{
            state.loading=false
        },
        [sendFirstObjectiveForm.rejected]:(state)=>{
            state.loading=false
        },
        [setInitialInvestment.pending]:(state)=>{
            state.loading=true
            state.isInitialInvestmentDialogVisible=false
        },
        [setInitialInvestment.fulfilled]:(state,action)=>{
            /*
            setAmplitudeUserProperties({initial_investment:action.payload.initial_investment,private_pension_plan:action.payload.private_pension_plan})

             */
            window.dataLayer.push({
                'events':'setInitialInvestment',
                'initial_investment':action.payload.initial_investment,
                'private_pension_plan':action.payload.private_pension_plan
            })
            state.loading=false
            if(state.reloadPlanFlag===0){
                state.reloadPlanFlag=1
            }else{
                state.reloadPlanFlag=0
            }
        },
        [setInitialInvestment.rejected]:(state)=>{
            state.loading=false
        },
    }
})

export const {
    selectGoal,
    resetPlanData,
    setIsGoalQuestionVisible,
    setIsInitialInvestmentDialogVisible,
    setIsTourDone,
    setPlanError,
    resetPerformance
}=planSlice.actions
export const selectPlan=(state)=>state.plan
export const selectPlanExists=(state)=>state.plan.planExists
export default planSlice.reducer