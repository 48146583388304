import React, {useEffect} from "react";
import {useHistory, useLocation} from "react-router-dom";
import {Dialog, Tab, Tabs, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {logotypeSrc} from "../../utils/logotype";
import {ReactComponent as Correct} from '../../assets/icons/24px/check-filled.svg'
import {colors} from "../../styles/colors/colors";

const VerificationStyles=makeStyles(theme=>({
    container:{
        padding:35,
        [theme.breakpoints.down("sm")]:{
            padding:20
        }
    }
}))

async function timerRedirection(data, history){
    setTimeout(()=> {
        if(data.status==='200'){
            history.push('/login')
        }else{
            history.push('/')
        }
    }, 5000);
}

export default function Verification(){
    const history=useHistory()
    const data=Object.fromEntries(new URLSearchParams(useLocation().search))
    const logoSrc=logotypeSrc()
    const classes=VerificationStyles()
    useEffect(() => {
        timerRedirection(data,history)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return(
        <Dialog open={true}>
            <Grid
                container
                direction={"column"}
                className={classes.container}
            >
                <Grid
                    item
                    container
                    direction={"column"}
                    alignItems={"center"}
                    spacing={2}
                >
                    <Grid
                        item
                    >
                        <img alt='' src={logoSrc} style={
                            {width: 153}
                        }
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        spacing={2}
                    >
                        <Grid
                            item
                            container
                            justify={"center"}
                        >
                            <Tabs
                                value={0}
                                indicatorColor="primary"
                                textColor="primary"
                                style={{minWidth:'inherit'}}
                            >
                                {
                                    data.status==="200"?
                                        <Tab label="VERIFICACIÓN" />
                                        :
                                        <Tab label="ERROR" />
                                }
                            </Tabs>

                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                    >
                        <Typography variant={"caption"} style={{color:data.status==="200"?'inherit':colors['--error']}}>
                            {data.msg}
                        </Typography>
                    </Grid>
                    {
                        data.status==="200"?
                            <Grid
                                item
                                container
                                justify={"center"}
                            >
                                <Correct fill={colors['--interactive-4']} style={{width:60, height:60}}/>
                            </Grid>
                            :
                            <Grid
                                item
                                container
                                justify={"center"}
                            >
                            </Grid>
                    }
                    <Grid
                        item
                        container
                        justify={"center"}
                    >
                        <Typography variant={"caption"} >
                            En 5 segundos será redirigido...
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    )
}