import {createSlice} from "@reduxjs/toolkit";

const apiCallsSlice = createSlice({
    name:'api',
    initialState:{
        response:{}
    },
    reducers:{
        getResponse(state,action){
            state.response=action.payload
        }
    }
})

export const {getResponse} = apiCallsSlice.actions
export const selectApiCalls = (state) => state.api
export default apiCallsSlice.reducer