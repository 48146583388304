import axios from 'axios'

export async function getRecommendation(data){
    const financialUrl=data.with_financial_service===true?"?with_financial_service=true":''
    try {
        const response=await axios.get('plans/'+data.planId+'/recommendation'+financialUrl)
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}

export async function getRecommendationOfActivePlanById(data){
    try {
        const response=await axios.get('active-plans/'+data.planId+'/objectives/'+data.objectiveId+'/investment')
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}