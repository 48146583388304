import axios from "axios";

export async function getAuthUser(){
    try {
        const response=await axios.get('auth/user')
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}

export async function login(data){
    try {
        const response=await axios.post('auth/login',data)
        return {data:response.data,url:response.config.url}
    }catch (e){
        return Promise.reject(e)
    }
}

export async function sendChangePasswordEmail(data){
    try {
        const response=await axios.post('auth/remember_password',data)
        return {data:response.data,url:response.config.url}
    }catch (e){
        return Promise.reject(e)
    }
}
export async function changePassword(data){
    try {
        const response=await axios.post('auth/restore-password',data)
        return {data:response.data,url:response.config.url}
    }catch (e){
        return Promise.reject(e)
    }
}
export async function isValidRestorePassword(data){
    try {
        const response=await axios.get('auth/restore-password/'+data)
        return {data:response.data,url:response.config.url}
    }catch (e){
        return e.response
    }
}

export async function putUser(data){
    try {
        const response=await axios.put('user',data)
        return {data:response.data,url:response.config.url}
    }catch (e){
        return Promise.reject(e)
    }
}

export async function getResendVerificationEmail(){
    try {
        const response=await axios.get('auth/email/resend')
        return {data:response.data,url:response.config.url}
    }catch (e){
        return Promise.reject(e)
    }
}
