import React from "react";
import {
    Bar, ResponsiveContainer, BarChart, XAxis, YAxis, Tooltip,Rectangle
} from 'recharts';
import {colors} from "../../styles/colors/colors";
import {Grid, Typography} from "@material-ui/core";
import NumberFormat from "react-number-format";
import {generalStyles} from "../../styles/general/generalStyles";
import {useSelector} from "react-redux";
import {selectConfig} from "../../redux/config/configSlice";
/*
    props={data,height}
 */
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
function ChartBarSavingGoal (props) {
    const config=useSelector(selectConfig)
    const generalClasses=generalStyles()
    const {data,height,isXaxisHiden,color,goalNumber}=props
    const CustomCursor = props => {
        const { x, y, width, height, stroke, fill } = props;
        return <Rectangle fill={fill} stroke={stroke} x={x} y={y} width={width} height={height} />;
    };
    const CustomTooltip = (e) => {
        const saving1=e.payload[0]?.payload?.saving
        const saving2=e.payload[0]?.payload?.savingGoal1?e.payload[0]?.payload?.savingGoal1:0
        const saving3=e.payload[0]?.payload?.savingGoal2?e.payload[0]?.payload?.savingGoal2:0
        const saving4=e.payload[0]?.payload?.savingGoal3?e.payload[0]?.payload?.savingGoal3:0
        const totalSaving=saving1+saving2+saving3+saving4
            return (
                <>
                    {totalSaving !== 0 &&
                    <Grid
                        item
                        container
                        className={generalClasses.width_fit_content}
                        style={{
                            backgroundColor:"white",
                            color:"black",
                            border: "0.4px solid #067DF8",
                            boxShadow: "0px 4px 8px rgba(219, 225, 231, 0.3), 0px 8px 20px #F0F5F9",
                            borderRadius: "6px",
                            padding:"11px 13px ",
                        }}
                        direction={"column"}
                    >
                        <Typography variant={"caption"}>
                            <Typography variant={"h5"} style={{color:"#5A5C5E",marginBottom:"2px"}}>
                                {totalSaving>0?'Aportaciones ':'Retiros '}
                                del año {e.payload[0]?.payload?.name}
                            </Typography>
                            {
                                e.payload.map(value=>{
                                    let component
                                    if (value.payload[value.name]!==0){
                                        component=<Grid item container style={{margin:"2px 0"}}>
                                            <Grid
                                                item
                                                container
                                                alignItems={"center"}
                                            >
                                                <Grid
                                                    item
                                                    style={{height:"8px",width:"8px",background: `${value?.color}`,borderRadius: "2px"}}
                                                />
                                                <Typography variant={"caption"}>
                                                    &nbsp;{value?.payload?.goalNames[value?.name]}:&nbsp;
                                                    <Typography variant={"caption"} style={{fontFamily:"Avenir Heavy"}}>
                                                        <NumberFormat
                                                            value={Math.abs(value.payload[value.name])}
                                                            thousandSeparator={config.thousand_separator}
                                                            decimalSeparator={config.decimal_separator}
                                                            decimalScale={0}
                                                            displayType={"text"}
                                                            suffix={config.currency_suffix}
                                                            prefix={config.currency_prefix}
                                                        />
                                                        /año
                                                    </Typography>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    }
                                    return component
                                })
                            }
                        </Typography>
                    </Grid>
                    }
                </>
            );
    }
    return(
        <ResponsiveContainer width={"100%"} height={height}>
            <BarChart
                data={data}
                margin={{
                    top: 10, right: 30, left: 0, bottom: 0,
                }}
            >
                <Tooltip
                    wrapperStyle={{zIndex: 1000}}
                    content={<CustomTooltip/>}
                    cursor={<CustomCursor/>}
                />
                {isXaxisHiden!==true&&
                <XAxis
                    dataKey={"name"}
                    tickLine={false}
                    fontFamily={"Avenir Book"}
                    fontSize={"10px"}
                />
                }
                <YAxis
                    hide={true}
                    type={"number"}
                    /*
                        dont mantain the scale between two charts
                        domain={[dataMin => (dataMinValue),dataMax => (dataMaxValue)]}
                     */
                />

                <Bar stackId={1} type="monotone" dataKey="saving" stroke={color} fillOpacity={1} fill={color} radius={data.length>30?[2,2,0,0]:[8,8,0,0]}>
                    {/*
                        Color negative with different color
                        {
                        data.map((entry, index) => {
                            return <Cell key={`cell-${index}`} fill={entry.saving > 0 ? "#1982B6":"#074C7F" } stroke={entry.saving > 0 ? "#1982B6":"#074C7F" }/>
                        })
                    }
                    */}
                </Bar>
                {
                    goalNumber>1&&
                    <Bar stackId={1} type="monotone" dataKey="savingGoal2" stroke={colors['--goal-secondary-100']} fillOpacity={1} fill={colors['--goal-secondary-100']} radius={data.length>30?[2,2,0,0]:[8,8,0,0]}/>
                }
                {
                    goalNumber>2&&
                    <Bar stackId={1} type="monotone" dataKey="savingGoal3" stroke={colors['--goal-tertiary-100']} fillOpacity={1} fill={colors['--goal-tertiary-100']} radius={data.length>30?[2,2,0,0]:[8,8,0,0]}/>
                }
                {
                    goalNumber>3&&
                    <Bar stackId={1} type="monotone" dataKey="savingGoal4" stroke={colors['--goal-quaternary-100']} fillOpacity={1} fill={colors['--goal-quaternary-100']} radius={data.length>30?[2,2,0,0]:[8,8,0,0]}/>
                }
            </BarChart>
        </ResponsiveContainer>
    )
}
export default ChartBarSavingGoal