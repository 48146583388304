import React from "react";
import {
    AreaChart, Area, ResponsiveContainer, Tooltip,
} from 'recharts';
import {Grid, Typography} from "@material-ui/core";
import NumberFormat from "react-number-format";
import {generalStyles} from "../../styles/general/generalStyles";
import {useSelector} from "react-redux";
import {selectConfig} from "../../redux/config/configSlice";
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
function ChartAreaPatrimony (props) {
    const config=useSelector(selectConfig)
    const generalClasses=generalStyles()
    const {data}=props
    const CustomTooltip = (e) => {
        return (
            <Grid
                item
                container
                className={generalClasses.width_fit_content}
                style={{
                    backgroundColor:"white",
                    color:"black",
                    border: "0.4px solid #067DF8",
                    boxShadow: "0px 4px 8px rgba(219, 225, 231, 0.3), 0px 8px 20px #F0F5F9",
                    borderRadius: "6px",
                    padding:"11px 13px ",
                }}
                direction={"column"}
            >
                <Typography variant={"caption"}>
                    <Typography variant={"h5"} style={{color:"#5A5C5E",marginBottom:"2px"}}>
                        Año {e.payload[0]?.payload.name}
                    </Typography>
                    <Grid item container style={{margin:"2px 0"}}>
                        <Grid
                            item
                            container
                            alignItems={"center"}
                        >
                            <Grid
                                item
                                style={{height:"8px",width:"8px",background: `${e.payload[0]?.color}`,borderRadius: "2px"}}
                            />
                            <Typography variant={"caption"}>
                                &nbsp;Tu patrimonio:&nbsp;
                                <Typography variant={"caption"} style={{fontFamily:"Avenir Heavy"}}>
                                    <NumberFormat
                                        value={e.payload[0]?.payload.patrimony}
                                        thousandSeparator={config.thousand_separator}
                                        decimalSeparator={config.decimal_separator}
                                        decimalScale={0}
                                        displayType={"text"}
                                        suffix={config.currency_suffix}
                                        prefix={config.currency_prefix}
                                    />
                                </Typography>
                            </Typography>
                        </Grid>
                    </Grid>
                </Typography>
            </Grid>
        );
    }
    return(
        <ResponsiveContainer width={"100%"} height={240}>
            <AreaChart
                data={data}
                margin={{
                    top: 10, right: 30, left: 0, bottom: 0,
                }}
            >
                <Tooltip
                    wrapperStyle={{zIndex: 1000}}
                    content={<CustomTooltip/>}
                    />
                {/*
                <XAxis
                    dataKey="name"
                    interval={36}
                    fontFamily={"Avenir Book"}
                    fontSize={"10px"}
                    tickSize={0}
                    dy={10}
                    tick={{fill:"#000306"}}

                />
                */}
                <Area type="monotone" dataKey="patrimony" stackId="1" stroke="#00B4C1" fill="#00B4C1" />
            </AreaChart>
        </ResponsiveContainer>
    )
}
export default ChartAreaPatrimony