import axios from 'axios'

/*
    returned value response anatomy:
    response={
        config:{},
        data:{} ó "",
        headers:{},
        request:{},
        status:integer,
        statusText:""
    }
 */
export async function postBusinessIncome(data){
    try{
        const response=await axios.post('business-income', data)
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}

export async function getBusinessIncome(data){
    try{
        const response=await axios.get('business-income',data)
        return response.data
    }catch (e){

    }
}

export async function putBusinessIncome(data,id){
    try{
        const response=await axios.put('business-income/'+id,data)
        return {data:response.data,url:response.config.url}
    }catch (e){
        return e.response
    }
}