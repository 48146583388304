import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../styles/colors/colors";

export const planStyles= makeStyles(theme=>({
    title_typography:{
        fontSize:"20px"
    },
        content_container_box:{
            maxHeight:"100vh",
            overflow:"auto",
            width:"calc(100% - 56px)",
            [theme.breakpoints.down("sm")]:{
                width:"100%",
                overflowX:'hidden'
            }
        },
    plan_info_container:{
        marginLeft:"25px",
        width:"275px",
        [theme.breakpoints.down("sm")]:{
            marginLeft:"0",
            width:"calc(100vw - 20px)",
            overflowX:"scroll"
        }

    },
    plan_info_inner_container:{
        [theme.breakpoints.down("sm")]:{

        }
    },
    content_container_grid:{
        padding:"90px 0 0 100px",
        [theme.breakpoints.down("sm")]:{
            padding:"90px 20px 38px"
        }
    },
    goals_and_info_cards_container:{
            flexDirection:"row",
        [theme.breakpoints.down("sm")]:{
            flexDirection:"column-reverse"
        }

    },
    plan_apportations_container:{
        maxWidth:"275px",
        maxHeight:"232px",
        backgroundColor:colors["--white"],
        boxShadow: `0px 4px 8px rgba(219, 225, 231, 0.3), 0px 8px 20px ${colors["--gray-soft"]}`,
        margin:"12px 0 0 -25px",
        [theme.breakpoints.down("sm")]:{
            maxWidth:"320px",
            margin:"0"
        }
    },
    goal_cards_container:{
            flexDirection:"column",
        justifyContent:"space-between",
        marginTop:"10px",
        height:"fit-content",
        width:"fit-content",
        [theme.breakpoints.down("sm")]:{
            flexDirection:"row",
            justifyContent:"flex-start",
            width:"calc(100vw - 20px)",
            overflow: "scroll !important"
        }
    },
    goal_cards_1_objective_inner_container:{
        transition: 'all .5s',
        transform: 'scale(1)',
        width:"min-content",
        cursor:'pointer',
        '& :hover':{
            transition: 'all 0.5s',
            transform: 'scale(1.025)'
        },
        [theme.breakpoints.down("sm")]:{
            flex:"0 0 auto",
            width:`${((264*2) + 16 + 24)}px`,
            '& :hover':{
                transform: 'none'
            },
        }
    },
    goal_cards_2_objective_inner_container:{
        transition: 'all .5s',
        transform: 'scale(1)',
        width:"min-content",
        cursor:'pointer',
        '& :hover':{
            transition: 'all 0.5s',
            transform: 'scale(1.025)'
        },
        [theme.breakpoints.down("sm")]:{
            flex:"0 0 auto",
            width:`${((264*3) + (16*2) + 24)}px`,
            '& :hover':{
                transform: 'none'
            },
        }
    },
    goal_cards_3_objective_inner_container:{
        transition: 'all .5s',
        transform: 'scale(1)',
        width:"min-content",
        cursor:'pointer',
        '& :hover':{
            transition: 'all 0.5s',
            transform: 'scale(1.025)'
        },
        [theme.breakpoints.down("sm")]:{
            flex:"0 0 auto",
            width:`${((264*4) + (16*3) + 24)}px`,
            '& :hover':{
                transform: 'none'
            },
        }
    },
    goal_card_can_add_4_objectives_container:{
        '& :hover':{
            transform: 'none'
        },
    },
    single_goal_card_container:{
        boxShadow: `0px 4px 8px rgba(219, 225, 231, 0.3), 0px 8px 20px ${colors["--gray-soft"]}`,
        borderRadius:"2px",
        background: colors["--white"],
        height:"108px",
        width:"576px",
        padding:"12px 16px",
        marginBottom:"12px",
        '& :hover':{
            transform: 'none'
        },
        [theme.breakpoints.down("sm")]:{
            width:"264px",
            height:"176px",
            marginRight:"16px"
        }
    },
    goal_colchon_title_container:{
        [theme.breakpoints.down("sm")]:{
            width:"179px"
        }
    },
    with_and_without_inveert_charts_container:{
        boxShadow: `0px 4px 8px rgba(219, 225, 231, 0.3), 0px 8px 20px ${colors["--gray-soft"]}`,
        borderRadius: "2px",
        background:colors["--white"],
        padding:"24px 55px 36px 26px",
        margin:"24px 0 40px",
        width:"876px",
        [theme.breakpoints.down("sm")]:{
            width:"320px",
            padding:"0",
            background:"none",
            boxShadow:"none"
        }
    },
    recomendacion_button_container:{
        margin:"35px 32px 0 0",
        [theme.breakpoints.down("sm")]:{
            margin:"29px 0 38px"
        }
    },
    menu_bar_box:{
        overflow:"auto",
        boxShadow: `0px 4px 8px rgba(219, 225, 231, 0.3), 0px 8px 20px ${colors["--gray-soft"]}`,
        zIndex:"50",
        [theme.breakpoints.down("sm")]:{
            position:"fixed",
            overflow:"auto",
            bottom:"0",
            boxShadow: "0px -4px 8px rgba(43, 43, 44, 0.06), 0px -8px 20px rgba(43, 43, 44, 0.05)",
        }
    },
    edit_initial_investment_container:{
        width:930,
        padding:"40px 0 20px 120px",
        [theme.breakpoints.down("sm")]:{
            width:'inherit',
            padding:"20px"
        }
    },
    aportacion_mensual_container:{
        backgroundColor:'#E5ECF2',
        boxShadow: `0px 4px 8px rgba(219, 225, 231, 0.3), 0px 8px 20px ${colors["--gray-soft"]}`,
        margin:'10px 0 0 -25px',
        width:"275px",
        [theme.breakpoints.down("sm")]:{
            margin:"0 24px 14px 0",
            width:"264px",
        }
    },
    tour_dialog:{
        maxWidth:776
    },
    tour_dialog_inner_container:{
        padding:'48px 60px 60px',
        [theme.breakpoints.down("sm")]:{
            padding:16
        }
    },
    tour_dialog_typography:{
        [theme.breakpoints.down("sm")]:{
            textAlign:'center'
        }
    }

    })
)