import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {
    deleteObjectives,
    getObjectives,
    getObjectivesActivePlanById,
    postObjectives,
    putObjectives
} from "../../services/objectives/objectives";
import {postFinancialWealth} from "../../services/financialWealth/financialWealth";

const initialState={
    loading:false,
    data:[],
    error:false
}

export const modifyObjective = createAsyncThunk(
    'objectives/modifyObjective',
    /*
        The parameters of the funcion are (data, thunkAPI)
        async (userData,thunkAPI)
     */
    async (objective,thunkAPI)=>{
        return putObjectives(objective.data,objective.id).then(()=>{
            return getObjectives().then(response=>response.data)
        })
    }
)

export const postObjective = createAsyncThunk(
    'objectives/postObjective',
    /*
        The parameters of the funcion are (data, thunkAPI)
        async (userData,thunkAPI)
     */
    async (objective,thunkAPI)=>{
        return postObjectives(objective).then(()=>{
            return getObjectives().then(response=>response.data)
        })
    }
)

export const deleteObjective = createAsyncThunk(
    'objectives/deleteObjective',
    /*
        The parameters of the funcion are (data, thunkAPI)
        async (userData,thunkAPI)
     */
    async (id,thunkAPI)=>{
        const {plan}=thunkAPI.getState()
        let initial_investment
        let private_pension_plan
        const deletePension=thunkAPI.getState().objectives.data.reduce((deletePension,objective)=>{
            if(objective.id===id && objective.type==="RETIREMENT"){
                deletePension=true
            }
            return deletePension
        },false)
        if(deletePension===true){
            initial_investment=plan.data?.initial_investment
            private_pension_plan=0
        }else{
            initial_investment=plan.data.initial_investment
            private_pension_plan=plan.data.private_pension_plan
        }
        const responsePostFinancialWealth=postFinancialWealth({initial_investment:initial_investment,private_pension_plan:private_pension_plan})
        const responseDeletedObjective=deleteObjectives(id)
        return Promise.all([responseDeletedObjective,responsePostFinancialWealth]).then(responsesData=>{
            /*
                we return a number of objects equal to the number of petitions with the name of the url we are calling
                because we name in the initial state our parameters as the names on the endpoints
             */
            getObjectives().then(response=> {
                /*
                setAmplitudeUserProperties({
                    goals:response.data.filter(objective=>objective.is_active).map(objective=>objective.type)
                })

                 */
                window.dataLayer.push({
                    'events':'setInitialInvestment',
                    'goals':response.data.filter(objective=>objective.is_active).map(objective=>objective.type),
                    'initial_investment':initial_investment,
                    'private_pension_plan':private_pension_plan
                })
            })
            return responsesData.reduce((obj,responseData)=>{
                obj[responseData.url.replace(/-/g,"_")]=responseData.data
                return obj
            },{})
        })
    }
)

export const getAllObjectives = createAsyncThunk(
    'objectives/getAllObjectives',
    /*
        The parameters of the funcion are (data, thunkAPI)
        async (userData,thunkAPI)
     */
    async (data,thunkAPI)=>{
        const plan=thunkAPI.getState().plan
        let response
        if(plan.data?.is_active===true){
            response=getObjectivesActivePlanById(plan.data.id).then(response=>response.data)
        }else{
            response = getObjectives().then(response=>response.data)
        }
        return response
    }
)

const objectiveSlice = createSlice({
    name:'objectives',
    initialState:initialState,
    reducers:{
        addObjective(state,action){
            state.data.push({type:action.payload})
        },
        addFirstObjective(state,action){
            state.data=[action.payload]
        },
        setObjectivesError(state,action){
            state.error=action.payload
        }
    },
    extraReducers:{
        [deleteObjective.pending]:(state,action)=>{
            state.loading=true
        },
        [deleteObjective.fulfilled]:(state,action)=>{
            return {...state,loading:false,data:state.data.filter(objective=>objective.id!==action.meta.arg)}
        },
        [deleteObjective.rejected]:(state,action)=>{
            state.loading=false
        },
        [modifyObjective.pending]:(state,action)=>{
            state.loading=true
        },
        [modifyObjective.fulfilled]:(state,action)=>{
            return {...state,loading:false,data:action.payload}
        },
        [modifyObjective.rejected]:(state,action)=>{
            state.loading=false
        },
        [getAllObjectives.pending]:(state,action)=>{
            state.loading=true
        },
        [getAllObjectives.fulfilled]:(state,action)=>{
            return {...state,loading:false,data:action.payload, error: false}
        },
        [getAllObjectives.rejected]:(state,action)=>{
            state.loading=false
            state.error=true
        },
        [postObjective.pending]:(state,action)=>{
            state.loading=true
        },
        [postObjective.fulfilled]:(state,action)=>{
            return {...state,loading:false,data:action.payload}
        },
        [postObjective.rejected]:(state,action)=>{
            state.loading=false
        }
    }
})

export const {
    addObjective,
    addFirstObjective,
    setObjectivesError
} = objectiveSlice.actions
export const selectObjectives = (state) => state.objectives
export default objectiveSlice.reducer