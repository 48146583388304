export function configAxiosHelpersParseResponse(response){
    const{status,statusText,config}=response
    return{
        status:status,
        statusText:statusText,
        config:{
            baseURL:config.baseURL,
            data:config.data,
            headers:config.headers,
            method:config.method,
            url:config.url
        }
    }
}