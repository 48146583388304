import React, {useEffect} from "react";
import {useHistory, useParams} from "react-router-dom";

export default function SaveTokenAfterRedirection(){
    const{token}=useParams()
    const history=useHistory()
    useEffect(() => {
        localStorage.setItem('token', token)
        history.push('/plan')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return(
        <div>

        </div>
    )
}