import React from "react";
import {Button, FormControl, Grid, MenuItem, Typography} from "@material-ui/core";
import {sendUserForm} from "../../redux/user/userSlice";
import {onboardingAccountFormHelpersParseFormData} from "../onboardingAccount/form/OnboardingAccountFormHelpers";
import {Field, Form, Formik} from "formik";
import {Select, TextField} from "formik-material-ui";
import {KeyboardDatePicker} from "formik-material-ui-pickers";
import {parse, sub} from "date-fns";
import InputRadioGroupForm from "../input/InputRadioGroupForm";
import NumberFormatCustom from "../number/NumberFormatCustom";
import {useDispatch, useSelector} from "react-redux";
import {selectConfig} from "../../redux/config/configSlice";
import * as Yup from "yup";
import {makeStyles} from "@material-ui/core/styles";
import {colors} from "../../styles/colors/colors";
import SingleIcon from "../../assets/icons/40px/single.png";
import CoupleIcon from "../../assets/icons/40px/couple.png";
import InputLabel from "@material-ui/core/InputLabel";
const onboardingAccountFormStyles=makeStyles(theme=>({
    buttons_container:{
        margin:"42px 0",
        width: "376px",
        [theme.breakpoints.down("sm")]:{
            background:colors["--white"],
            margin: "0",
            padding:"24px 0 20px",
            position:"fixed",
            bottom:"0",
            justifyContent:"center",
            width: "320px",
            zIndex:"90"
        }
    },
    form_container:{
        width:'100%',
        [theme.breakpoints.down("sm")]:{
            paddingBottom:"70px"
        }
    }
}))

const radioGroup2={
    title:"Tipo de plan",
    name:"partner",
    showRequiredSign:false,
    rowDirection:true,
    items:[
        {
            value:"no-partner",
            label:"Individual",
            imageSrc:SingleIcon
        },
        {
            value: "partner",
            label: "En pareja",
            imageSrc:CoupleIcon
        }
    ]
}
const radioGroup3={
    title:"¿Cuántos hijos tienes a tu cargo?",
    name:"sons",
    showRequiredSign:false,
    rowDirection:true,
    items:[
        {
            value:"0",
            label:"0",
            imageSrc:null
        },
        {
            value:"1",
            label:"1",
            imageSrc:null
        },
        {
            value:"2",
            label:"2",
            imageSrc:null
        },
        {
            value:"3",
            label:"3",
            imageSrc:null
        },
        {
            value:"4",
            label:">3",
            imageSrc:null
        }
    ]
}
export default function UserDataMainForm(props){
    const config=useSelector(selectConfig)
    const classes=onboardingAccountFormStyles()
    const{user}=props
    const dispatch=useDispatch()
    const validationSchema=Yup.object({
        name: Yup.string()
            .nullable()
            .max(20,"El nombre no puede contener mas de 20 letras")
            .required("Nombre requerido"),
        date: Yup.date()
            .nullable()
            .min(sub(Date.now(), {years: 90}),"No puedes tener mas de 90 años")
            .max(sub(Date.now(),{years:18}),"Debes ser mayor de edad")
            .required("Fecha de nacimiento requerida"),
        gender: Yup.string()
            .nullable()
            .required("Necesitamos saber tu sexo")
            .oneOf(["male","female"]),
        partner: Yup.string()
            .nullable()
            .required("Necesitamos saber tu estado civil")
            .oneOf(["partner","no-partner"]),
        sons: Yup.string()
            .nullable()
            .required("Necesitamos saber cuantos hijos tienes")
            .oneOf(["0","1","2","3","4"]),
        monthly_income: Yup.number()
            .nullable()
            .required("Tienes que introducir tus ingresos mensuales")
            .min(0,"Los ingresos no pueden ser negativos")
            .max(9999999999999.99,"La cantidad excede el máximo permitido")

    })
    const initialValues={
        name:user.family_situation?.name,
        date:user.family_situation?.birthday===undefined?null:parse(user.family_situation.birthday,"dd/MM/yyyy",new Date()),
        gender:user.family_situation?.gender===undefined?undefined:user.family_situation.gender==="H"?"male":"female",
        partner:user.family_situation?.has_partner===undefined?undefined:user.family_situation.has_partner===true?"partner":"no-partner",
        sons:user.family_situation?.current_children===undefined?undefined: user.family_situation.current_children.toString(),
        monthly_income:user.income?.total===undefined?null:user.income?.total,
    }
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {

                setSubmitting(false);
                dispatch(sendUserForm({...onboardingAccountFormHelpersParseFormData(values)}))
                props.setOpen(false)
            }}
            enableReinitialize
            validationSchema={validationSchema}
        >
            {/*
                submitForm has useful functions like errors or isSubmitting that can be used to block fields when error
                or submission event happen. We are using redux so we dont need them
            */}
            {({ submitForm,validateForm,errors,submitCount})=>(
                <Form className={classes.form_container}>
                    <Grid
                        container
                        justify={"space-between"}
                        direction={"column"}
                    >
                        <Grid
                            item
                        >
                            <InputRadioGroupForm submitCount={submitCount} error={errors.partner} radioGroup={radioGroup2} initialValue={user.family_situation?.has_partner===undefined?"":user.family_situation.has_partner===true?"partner":"no-partner"}/>
                        </Grid>
                        <Grid
                            item
                        >
                            <Field
                                className="onboarding-account-form-input account-form-input"
                                component={TextField}
                                /*
                                    value on an input cant be null or a warning is raised, we need to initialite it
                                    on an empty string or undefined, in this case we use undefined cause it is an
                                    uncontroled element
                                 */
                                type={"string"}
                                autoComplete={"off"}
                                variant={"filled"}
                                name={"name"}
                                label={"Titular principal"}
                                size={"small"}
                                fullWidth={true}
                                InputProps={{
                                    disableUnderline:true,
                                }}
                            />
                        </Grid>
                        <Grid
                            item
                        >
                            <Field
                                component={KeyboardDatePicker}
                                className="account-form-input"
                                name={"date"}
                                inputVariant="filled"
                                autoComplete={"off"}
                                cancelLabel={"Atrás"}
                                autoOk={true}
                                fullWidth={true}
                                /*
                                    disableToolbar hides the superior toolbar and dont let you choose between years
                                    or months
                                 */
                                minDate={sub(Date.now(), {years: 90})}
                                minDateMessage={"¿De verdad eres tan mayor?"}
                                maxDateMessage={"Debes ser mayor de edad"}
                                invalidDateMessage={"Formato de fecha no válido, debe ser dd/mm/aaaa"}
                                maxDate={sub(Date.now(), {years: 18})}
                                label={"Fecha de nacimiento"}
                                format="dd/MM/yyyy"
                                inputProps={{ inputMode: 'numeric'}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                mask={"__/__/____"}
                                placeholder={"__/__/____"}
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        item
                    >
                        <FormControl className={"gender-selector"} variant={"filled"} style={{width:"100%"}}>
                            <InputLabel>Sexo</InputLabel>
                            <Field
                                component={Select}
                                name="gender"

                            >
                                <MenuItem value={'male'}>
                                    <Typography variant={"body2"}>
                                        Masculino
                                    </Typography>
                                </MenuItem>
                                <MenuItem value={'female'}>
                                    <Typography>

                                        <Typography variant={"body2"}>
                                            Femenino
                                        </Typography>
                                    </Typography>
                                </MenuItem>
                            </Field>
                        </FormControl>
                    </Grid>
                    <Grid
                        item
                    >
                        <Field
                            className="onboarding-account-form-input account-form-input"
                            component={TextField}
                            fullWidth={true}
                            variant={"filled"}
                            name={"monthly_income"}
                            label={"Tus ingresos netos mensuales"}
                            size={"small"}
                            InputProps={{
                                disableUnderline:true,
                                endAdornment:(
                                    <Typography style={{color:"#AABACA"}}>
                                        {config.currency_symbol}/mes
                                    </Typography>
                                ),
                                inputComponent: NumberFormatCustom,
                            }}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        justify={"center"}
                    >
                        <InputRadioGroupForm submitCount={submitCount} error={errors.sons} radioGroup={radioGroup3} initialValue={user.family_situation?.current_children===undefined?"": user.family_situation.current_children.toString()}/>
                    </Grid>

                    <Grid
                        container
                        direction={"column"}
                        spacing={2}
                    >
                        <Grid
                            item
                            className="account-form-button-item"
                        >
                            <Button
                                style={{width:'100%'}}
                                className="onboarding-account-form-button"
                                variant={"outlined"}
                                onClick={()=>props.setOpen(false)}
                            >
                                Cancelar
                            </Button>
                        </Grid>
                        <Grid
                            item
                            className="account-form-button-item"
                        >
                            {/*
                                if we want the submit button to be disabled until all the fields are valid and if the
                                fields values have been changed from their initial values on formik then we have to use:
                                disabled={!(formik.isValid && formik.dirty)}
                            */}
                            <Button
                                style={{width:'100%'}}
                                className="onboarding-account-form-button"
                                variant={"contained"}
                                color={"primary"}
                                /*
                                    We add isInitialValid to check that the initialValues are correct and
                                    enable the submit button
                                 */
                                onClick={()=>validateForm().then(submitForm)}
                            >
                                Guardar cambios
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    )
}