import {createSlice} from "@reduxjs/toolkit";

const steppersSlice = createSlice({
    name:'steppers',
    initialState:{
        onboarding:0
    },
    reducers:{
        nextOnboardingStep(state,action){
            state.onboarding++
        },
        setOnboardingStep(state,action){
            state.onboarding=action.payload
        }
    }
})

export const {nextOnboardingStep,setOnboardingStep} = steppersSlice.actions
export const selectSteppers = (state) => state.steppers
export default steppersSlice.reducer