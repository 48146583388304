import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {getFamilySituation, postFamilySituation} from "../../services/familySituation/familySituation";
import {getIncome, postIncome} from "../../services/income/income";
import {sendRetirementForm} from "../firstObjective/firstObjectiveSlice";
import {parse, differenceInYears} from 'date-fns'
import {getAuthUser} from "../../services/oauth/oauthUser";

const initialState={
    family_situation:null,
    income: {business_income:[]},
    expenses:null,
    loading:false,
    route:"/",
    email_verified_at:null,
    is_active:null,
    isRegisterVisible:false,
    error:false,
    isPremium:false,
    premium:{
        family:null,
        real_state:null,
        financial:null,
        income_expenses:null
    }
}
export const sendUserForm = createAsyncThunk(
    'user/sendUserForm',
    /*
        The parameters of the funcion are (data, thunkAPI)
        async (userData,thunkAPI)
     */
    async (userData,thunkAPI)=>{
        const {family_situation,income}=userData
        const responseFamilySituation= postFamilySituation(family_situation)
        const responseIncome= postIncome(income)
        return Promise.all([responseFamilySituation,responseIncome]).then(responsesData=>{
            /*
                we return a number of objects equal to the number of petitions with the name of the url we are calling
                because we name in the initial state our parameters as the names on the endpoints
             */
            return responsesData.reduce((obj,responseData)=>{
                obj[responseData.url.replace(/-/g,"_")]=responseData.data
                return obj
            },{})
        })
    }
)
export const getUserData = createAsyncThunk(
    'user/getUserData',
    /*
        The parameters of the funcion are (data, thunkAPI)
        async (userData,thunkAPI)
     */
    async (userData,thunkAPI)=>{
        const responseFamilySituation=getFamilySituation()
        const responseIncome= getIncome()
        return Promise.all([responseFamilySituation,responseIncome]).then(responsesData=>{
            /*
                we return a number of objects equal to the number of petitions with the name of the url we are calling
                because we name in the initial state our parameters as the names on the endpoints
             */
            return responsesData.reduce((obj,responseData)=>{
                obj[responseData.url.replace(/-/g,"_")]=responseData.data
                return obj
            },{})
        })
    }
)
export const getAuthUserData = createAsyncThunk(
    'user/getAuthUserData',
    /*
        The parameters of the funcion are (data, thunkAPI)
        async (userData,thunkAPI)
     */
    async (data,thunkAPI)=>{
        return getAuthUser().then(response=>response.data)
    }
)

export const userSlice = createSlice({
    name:"user",
    initialState: initialState,
    reducers:{
        addFirstUser(){
            return initialState
        },
        setRoute(state,action){
            state.route=action.payload
        },
        setIsRegisterVisible(state,action){
            state.isRegisterVisible=action.payload
        },
        setUserError(state,action){
            state.error=action.payload
        },
        setIsPremium(state,action){
            state.isPremium=action.payload
        },
        setPremiumFamily(state,action){
            state.premium={family:action.payload}
        }
    },
    extraReducers:{
        [sendUserForm.pending]:(state)=>{
            state.loading=true
        },
        [sendUserForm.fulfilled]:(state,action)=>{
            const birthday=parse(action.payload.family_situation.birthday,"dd/MM/yyyy",new Date())
            const age=differenceInYears(new Date(),birthday)

            /*
                we use Object.keys(object) that returns an array with the keys of the object and then map it to
                store the value of the given key in the given redux state property
             */
            Object.keys(action.payload).map(key=>
                state[key]=action.payload[key]
            )
            state.loading=false
            window.dataLayer.push({
                'event':'setGender',
                'gender': action.payload.family_situation.gender,
                'age':age
            });
            state.route="/crear-meta"
        },
        [sendUserForm.rejected]:(state)=>{
            state.loading=false
        },
        [sendRetirementForm.pending]:(state)=>{
            state.loading=true
        },
        [sendRetirementForm.fulfilled]:(state,action)=>{
            state.income.business_income[0]=action.payload.business_income
            state.loading=false
        },
        [sendRetirementForm.rejected]:(state)=>{
            state.loading=false
        },
        [getUserData.pending]:(state)=>{
            state.loading=true
        },
        [getUserData.fulfilled]:(state,action)=>{
            /*
                we use Object.keys(object) that returns an array with the keys of the object and then map it to
                store the value of the given key in the given redux state property
             */
            Object.keys(action.payload).map(key=>
                state[key]=action.payload[key]
            )
            state.loading=false
            state.error=false
        },
        [getUserData.rejected]:(state)=>{
            state.loading=false
            state.error=true
        },
        [getAuthUserData.pending]:(state)=>{
            state.loading=true
        },
        [getAuthUserData.fulfilled]:(state,action)=>{
            state.email_verified_at=action.payload.email_verified_at
            state.is_active=action.payload.is_active
            state.loading=false
        },
        [getAuthUserData.rejected]:(state)=>{
            state.loading=false
        },

    }
})

export const {addFirstUser,setRoute,setIsRegisterVisible, setUserError,setIsPremium,setPremiumFamily}  = userSlice.actions
export const selectUser = (state) => state.user
export default userSlice.reducer