import React, {useState} from 'react'

import {Button, Dialog, Grid, MenuItem, withStyles} from "@material-ui/core";
import {colors} from "../../styles/colors/colors";
import {Responsive} from "../../utils/Responsive";
import {ReactComponent as HamburguerIcon} from "../../assets/landing/menu-hamburguer.svg"
import {ReactComponent as CloseIconSvg} from "../../assets/icons/24px/close.svg"
import {useHistory} from 'react-router-dom'
import {logotypeSrc} from "../../utils/logotype";
import {useDispatch} from "react-redux";
import {setIsRegisterVisible} from "../../redux/user/userSlice";

const LandingHeaderMenuItem = withStyles(theme=>({
    root:{
        color:colors['--interactive'],
        fontFamily:'DM Sans',
        fontSize:16
    }
}))(MenuItem)
const LandingHeaderDialog = withStyles(theme=>({
    container:{
        height:'inherit'
    }
}))(Dialog)

function LandingHeader (props){
    const dispatch=useDispatch()
    const history=useHistory()
    const [openDialog,setOpenDialog]=useState(false)
    const logoSrc=logotypeSrc()
    return(
        <Grid
            container
            justify={"space-between"}
            alignItems={"center"}
            style={{marginTop:30}}
        >
            <Grid
                item
            >
                <img alt='' src={logoSrc} style={
                    { width:"153px", height:"53px"}
                }
                />
            </Grid>
            <Responsive displayIn={'Laptop'}>
                <Grid
                    item
                    container
                    style={{width:'fit-content',zIndex:3,position:'relative'}}
                    spacing={2}
                    alignItems={"center"}
                >
                    <Grid
                        item
                        onClick={()=>history.push('/')}
                    >
                        <LandingHeaderMenuItem>
                            Inicio
                        </LandingHeaderMenuItem>
                    </Grid>
                    <Grid
                        item
                        onClick={()=>history.push('/sobre-nosotros')}
                    >
                        <LandingHeaderMenuItem>
                            Nosotros
                        </LandingHeaderMenuItem>
                    </Grid>
                    <Grid
                        item
                    >
                        <LandingHeaderMenuItem>
                            FAQs
                        </LandingHeaderMenuItem>
                    </Grid>
                    <Grid
                        item
                    >
                        <LandingHeaderMenuItem>
                            Blog
                        </LandingHeaderMenuItem>
                    </Grid>
                    <Grid
                        item
                        style={{marginLeft:180}}
                    >
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            size={"small"}
                            style={{fontSize:16,backgroundColor:colors['--brand-main-100']}}
                            onClick={()=>dispatch(setIsRegisterVisible(true))}
                        >
                            Login
                        </Button>
                    </Grid>
                </Grid>
            </Responsive>
            <Responsive displayIn={['Tablet','Mobile']}>
                <Grid
                    item
                    container
                    style={{
                        borderBottomLeftRadius: '50% 75%',
                        borderBottomRightRadius: '50% 75%',
                        borderTopLeftRadius: '50% 75%',
                        borderTopRightRadius: '50% 75%',
                        background:'rgba(0,0,0,0.1)',
                        transform:'scale(1.5)',
                        width:40,
                        height:25,
                        cursor:'pointer'
                    }}
                    justify={"center"}
                    alignItems={"center"}
                    onClick={()=>setOpenDialog(true)}
                >
                    <Grid
                        item
                    >
                        <HamburguerIcon />
                    </Grid>
                </Grid>
                <LandingHeaderDialog open={openDialog} onClose={()=>setOpenDialog(false)} fullWidth>
                    <Grid
                        container
                        direction={"column"}
                        alignItems={"center"}
                        style={{marginBottom:30}}
                    >
                        <Grid
                            item
                            container
                            justify={"flex-end"}
                            style={{paddingTop:6,paddingRight:6}}
                        >
                            <Grid
                                item
                            >
                                <CloseIconSvg onClick={()=>setOpenDialog(false)}/>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            onClick={()=>history.push('/')}
                        >
                            <LandingHeaderMenuItem>
                                Inicio
                            </LandingHeaderMenuItem>
                        </Grid>
                        <Grid
                            item
                            onClick={()=>history.push('/sobre-nosotros')}
                        >
                            <LandingHeaderMenuItem>
                                Nosotros
                            </LandingHeaderMenuItem>
                        </Grid>
                        <Grid
                            item
                        >
                            <LandingHeaderMenuItem>
                                FAQs
                            </LandingHeaderMenuItem>
                        </Grid>
                        <Grid
                            item
                        >
                            <LandingHeaderMenuItem>
                                Blog
                            </LandingHeaderMenuItem>
                        </Grid>
                        <Grid
                            item
                        >
                            <LandingHeaderMenuItem>
                                Login
                            </LandingHeaderMenuItem>
                        </Grid>
                    </Grid>
                </LandingHeaderDialog>
            </Responsive>
        </Grid>
    )
}
export default LandingHeader