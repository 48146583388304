import axios from 'axios'
import {getResponse} from "../../redux/apiCalls/apiCallsSlice";
/*
    we are calling this outside the provider of store so we need to import the store and call manually the desired
    function
 */

import {store} from '../../redux/store'
import {configAxiosHelpersParseResponse} from "./configAxiosHelpers";
import SnackbarUtils from "../../utils/SnackbarUtils";
import errorsHandler from "../../utils/errorsHandler";

function configAxiosInterceptors(){
    axios.interceptors.response.use(function (response){
        /*
            Any 2xx cause this function to trigger
            So we just return de data correctly
         */
            store.dispatch(getResponse(configAxiosHelpersParseResponse(response)))
        return response
    }, function (e) {
        /*
            Any status codes that falls outside the range of 2xx cause this function to trigger
            We return a reject promise
         */
        /*
            500=internal server error
            403=Forbidden
            401=Unauthorized
         */
        if(e.response.status===500){
            SnackbarUtils.error("Error interno del servidor")
        }else if(e.response.status===403){
            SnackbarUtils.error("No tienes permiso para realizar esta acción")
        }else if(e.response.status===503){
            SnackbarUtils.error("Servicio no disponible")
        }else if(e.response.status===401){
            SnackbarUtils.error("No estás logueado")
        }else if(e.response.status===400){
            SnackbarUtils.error(e.response.data.message)
        }else if(e.response.status===404){
            SnackbarUtils.error("Recurso no encontrado")
        }else{
            errorsHandler(e)
        }
        store.dispatch(getResponse(configAxiosHelpersParseResponse(e.response)))
        return Promise.reject(e)
        }
    )
    axios.interceptors.request.use(
        config=>{
            const token=localStorage.getItem("token")
            if(token){
                config.headers.Authorization=`Bearer ${token}`;
            }else{

            }
            return config
        },
        error=>Promise.reject(error)
    )
}
export default configAxiosInterceptors