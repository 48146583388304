import React, {useState} from "react";
import {Button, Divider, Grid, Typography} from "@material-ui/core";
import {colors} from "../../styles/colors/colors";
import Box from "@material-ui/core/Box";
import SidebarPlan from "../../components/sidebar/SidebarPlan";
import {useDispatch, useSelector} from "react-redux";
import {selectUser, setIsPremium, setPremiumFamily} from "../../redux/user/userSlice";
import {planStyles} from "../plan/planStyles";
import {ReactComponent as EditIcon} from "../../assets/icons/24px/edit.svg";
import {ReactComponent as CoupleIcon} from "../../assets/icons/24px/couple.svg";
import {ReactComponent as SingleIcon} from "../../assets/icons/24px/single.svg";
import {ReactComponent as CrownIcon} from "../../assets/userData/crown.svg";
import NumberFormat from "react-number-format";
import {selectConfig} from "../../redux/config/configSlice";
import {generalStyles} from "../../styles/general/generalStyles";
import {ReactComponent as FamilyIcon} from "../../assets/userData/family.svg";
import {ReactComponent as PatrimonyIcon} from "../../assets/userData/patrimony.svg";
import {ReactComponent as RealStateIcon} from "../../assets/userData/real-state.svg";
import {ReactComponent as IncomeExpensesIcon} from "../../assets/userData/income-expenses.svg";
import {userDataStyles} from "./userDataStyles";
import UserDataMainForm from "../../components/userData/UserDataMainForm";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import {Field, Form, Formik} from "formik";
import {TextField} from "formik-material-ui";
import * as Yup from "yup";

export default function UserData(){
    const dispatch=useDispatch()
    const user=useSelector(selectUser)
    const config=useSelector(selectConfig)
    const classes=planStyles()
    const userDataClasses=userDataStyles()
    const generalClasses=generalStyles()
    const [isMainFormVisible,setIsMainFormVisible]=useState(false)
    const [modifyDataMenus,setModifyDataMenus]=useState([
        {
            id:'family',
            isEditing:false,
            title:'Situación familiar',
            text:'Agrega datos de tu situación familiar para afinar tu plan',
            svgImage:<FamilyIcon/>,
            validationSchema:Yup.object({
                has_partner: Yup.string()
                    .nullable()
                    .max(20,"El nombre no puede contener mas de 20 letras")
                    .required("Nombre requerido"),
                partner_name: Yup.string()
                    .nullable()
                    .max(20,"El nombre no puede contener mas de 20 letras")
                    .required("Nombre requerido"),
                partner_gender: Yup.string()
                    .nullable()
                    .max(20,"El nombre no puede contener mas de 20 letras")
                    .required("Nombre requerido"),
                partner_birthday: Yup.string()
                    .nullable()
                    .max(20,"El nombre no puede contener mas de 20 letras")
                    .required("Nombre requerido"),
                studies: Yup.string()
                    .nullable()
                    .max(20,"El nombre no puede contener mas de 20 letras")
                    .required("Nombre requerido"),
                partner_studies: Yup.string()
                    .nullable()
                    .max(20,"El nombre no puede contener mas de 20 letras")
                    .required("Nombre requerido"),
                sons: Yup.string()
                    .nullable()
                    .max(20,"El nombre no puede contener mas de 20 letras")
                    .required("Nombre requerido"),
                postal_code: Yup.string()
                    .nullable()
                    .max(20,"El nombre no puede contener mas de 20 letras")
                    .required("Nombre requerido"),

            }),
            questions:[
                {
                    id:'has_partner',
                    question:'¿Vives solo o en pareja?',
                    value:'En pareja',
                },
                {
                    id:'partner_name',
                    question:'Nombre de tu pareja',
                    value:'Marta',
                },
                {
                    id:'partner_gender',
                    question:'Sexo de tu pareja',
                    value:'Femenino',
                },
                {
                    id:'partner_birthday',
                    question:'Fecha de nacimiento de tu pareja',
                    value:'12/10/1995',
                },
                {
                    id:'studies',
                    question:'Tus estudios finalizados',
                    value:'Universitarios',
                },
                {
                    id:'partner_studies',
                    question:'Estudios finalizados de tu pareja',
                    value:'Universitarios',
                },
                {
                    id:'sons',
                    question:'Numero de hijos a cargo',
                    value:'1',
                },
                {
                    id:'postal_code',
                    question:'Código postal residencia familiar',
                    value:'44500',
                },
                {
                    id:null,
                    question:'',
                    value:'',
                },
            ]
        },
        {
            id:'financial',
            title:'Patrimonio financiero',
            text:'Agrega datos de tu patrimonio financiero para afinar tu plan',
            svgImage:<PatrimonyIcon/>
        },
        {
            id:'real_estate',
            title:'Patrimonio inmobiliario',
            text:'Agrega datos de tu patrimonio inmobiliario para afinar tu plan',
            svgImage:<RealStateIcon/>
        },
        {
            id:'income_expenses',
            title:'Ingresos y gastos',
            text:'Agrega datos de tus gastos e ingresos para afinar tu plan',
            svgImage:<IncomeExpensesIcon/>
        },
    ])
    const userColumnData=[
        {
            title:'Titular principal',
            data:<Typography variant={"body2"}>
                {user.family_situation?.name}
            </Typography>
        },
        {
            title:'Fecha de nacimiento',
            data:<Typography variant={"body2"}>
                {user.family_situation?.birthday}
            </Typography>
        },
        {
            title:'Sexo',
            data:<Typography variant={"body2"}>
                {user.family_situation?.gender==='H'?'Hombre':'Mujer'}
            </Typography>
        },
        {
            title:'Tus ingresos netos mensuales',
            data:<Typography variant={"body2"}>
                <NumberFormat
                value={user.income?.total}
                thousandSeparator={config.thousand_separator}
                decimalSeparator={config.decimal_separator}
                decimalScale={0}
                displayType={"text"}
                suffix={config.currency_suffix}
                prefix={config.currency_prefix}
            />
            </Typography>
        },
        {
            title:'Número de hijos a tu cargo',
            data:<Typography variant={"body2"}>
                {user.family_situation?.current_children}
            </Typography>
        },
    ]
    return(
        <Grid
            container
            style={{height:"100%",background:colors["--background"]}}
            direction={"row"}
        >
            <Backdrop style={{zIndex:"1501"}}  open={user.loading} >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box style={{flexGrow:"1", display: "flex", overflow: "hidden"}}>
                <Box className={classes.menu_bar_box}>
                    <SidebarPlan user={user}/>
                </Box>
                <Box className={classes.content_container_box}>
                    <Grid
                        item
                        container
                        direction={"row"}
                        style={{
                            minHeight:'100vh',
                            maxHeight:'100%'
                        }}
                    >
                        {
                            // LEFT COLUMN
                        }
                        <Grid
                            item
                            container
                            style={{
                                maxWidth:376,
                                background:colors['--white'],
                                boxShadow: '0px 4px 8px rgba(219, 225, 231, 0.3), 0px 8px 20px #F0F5F9',
                                borderRadius: 2,
                                padding:24
                            }}
                            direction={"column"}
                            sm={4}
                            lg={4}
                            xs={12}
                        >
                            {
                                isMainFormVisible===false?
                                    <Grid
                                        item
                                        container
                                        direction={"column"}
                                        spacing={3}
                                    >
                                        {
                                            //HEADER OF LEFT CONTAINER
                                        }
                                        <Grid
                                            item
                                            container
                                            direction={"column"}
                                        >
                                            <Grid
                                                item
                                                container
                                                justify={"flex-end"}
                                            >
                                                <Grid
                                                    style={{cursor:"pointer"}}
                                                    onClick={()=>setIsMainFormVisible(true)}
                                                >
                                                    <EditIcon
                                                        id={'edit-profile'}
                                                        fill={"#AABACA"}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid
                                                item
                                                container
                                                direction={"column"}
                                                spacing={2}
                                            >
                                                <Grid
                                                    item
                                                    container
                                                    alignItems={"center"}
                                                    spacing={1}
                                                    direction={"column"}
                                                >
                                                    <Grid
                                                        item
                                                    >
                                                        {
                                                            user.family_situation?.has_partner===true?
                                                                <CoupleIcon style={{transform: 'scale(1.5)'}} fill={colors['--brand-main-100']} />
                                                                :
                                                                <SingleIcon style={{transform: 'scale(1.5)'}} fill={colors['--brand-main-100']}  />
                                                        }
                                                    </Grid>
                                                    <Grid
                                                        item
                                                    >
                                                        <Typography variant={"h4"}>
                                                            {
                                                                user.family_situation?.has_partner===true?
                                                                    'Plan financiero en pareja'
                                                                    :
                                                                    'Plan financiero individual'
                                                            }
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    style={{width:'inherit'}}
                                                >
                                                    <Divider />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {
                                            //BODY OF LEFT COLUMN
                                        }
                                        <Grid
                                            item
                                            container
                                            direction={"column"}
                                            spacing={3}
                                            style={{paddingLeft:26,paddingRight:26}}
                                        >
                                            {
                                                userColumnData.map(data=><Grid item container direction={"column"}>
                                                    <Grid
                                                        item
                                                    >
                                                        <Typography variant={"caption"} style={{color:colors['--black-soft']}}>
                                                            {data.title}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item>
                                                        {data.data}
                                                    </Grid>
                                                </Grid>)
                                            }
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid
                                        item
                                        container
                                        direction={"column"}
                                        spacing={3}
                                    >
                                        <UserDataMainForm user={user} setOpen={setIsMainFormVisible}/>
                                    </Grid>
                            }
                        </Grid>
                        {
                            //RIGHT COLUMN
                        }
                        {
                            process.env.REACT_APP_ENVIRONMENT!=='planifica' &&
                            <Grid
                                item
                                container
                                className={generalClasses.width_fit_content}
                                style={{padding:24}}
                                direction={"column"}
                                sm={8}
                                lg={8}
                                xs={12}
                            >
                                <Grid
                                    item
                                    container
                                    style={{maxWidth:876,marginBottom:16}}
                                >
                                    <Typography variant={'h3'} onClick={()=> {
                                        dispatch(setIsPremium(false))
                                        dispatch(setPremiumFamily(false))
                                    }}>
                                        Tus datos financieros
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    container
                                    className={userDataClasses.right_column_items_container}
                                >
                                    {
                                        user.isPremium===false &&
                                        <Grid direction={"column"} alignItems={"center"} item container style={{width:'100%',padding:'32px 0 40px',marginBottom:16,background:'#051122',boxShadow: '0px 4px 8px rgba(219, 225, 231, 0.3), 0px 8px 20px #F0F5F9',
                                            borderRadius:2}}>
                                            <Grid
                                                item
                                                container
                                                className={generalClasses.width_fit_content}
                                                spacing={2}
                                                direction={"column"}
                                                alignItems={"center"}
                                            >
                                                <Grid
                                                    item
                                                >
                                                    <CrownIcon fill={colors['--brand-accent-100']}/>
                                                </Grid>
                                                <Grid
                                                    item
                                                    container
                                                    direction={"column"}
                                                    className={generalClasses.width_fit_content}
                                                    spacing={1}
                                                    alignItems={"center"}
                                                    style={{maxWidth:406}}
                                                >
                                                    <Grid
                                                        item
                                                    >
                                                        <Typography variant={"h4"} style={{color:colors['--brand-accent-100']}}>
                                                            Pronto podrás disfrutar de un plan premium
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                    >
                                                        <Typography variant={"h4"} style={{color:colors['--white']}} align={"center"}>
                                                            Con premium nuestro algoritmo hará una planificación mucho más personalizada y adaptada a tu vida
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    item
                                                    container
                                                    direction={"column"}
                                                    alignItems={"center"}
                                                    spacing={2}
                                                    style={{marginTop:40}}
                                                >
                                                    <Grid
                                                        item
                                                    >
                                                        <Typography variant={"caption"} style={{color:colors['--white']}} align={"center"}>
                                                            ¿Quieres saber cuando estará disponible?
                                                        </Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                    >
                                                        <Button
                                                            variant={"contained"}
                                                            color={"primary"}
                                                            onClick={()=>dispatch(setIsPremium(true))}
                                                        >
                                                            Informadme a mi email
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                    {
                                        modifyDataMenus.map((data,index)=><Grid justify={"space-between"} item container style={{width:'100%',marginBottom:16,background:colors['--white'],boxShadow: '0px 4px 8px rgba(219, 225, 231, 0.3), 0px 8px 20px #F0F5F9',opacity:user.isPremium===true?1:0.5,
                                            borderRadius:2}}>
                                            {user.premium?.[data.id]?
                                                <>
                                                    <Grid
                                                        item
                                                        container
                                                        spacing={2}
                                                        direction={"column"}
                                                        style={{padding: 16}}
                                                    >
                                                        <Grid
                                                            item
                                                            container
                                                            justify={"space-between"}
                                                        >
                                                            <Grid
                                                                item
                                                            >
                                                                <Typography variant={"h4"}>
                                                                    {data.title}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                container
                                                                className={generalClasses.width_fit_content}
                                                                spacing={2}
                                                            >
                                                                <Grid
                                                                    item
                                                                >
                                                                    <Typography variant={"caption"}>
                                                                        Última edición: 12/10/2020
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                >
                                                                    <EditIcon fill={"#AABACA"} onClick={()=>setModifyDataMenus([...modifyDataMenus].map((menu,menuIndex)=>menuIndex===index?{...menu,isEditing: true}:{...menu}))}/>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                    <Divider style={{width:'100%'}}/>
                                                    <Grid
                                                        item
                                                        container
                                                        style={{padding:32}}
                                                    >
                                                        {
                                                            data.isEditing===false?
                                                                data.questions?.map(questionData=><Grid item container direction={"column"} style={{maxWidth:270,marginBottom:24}} justify={"space-between"}>
                                                                    <Grid
                                                                        item
                                                                    >
                                                                        <Typography variant={"caption"} style={{color:colors['--black-soft']}}>
                                                                            {questionData.question}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        {questionData.value}
                                                                    </Grid>
                                                                </Grid>)
                                                                :
                                                                <Formik
                                                                    initialValues={data.questions?.reduce((obj, item) => {
                                                                        return {
                                                                            ...obj,
                                                                            [item?.id]: item?.value,
                                                                        };
                                                                    }, {})
                                                                    }
                                                                    onSubmit={(values, { setSubmitting }) => {

                                                                        setSubmitting(false);
                                                                    }}
                                                                    enableReinitialize
                                                                >
                                                                    {({ submitForm,validateForm,errors,submitCount})=>(
                                                                        <Form >
                                                                            <Grid
                                                                                item
                                                                                container
                                                                                justify={"space-between"}
                                                                            >
                                                                                {data.questions?.map(data => <Grid item
                                                                                                                   container
                                                                                                                   direction={"column"}
                                                                                                                   style={{
                                                                                                                       maxWidth: 250,
                                                                                                                       marginBottom: 24
                                                                                                                   }}
                                                                                                                   justify={"space-between"}>
                                                                                    <Grid
                                                                                        item
                                                                                    >
                                                                                        {data.id?
                                                                                            <Field
                                                                                                className="onboarding-account-form-input account-form-input"
                                                                                                component={TextField}
                                                                                                /*
                                                                                                    value on an input cant be null or a warning is raised, we need to initialite it
                                                                                                    on an empty string or undefined, in this case we use undefined cause it is an
                                                                                                    uncontroled element
                                                                                                 */
                                                                                                type={"string"}
                                                                                                autoComplete={"off"}
                                                                                                variant={"filled"}
                                                                                                fullWidth={true}
                                                                                                name={data.id}
                                                                                                label={data.question}
                                                                                                size={"small"}
                                                                                                InputProps={{
                                                                                                    disableUnderline: true,
                                                                                                }}
                                                                                            />
                                                                                            :
                                                                                            <div style={{width:'100%'}}/>
                                                                                        }
                                                                                    </Grid>
                                                                                </Grid>)}
                                                                                <Grid
                                                                                    item
                                                                                    container
                                                                                    spacing={2}
                                                                                    justify={"flex-end"}
                                                                                >
                                                                                    <Grid
                                                                                        item
                                                                                    >
                                                                                        <Button
                                                                                            variant={"outlined"}
                                                                                            onClick={()=>setModifyDataMenus([...modifyDataMenus].map((menu,menuIndex)=>menuIndex===index?{...menu,isEditing: false}:{...menu}))}
                                                                                        >
                                                                                            Cancelar
                                                                                        </Button>
                                                                                    </Grid>
                                                                                    <Grid
                                                                                        item
                                                                                    >
                                                                                        <Button
                                                                                            variant={"contained"}
                                                                                            color={"primary"}
                                                                                            onClick={()=>setModifyDataMenus([...modifyDataMenus].map((menu,menuIndex)=>menuIndex===index?{...menu,isEditing: false}:{...menu}))}
                                                                                        >
                                                                                            Guardar cambios
                                                                                        </Button>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Form>
                                                                    )}
                                                                </Formik>

                                                        }
                                                    </Grid>
                                                </>
                                                :
                                                <Grid
                                                    item
                                                    container
                                                    justify={"space-between"}
                                                    style={{padding: '16px 40px 24px 16px'}}
                                                >
                                                    <Grid
                                                        item
                                                        container
                                                        className={generalClasses.width_fit_content}
                                                        spacing={2}
                                                        direction={"column"}
                                                    >
                                                        <Grid
                                                            item
                                                            container
                                                            direction={"column"}
                                                            className={generalClasses.width_fit_content}
                                                            spacing={1}
                                                        >
                                                            <Grid
                                                                item
                                                            >
                                                                <Typography variant={"h4"}>
                                                                    {data.title}
                                                                </Typography>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                            >
                                                                <Typography variant={"body2"} style={{fontSize:14}}>
                                                                    {data.text}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                        >
                                                            <Button
                                                                variant={"contained"}
                                                                color={"primary"}
                                                                onClick={()=>dispatch(setPremiumFamily(true))}
                                                            >
                                                                Añadir datos
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                    >
                                                        {data.svgImage}
                                                    </Grid>
                                                </Grid>

                                            }
                                        </Grid>)
                                    }
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Box>

        </Grid>
    )
}