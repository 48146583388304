import React, {useEffect} from "react";
import {Container, Grid,Typography} from "@material-ui/core";
import {Helmet} from "react-helmet";
import {logotypeSrc} from "../../utils/logotype";

export default function LegalWarning(){
    useEffect(() => {
        window.dataLayer.push({
            'event':'setTitle',
            'title':'Aviso legal'
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const logoSrc=logotypeSrc()
    return(
        <Container
            maxWidth={"md"}
            style={{padding:'20px 0'}}
        >
            <Helmet>
                <title>Aviso legal</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <Grid
                container
                spacing={4}
                style={{padding:'0 20px'}}
            >
                <Grid
                    item
                >
                    <img alt={""} src={logoSrc} style={{width:150}}/>
                </Grid>
                <Grid
                    item
                    container
                    direction={"column"}
                    spacing={4}
                >
                    <Grid
                        item
                        container
                        direction={"column"}
                        alignItems={"center"}
                    >
                        <Grid
                            item
                        >
                            <Typography variant={"h1"}>
                                Aviso Legal
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                    >
                        <Typography variant={"body2"}>
                            En cumplimiento con el deber de información recogido en artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, INVEERT INTELIGENCIA ARTIFICIAL RACIONAL S.L., le informa que los datos aquí consignados corresponden a la entidad titular del sitio web www.inveert.com.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                    >
                        <Typography variant={"body2"}>
                            Denominación: Inveert Inteligencia Artificial Racional S.L.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                    >
                        <Typography variant={"body2"}>
                            NIF: B-90411349
                        </Typography>
                    </Grid>
                    <Grid
                        item
                    >
                        <Typography variant={"body2"}>
                            Domicilio: C/ Tabladilla nº 7, Portal B, 3ª A - 41013, Sevilla.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                    >
                        <Typography variant={"body2"}>
                            E-mail: info@inveert.com
                        </Typography>
                    </Grid>
                    <Grid
                        item
                    >
                        <Typography variant={"body2"}>
                            Inscripción Registral: Registro Mercantil de Sevilla, Tomo 6652, Folio 170, Sección General de Sociedades, Hoja Nº SE-120.197, Inscripción 1ª.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                    >
                        <Typography variant={"body2"}>
                            Inveert Inteligencia Artificial Racional S.L (en adelante la Empresa), a través de su página web, ofrece información sobre su actividad y servicios, siendo dicha información meramente informativa.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                    >
                        <Typography variant={"body2"}>
                            La Empresa se reserva la facultad, en todo momento y sin necesidad de previo aviso, de efectuar modificaciones y actualizaciones de la información contenida en su web o en la configuración y presentación de ésta. Asimismo, se reserva el derecho a suspender, interrumpir, cancelar o restringir temporalmente, y sin necesidad de previo aviso, la accesibilidad a la página web por razón de eventual necesidad de efectuar operaciones de mantenimiento, reparación o mejoras en la misma.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                    >
                        <Typography variant={"body2"}>
                            Las marcas, rótulos, signos distintivos, logotipos, imágenes, ilustraciones, datos y textos del sitio web referenciado, son titularidad de la Empresa y están debidamente registrados; o bien son titularidad de entidades proveedoras de información, no pudiendo ser objeto de modificación, transformación, alteración, reproducción, adaptación, copia, o traducción por parte de terceros, sin la expresa autorización de sus titulares. La puesta a disposición de los mismos no implica, en ningún caso, la cesión de su titularidad o la concesión de un derecho de explotación, difusión, distribución o transmisión a su favor, distinto del derecho de uso que comporta la utilización legítima de la web.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                    >
                        <Typography variant={"body2"}>
                            Los contenidos y diseño de inveert.com son propiedad de la Empresa o de los proveedores de contenidos, habiendo sido, en este último caso, objeto de licencia o cesión por parte de los mismos, y estando protegidos por las normas de propiedad intelectual.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                    >
                        <Typography variant={"body2"}>
                            La Empresa manifiesta que esta web dispone de la tecnología adecuada para permitir el acceso y utilización del mismo. No obstante, no se responsabiliza por la eventual existencia de virus u otros elementos nocivos, introducidos por cualquier medio o por un tercero, que puedan producir alteraciones en sus sistemas informáticos.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                    >
                        <Typography variant={"body2"}>
                            INVEERT no se responsabiliza de ningún contenido, producto o servicio ajeno a la Empresa y que pudiera aparecer en esta web mediante enlaces directos o indirectos.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                    >
                        <Typography variant={"body2"}>
                            El usuario se compromete a utilizar la web y los servicios de conformidad con la ley y el presente Aviso Legal, así como conforme a la moral, las buenas costumbres generalmente aceptadas y el orden público. A tal efecto, se abstendrá de utilizar la web con fines o efectos ilícitos, lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan dañar, inutilizar, sobrecargar, deteriorar o impedir la normal utilización de la misma.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                    >
                        <Typography variant={"body2"}>
                            Toda la información que facilite el usuario a través de la web deberá ser veraz. A estos efectos, el usuario garantiza la autenticidad de todos aquellos datos que comunique como consecuencia de la cumplimentación de los formularios y/o envío de su currículum. En todo caso el usuario será el único responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios que cause a la Empresa o a terceros por la información que facilite.
                        </Typography>
                    </Grid>
                    <Grid
                        item
                    >
                        <Typography variant={"body2"}>
                            Por el objeto de la actividad, Inveert es agente de Finanbest Inversiones Inteligentes A.V S.A.U., en Paseo de la Castellana, 139. 2ºA, 28046 (Madrid). Finanbest es una Agencia de Valores registrada y regulada por la CNMV con el nº271 y CIF A-87.740.072, que está Inscrita en el Registro Mercantil de Madrid, tomo 35559, folio 115, hoja M-639104.
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}
