export function dniNieValidator(value){
    const validChars='TRWAGMYFPDXBNJZSQVHLCKET'
    const dniRegex=/^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    const nieRegex=/^[XYZ][0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]$/i;
    const str=value?value.replace('-','').toString().toUpperCase():''
    let isValid=false

    if(dniRegex.test(str) || nieRegex.test(str)){

        let nie = str
            .replace(/^[X]/, '0')
            .replace(/^[Y]/, '1')
            .replace(/^[Z]/, '2');

        let letter= str.substr(-1)
        let charIndex = parseInt(nie.substr(0,8)) % 23
        if(validChars.charAt(charIndex)===letter){
            isValid=true
        }

    }
    return isValid
}