import InveertLogotypeSrc from '../assets/logo/inveert_logotype.svg'
import FinseiLogotypeSrc from '../assets/logo/finsei_logotype.svg'
import InveertIsotypeSrc from '../assets/logo/inveert_isotype.svg'
import FinseiIsotypeSrc from '../assets/logo/finsei_isotype.svg'
import PlanificaLogotypeSrc from  '../assets/logo/planifica_logotype.svg'
import PlanificaIsotypeSrc from  '../assets/logo/planifica_isotype.svg'

export function logotypeSrc(){
    let src
    if(process.env.REACT_APP_ENVIRONMENT==='finsei'){
        src= FinseiLogotypeSrc
    }else if(process.env.REACT_APP_ENVIRONMENT==='planifica'){
        src=PlanificaLogotypeSrc
    }else{
        src=InveertLogotypeSrc
    }
    return src
}

export function isotypeSrc(){
    let src
    if(process.env.REACT_APP_ENVIRONMENT==='finsei'){
        src= FinseiIsotypeSrc
    }else if(process.env.REACT_APP_ENVIRONMENT==='planifica'){
        src=PlanificaIsotypeSrc
    }else{
        src=InveertIsotypeSrc
    }
    return src
}
