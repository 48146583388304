import axios from 'axios'

export async function getAllRiskProfiles(data){
    try{
        const response=await axios.get('config/risk-profile?term='+data.term+"&protection_type="+data.protection_type)
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}
export async function getAllRiskProfilesWithoutTermAndProtection(){
    try{
        const response=await axios.get('config/risk-profile')
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}
export async function getRiskProfile(){
    try{
        const response=await axios.get('risk-profile')
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}

/*
    The data accepeted is
    {
        identifier:integer,
        main:1 (optional),
        is_active:1 (optional)
    }
 */
export async function postRiskProfile(data){
    try{
        const response=await axios.post('risk-profile',data)
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}

export async function getRiskProfilePerformance(data){
    try{
        const response=await axios.get('risk-profile?term='+data.term+'&protection_type='+data.protection_type+'&is_active=1')
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}