import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {deletePreviousObjectives} from "../firstObjective/firstObjectiveSlice";
import {getInveertConfig} from "../../services/configVariables/configVariables";

const initialState={
    death_age_woman:null,
    death_age_man: null,
    inflation:null,
    increment_objectives:null,
    percentile:null,
    loading:false,
    min_investment_deposit:null,
    min_investment_mutual:null,
    min_investment_pension:null,
    error:false,
    currency_symbol:"*",
    thousand_separator:'.',
    decimal_separator:',',
    currency_suffix:'',
    currency_prefix:''

}

export const getConfigVariables=createAsyncThunk(
    'config/getConfig',
    async (data, thunkAPI)=>{

        return getInveertConfig().then(responseData=>responseData.data)

    }
)

export const configSlice = createSlice({
    name:"config",
    initialState: initialState,
        reducers:{
            setConfigError(state,action){
                state.error=action.payload
            },
            setConfigLoading(state,action){
                state.loading=action.payload
            }
        },
    extraReducers:{
        [deletePreviousObjectives.fulfilled]:(state,action)=>{
            action.payload.map(variable=>{
                if(variable.name==="DEATH_AGE_MAN"){
                    state.death_age_man=variable.value
                }else if(variable.name==="DEATH_AGE_WOMAN"){
                    state.death_age_woman=variable.value
                }else if(variable.name==="INFLATION"){
                    state.inflation=variable.value
                }else if(variable.name==="INCREMENT_OBJECTIVES"){
                    state.increment_objectives=variable.value
                }else if(variable.name==="PERCENTILE"){
                    state.percentile=variable.value
                }else if(variable.name==="MIN_INVESTMENT_SERVICES"){
                    state.min_investment_deposit=variable.value.deposit
                    state.min_investment_mutual=variable.value.mutual
                    state.min_investment_pension=variable.value.pension
                }

                return 1
            })
        },
        [getConfigVariables.fulfilled]:(state,action)=>{
            action.payload.map(variable=>{
                if(variable.name==="DEATH_AGE_MAN"){
                    state.death_age_man=variable.value
                }else if(variable.name==="DEATH_AGE_WOMAN"){
                    state.death_age_woman=variable.value
                }else if(variable.name==="INFLATION"){
                    state.inflation=variable.value
                }else if(variable.name==="INCREMENT_OBJECTIVES"){
                    state.increment_objectives=variable.value
                }else if(variable.name==="PERCENTILE"){
                    state.percentile=variable.value
                }else if(variable.name==="MIN_INVESTMENT_SERVICES"){
                    state.min_investment_deposit=variable.value.deposit
                    state.min_investment_mutual=variable.value.mutual
                    state.min_investment_pension=variable.value.pension
                }else if(variable.name==="CURRENCY_SYMBOL"){
                    state.currency_symbol=variable.value
                }else if(variable.name==="CURRENCY"){
                    if(variable.value==='USD'){
                        state.currency_prefix=''
                        state.currency_suffix=' USD'
                        state.decimal_separator='.'
                        state.thousand_separator=','
                    }else{
                        state.currency_prefix=''
                        state.currency_suffix='€'
                        state.decimal_separator=','
                        state.thousand_separator='.'
                    }
                }

                state.loading=false
                state.error=false
                return 1
            })
        },
        [getConfigVariables.pending]:(state)=>{
            state.loading=true
        },
        [getConfigVariables.rejected]:(state)=>{
            state.loading=false
            state.error=true
        },

    }
})

export const {setConfigError,setConfigLoading}  = configSlice.actions
export const selectConfig = (state) => state.config
export default configSlice.reducer