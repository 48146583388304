import React, {useEffect, useState} from "react";
import {Radio, FormControl, FormLabel, FormControlLabel, Grid, Typography, FormHelperText} from "@material-ui/core"
import {RadioGroup} from "formik-material-ui";
import makeStyles from "@material-ui/core/styles/makeStyles";
import clsx from "clsx";
import '../../styles/css/cssCustom.css'
import {Field} from "formik";
import "./input.css"
import {colors} from "../../styles/colors/colors";


/*
    * This component creates a radio group that is a section of any form
    * The composition of the accepted parameter are:
    * props={radioGroup}
    * radioGroup=
    *           {
    *               title:string,
    *               name:string,
    *               showRequiredSign:boolean,
    *               rowDirection:boolean
    *               items:
    *                   [
    *                       {
    *                           value:string, (value in the form context)
    *                           label:string,
    *                           imageSrc:string
    *                       }
    *                   ]
    *           }
     */

const useStyles = makeStyles({
    root: {
        padding:15,
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    icon: {
        borderRadius: '50%',
        width: 16,
        height: 16,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        /*
            This is the color of the checked radios
         */
        backgroundColor: colors['--interactive-4'],
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        boxShadow:"none",
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: colors['--interactive-4'],
        },
    },
});

function InputRadioGroupForm(props){
    const classes = useStyles();
    const {isBorderVisible,initialValue,error,submitCount}=props
    const [selectedValue,setSelectedValue]=useState("")
    const {title,name,items,showRequiredSign,rowDirection}=props.radioGroup
    const radioStyle=isBorderVisible?{background:"white",width:"-moz-fit-content",border: `1px solid ${colors['--gray']}`, borderRadius:"2px"}:{width:"fit-content"}
    const radioSelectedStyle=isBorderVisible?{border: `1px solid ${colors['--interactive-4']}`,background:"white",width:"-moz-fit-content"}:{border: `1px solid ${colors['--interactive-4']}`,width:"fit-content"}
    useEffect(() => {
        setSelectedValue(initialValue?initialValue:"")
    }, [initialValue,name]);
    return(

        <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend" required={showRequiredSign} >{title}</FormLabel>
            {/*
                add formik and material wrapped components to control checkboxes
            */}
            <Field component={RadioGroup} name={name} row={rowDirection} aria-label={name} value={selectedValue}>
                {
                    items.map(item=>
                        <FormControlLabel
                            /*
                                We dont use className={item.imageSrc!==null && "radio-group-with-images"} because the
                                classname when imageSrc is null will be null and classname should be a string
                            */
                            className={item.imageSrc!==null ? "radio-group-with-images" : ""}
                            style={selectedValue===item.value ? radioSelectedStyle : radioStyle}
                            value={item.value}
                            key={item.value}
                            control={
                                /*
                                    custom properties should be lowerCase
                                 */
                                <Radio
                                    onChange={(selectedValue)=>{setSelectedValue(selectedValue.target.value)}}
                                    className={classes.root}
                                    disableRipple
                                    color="default"
                                    checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                    icon={<span className={classes.icon} />}
                                    {...props}
                                />
                            }
                            label={
                                <div>
                                    {
                                        item.imageSrc===null?
                                            <Typography variant={"body2"} style={{marginRight:20}}>
                                                {item.inlineImageSrc&&
                                                    <img alt={""} src={item.inlineImageSrc} style={{position:"relative",top:5, marginRight:3}}/>
                                                }
                                                {item.label}
                                            </Typography>
                                            :
                                            <Grid
                                                className="radio-image-grid"
                                                container
                                                direction={"column"}
                                                alignItems={"center"}
                                                justify={"center"}
                                            >
                                                <Grid
                                                    item
                                                >
                                                    <img alt={""} src={item.imageSrc} style={{marginTop:"25px"}}/>
                                                </Grid>
                                                <Grid
                                                    item
                                                >
                                                    <Typography variant={"body2"} style={{fontSize:"12px"}}>
                                                        {item.label}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                    }
                                </div>
                            }
                        />
                    )
                }
            </Field>
            {
                submitCount>0 &&
                <FormHelperText error={true}>{error}</FormHelperText>
            }
        </FormControl>
    )
}
export default InputRadioGroupForm