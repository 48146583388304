import axios from 'axios'

/*
    returned value response anatomy:
    response={
        config:{},
        data:{} ó "",
        headers:{},
        request:{},
        status:integer,
        statusText:""
    }
 */
export async function logout(){
    try{
        const response=await axios.get('auth/logout')
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}