import axios from 'axios'

/*
    returned value response anatomy:
    response={
        config:{},
        data:{} ó "",
        headers:{},
        request:{},
        status:integer,
        statusText:""
    }
 */
export async function postFamilySituation(data){
    try{
        const response=await axios.post('family-situation', data)
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}

export async function getFamilySituation(){
    try{
        const response =await axios.get('family-situation')
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}