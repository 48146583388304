import axios from 'axios'

export async function postPlans(data){
    try{
        const response=await axios.post('plans?global_info=1', data)
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}
export async function getPlans(){
    try {
        const response=await axios.get('plans?global_info=1')
        return {data:response.data,url:response.config.url}
    }catch (e){
        if(e.response.status===401){
            //SnackbarUtils.error('Acceso no permitido')
        }else{
            //SnackbarUtils.error('Error obteniendo los datos del plan')
        }
    }
}
export async function getActivePlans(){
    try{
        const response=await axios.get('active-plans')
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}

export async function getActivePlanById(id){
    try{
        const response=await axios.get('active-plans/'+id+'?global_info=1')
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}

export async function getActivePlanTracingById(id){
    try{
        const response=await axios.get('active-plans/'+id+'/tracing')
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}

export async function getActivePlanPerformanceById(idPlan,idObjective){
    try{
        const response=await axios.get('active-plans/'+idPlan+'/objectives/'+idObjective+'/performance')
        return {data:response.data,url:response.config.url}
    }catch (e){

    }
}